import {
  MODAL_CHANGE_STATUS,
  MODAL_CHANGE_TYPE,
  MODAL_CLEAR,
  MODAL_CHANGE_TITLE,
  MODAL_CHANGE_NAME,
  MODAL_CHANGE_EMAIL,
  MODAL_CHANGE_INSTITUTION,
  MODAL_CHANGE_PHONE,
  MODAL_CHANGE_MESSAGE,
  MODAL_CHANGE_SECTION,
  MODAL_CHANGE_REQUEST,
  MODAL_ERROR,
  MODAL_LOADED,
  MODAL_SUCCESS,
  MODAL_LINK,
  MODAL_SURVEY,
} from "../types/modalTypes";

const INITIAL_STATE = {
  modal_download_status: "",
  modal_download_type: 0,
  modal_title: "",
  modal_name: "",
  modal_email: "",
  modal_institution: "",
  modal_phone: "",
  modal_message: "",
  modal_section: "",
  modal_request: "",
  modal_error: "",
  modal_loading: false,
  modal_success: "",
  modal_link: "",
  modal_survey: "",
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODAL_CHANGE_STATUS:
      return {
        ...state,
        modal_download_status: action.payload,
        modal_loading: false,
        modal_success: "",
      };
    case MODAL_CHANGE_TYPE:
      return {
        ...state,
        modal_download_type: action.payload,
      };
    case MODAL_CLEAR:
      return {
        ...state,
        modal_title: "",
        modal_name: "",
        modal_email: "",
        modal_institution: "",
        modal_phone: "",
        modal_message: "",
        modal_section: "",
        modal_request: "",
        modal_error: "",
        modal_link: "",
        modal_survey: "",
      };
    case MODAL_CHANGE_TITLE:
      return {
        ...state,
        modal_title: action.payload,
      };
    case MODAL_CHANGE_NAME:
      return {
        ...state,
        modal_name: action.payload,
      };
    case MODAL_CHANGE_EMAIL:
      return {
        ...state,
        modal_email: action.payload,
      };
    case MODAL_CHANGE_INSTITUTION:
      return {
        ...state,
        modal_institution: action.payload,
      };
    case MODAL_CHANGE_PHONE:
      return {
        ...state,
        modal_phone: action.payload,
      };
    case MODAL_CHANGE_MESSAGE:
      return {
        ...state,
        modal_message: action.payload,
      };
    case MODAL_CHANGE_SECTION:
      return {
        ...state,
        modal_section: action.payload,
      };
    case MODAL_CHANGE_REQUEST:
      return {
        ...state,
        modal_request: action.payload,
      };
    case MODAL_ERROR:
      return {
        ...state,
        modal_error: action.payload,
      };
    case MODAL_LOADED:
      return {
        ...state,
        modal_loading: action.payload,
      };
    case MODAL_SUCCESS:
      return {
        ...state,
        modal_success: action.payload,
      };
    case MODAL_LINK:
      return {
        ...state,
        modal_link: action.payload,
      };
    case MODAL_SURVEY:
      return {
        ...state,
        modal_survey: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default modalReducer;

import {
  TERRITORY_MODAL,
  TERRITORY_VIEW_COMPONENT,
  TERRITORY_TITLE_COMPONENT,
  TERRITORY_CLEAR_STAGES,
  TERRITORY_DATA_STAGES,
  TERRITORY_MAP,
  TERRITORY_IFRAME,
  TERRITORY_CLEAR,
  TERRITORY_TYPE,
  TERRITORY_DATA,
  TERRITORY_DATA_SELECTED,
  TERRITORY_LOADING_DATA,
  TERRITORY_PROGRAMS,
  TERRITORY_PROGRAM,
  TERRITORY_LOADING_PROGRAMS,
  TERRITORY_LOADING_SELECTED,
  TERRITORY_REDIRECT,
  TERRITORY_INDEX,
  TERRITORY_REQUEST_LOADING,
  TERRITORY_TAB_LOADING,
  TERRITORY_TABS,
  TERRITORY_TAB_SELECTED,
  TERRITORY_TAB_ITEMS,
  TERRITORY_TAB_SELECTED_ITEMS,
  TERRITORY_TAB_ITEM_SELECTED,
  TERRITORY_TAB_CONTENTS,
  TERRITORY_TAB_CONTENTS_PDF,
  TERRITORY_TAB_CONTENTS_GRAPH,
  TERRITORY_TOOLTIP,
} from "../types/territoryTypes";

const INITIAL_STATE = {
  territory_modal: "",
  territory_map: "",
  territory_iframe: [],
  territory_view_component: false,
  territory_title_component: "",
  territory_stage_half: {
    country: "",
    city: "",
  },
  territory_stage_postmedia: {
    country: "",
    city: "",
  },
  territory_stage_intermediation: {
    country: "",
    city: "",
  },
  territory_stage_job: {
    country: "",
    city: "",
  },
  territory_type: "datos",
  territory_data: null,
  territory_data_selected: null,
  territory_loading_data: true,
  territory_programs: null,
  territory_program: null,
  territory_loading_programs: true,
  territory_loading_selected: true,
  territory_redirect: false,
  territory_index: "0",
  territory_request_loading: false,
  territory_tab_loading: true,
  territory_tabs: [],
  territory_tab_selected: [],
  territory_tab_items: [],
  territory_tab_selected_items: [],
  territory_tab_item_selected: null,
  territory_tab_contents: [],
  territory_tab_contents_pdf: [],
  territory_tab_contents_graph: [],
  territory_tooltip: true,
};

const territoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TERRITORY_MODAL:
      return {
        ...state,
        territory_modal: action.payload,
      };
    case TERRITORY_VIEW_COMPONENT:
      return {
        ...state,
        territory_view_component: action.payload,
      };
    case TERRITORY_TITLE_COMPONENT:
      return {
        ...state,
        territory_title_component: action.payload,
      };
    case TERRITORY_CLEAR_STAGES:
      return {
        ...state,
        territory_stage_half: {
          country: "",
          city: "",
        },
        territory_stage_postmedia: {
          country: "",
          city: "",
        },
        territory_stage_intermediation: {
          country: "",
          city: "",
        },
        territory_stage_job: {
          country: "",
          city: "",
        },
      };
    case TERRITORY_DATA_STAGES:
      return {
        ...state,
        territory_stage_half: {
          country: action.payload.country_half,
          city: action.payload.city_half,
        },
        territory_stage_postmedia: {
          country: action.payload.country_postmedia,
          city: action.payload.city_postmedia,
        },
        territory_stage_intermediation: {
          country: action.payload.country_intermediation,
          city: action.payload.city_intermediation,
        },
        territory_stage_job: {
          country: action.payload.country_job,
          city: action.payload.city_job,
        },
      };
    case TERRITORY_MAP:
      return {
        ...state,
        territory_map: action.payload,
      };
    case TERRITORY_IFRAME:
      return {
        ...state,
        territory_iframe: action.payload,
      };
    case TERRITORY_CLEAR:
      return {
        ...state,
        territory_view_component: false,
        territory_title_component: "",
        territory_stage_half: {
          country: "",
          city: "",
        },
        territory_stage_postmedia: {
          country: "",
          city: "",
        },
        territory_stage_intermediation: {
          country: "",
          city: "",
        },
        territory_stage_job: {
          country: "",
          city: "",
        },
        territory_modal: "",
        territory_map: "",
        territory_iframe: [],
      };
    case TERRITORY_TYPE:
      return {
        ...state,
        territory_type: action.payload,
      };
    case TERRITORY_DATA:
      return {
        ...state,
        territory_data: action.payload,
      };
    case TERRITORY_DATA_SELECTED:
      return {
        ...state,
        territory_data_selected: action.payload,
      };
    case TERRITORY_LOADING_DATA:
      return {
        ...state,
        territory_loading_data: action.payload,
      };
    case TERRITORY_PROGRAMS:
      return {
        ...state,
        territory_programs: action.payload,
      };
    case TERRITORY_PROGRAM:
      return {
        ...state,
        territory_program: action.payload,
      };
    case TERRITORY_LOADING_PROGRAMS:
      return {
        ...state,
        territory_loading_programs: action.payload,
      };
    case TERRITORY_LOADING_SELECTED:
      return {
        ...state,
        territory_loading_selected: action.payload,
      };
    case TERRITORY_REDIRECT:
      return {
        ...state,
        territory_redirect: action.payload,
      };
    case TERRITORY_INDEX:
      return {
        ...state,
        territory_index: action.payload,
      };
    case TERRITORY_REQUEST_LOADING:
      return {
        ...state,
        territory_request_loading: action.payload,
      };
    case TERRITORY_TAB_LOADING:
      return {
        ...state,
        territory_tab_loading: action.payload,
      };
    case TERRITORY_TABS:
      return {
        ...state,
        territory_tabs: action.payload,
      };
    case TERRITORY_TAB_SELECTED:
      return {
        ...state,
        territory_tab_selected: action.payload,
      };
    case TERRITORY_TAB_SELECTED_ITEMS:
      return {
        ...state,
        territory_tab_selected_items: action.payload,
      };
    case TERRITORY_TAB_ITEMS:
      return {
        ...state,
        territory_tab_items: action.payload,
      };
    case TERRITORY_TAB_ITEM_SELECTED:
      return {
        ...state,
        territory_tab_item_selected: action.payload,
      };
    case TERRITORY_TAB_CONTENTS:
      return {
        ...state,
        territory_tab_contents: action.payload,
      };
    case TERRITORY_TAB_CONTENTS_PDF:
      return {
        ...state,
        territory_tab_contents_pdf: action.payload,
      };
    case TERRITORY_TAB_CONTENTS_GRAPH:
      return {
        ...state,
        territory_tab_contents_graph: action.payload,
      };
    case TERRITORY_TOOLTIP:
      return {
        ...state,
        territory_tooltip: action.payload,
      };
    default:
      return { ...state };
  }
};

export default territoryReducer;

import {
  NEWS_COD_CATEGORY, NEWS_SUBCATEGORIES, NEWS_VIEW_GRAPH, NEWS_TOTAL, NEWS_PER_CATEGORY, NEWS_LOADING,
  NEWS_COD_SUBCATEGORY,
} from "../types/newsTypes";

export const newsViewGraph = (payload) => (dispatch) => {
  dispatch({
    type: NEWS_VIEW_GRAPH,
    payload,
  });
}

export const newsPerCategories = () => (dispatch, getState) => {
  const { categories } = getState().defaultReducer;
  let principalCategories = categories.filter(item => (item.cod_category === 0));
  
  principalCategories.map(category => {
    let subcategories = categories.filter(item => (item.cod_category === category.id));
    category.subcategories = subcategories;

    return category;
  });

  dispatch({
    type: NEWS_TOTAL,
    payload: principalCategories,
  });
  dispatch({
    type: NEWS_PER_CATEGORY,
    payload: principalCategories,
  });

  setTimeout(() => {
    dispatch({
      type: NEWS_LOADING,
      payload: false,
    });
  }, 100);
}

export const newsClearSubcategories = () => (dispatch, getState) => {
  const { news_total } = getState().newsReducer;
  dispatch({
    type: NEWS_COD_CATEGORY,
    payload: null
  });
  dispatch({
    type: NEWS_SUBCATEGORIES,
    payload: [],
  });
  dispatch({
    type: NEWS_PER_CATEGORY,
    payload: news_total,
  });
}

export const newsChangeCategory = (payload) => (dispatch, getState) => {
  const { categories } = getState().defaultReducer;
  const { news_cod_category, news_total, news_cod_subcategory } = getState().newsReducer;

  dispatch({
    type: NEWS_LOADING,
    payload: true,
  });
  dispatch({
    type: NEWS_COD_SUBCATEGORY,
    payload: null
  });

  if (news_cod_category === payload && !news_cod_subcategory) {
    dispatch({
      type: NEWS_COD_CATEGORY,
      payload: null
    });
    dispatch({
      type: NEWS_SUBCATEGORIES,
      payload: [],
    });
    dispatch({
      type: NEWS_PER_CATEGORY,
      payload: news_total,
    });
  } else {
    let subcategories = categories.filter(item => (item.cod_category === payload));
    let news = news_total.filter(item => (item.id === payload));

    dispatch({
      type: NEWS_COD_CATEGORY,
      payload
    });
    dispatch({
      type: NEWS_SUBCATEGORIES,
      payload: subcategories,
    });
    dispatch({
      type: NEWS_PER_CATEGORY,
      payload: news,
    });
  }

  setTimeout(() => {
    dispatch({
      type: NEWS_LOADING,
      payload: false,
    });
  }, 100);
}

export const newsChangeSubcategory = (payload) => (dispatch, getState) => {
  const { categories } = getState().defaultReducer;
  const { news_cod_category, news_cod_subcategory } = getState().newsReducer;
  dispatch({
    type: NEWS_LOADING,
    payload: true,
  });

  if (payload === news_cod_subcategory) {
    dispatch({
      type: NEWS_COD_SUBCATEGORY,
      payload,
    });
    dispatch(newsChangeCategory(news_cod_category));
  } else {
    let news = categories.filter(item => (item.id === payload));

    dispatch({
      type: NEWS_COD_SUBCATEGORY,
      payload
    });
    dispatch({
      type: NEWS_PER_CATEGORY,
      payload: news,
    });
    setTimeout(() => {
      dispatch({
        type: NEWS_LOADING,
        payload: false,
      });
    }, 100);
  }
}
import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import image from "../images/bg-tools.jpg";
import BoxSearch from "../components/BoxSearch";
import ToolsMenu from "../components/ToolsMenu";
import Pagination from "../components/Pagination";
import * as toolsActions from "../functionality/actions/toolsActions";
import * as indexActions from "../functionality/actions/indexActions";
import MostRecent from "../components/MostRecent";
import "./css/ToolsPage.css";
import DocDetail from "../components/DocDetail";
import SectionSlider from "../components/SectionSlider";
import DocsList from "../components/DocsList";
import Counter from "../components/Counter";
import ButtonGraph from "../components/ButtonGraph";

const {
  changeSelectMenu,
  toolsChangePage,
  toolsViewDoc,
  toolsSearch,
  toolsClear,
  toolsViewGraph,
} = toolsActions;

const { changeMenu } = indexActions;
class ToolsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { toolsViewDoc } = this.props;
    if (this.props.match.params.id) {
      toolsViewDoc(true, this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    const { toolsClear, changeMenu, toolsViewGraph } = this.props;
    changeMenu("");
    toolsClear();
    toolsViewGraph(true);
  }

  componentDidUpdate(prevProps) {
    const { toolsViewDoc } = this.props;
    if (!this.props.match.params.id && prevProps.match.params.id) {
      window.scrollTo(0, 0);
      toolsViewDoc(false, "");
    }
    if (
      this.props.match.params.id &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      toolsViewDoc(true, this.props.match.params.id);
    }
  }

  _renderGraphs = (value) => {
    let sources = value.split(";");
    let response = sources.map((item, index) => {
      item = item.trim(";");
      if (item.length > 0) {
        return (
          <div className="component-content" key={index}>
            <iframe src={item} title="Gráfico Herramientas y Metodología" />
          </div>
        );
      }
      return null;
    });

    return response;
  };

  render() {
    const {
      changeSelectMenu,
      toolsChangePage,
      toolsSearch,
      toolsViewGraph,
    } = this.props;

    const {
      tools_menu_select,
      tools_view_docs,
      tools_items_per_page,
      tools_current_page,
      tools_docs,
      tools_view_detail_doc,
      tools_search,
      tools_detail,
      tools_view_graph,
    } = this.props.toolsReducer;

    const {
      other_contents,
      filter_tools,
      sliders,
      tools,
    } = this.props.defaultReducer;

    let tool = other_contents.filter(
      (item) => item.label === "herramientas_metodologias"
    );
    let graphic = other_contents.filter(
      (item) => item.label.indexOf("herramientas_grafico") >= 0
    );
    let slider = sliders.filter(
      (item) => item.name === "herramientas_tecnologia"
    );

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div className="title-section">{tool[0].title}</div>
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-coral"
              dangerouslySetInnerHTML={{ __html: tool[0].value }}
            />
          </div>
        </div>
        <Counter
          title="Contador de herramientas y metodología publicadas"
          background="#D77561"
          total={tools.length}
        />
        <BoxSearch
          placeholder="BUSCADOR PALABRAS CLAVES"
          value={tools_search}
          onChange={toolsSearch}
        />
        <ToolsMenu
          data={filter_tools}
          status={tools_menu_select}
          selectMenu={changeSelectMenu}
        />
        {tools_search.length >= 4 && (
          <div className="tools-search">
            Resultados de la busqueda: {tools_search}
          </div>
        )}
        {tools_docs.length > 0 ? (
          <div className="section-content">
            <DocsList
              data={tools_view_docs}
              link="/herramientas-metodologias"
            />
            <Pagination
              all_data={tools_docs}
              items_per_page={tools_items_per_page}
              current_page={tools_current_page}
              change_page={toolsChangePage}
            />
          </div>
        ) : (
          <div className="section-content">
            <h3 className="text-center">NO HAY ARCHIVOS DISPONIBLES</h3>
          </div>
        )}
        <div id="doc-detail">
          {tools_view_detail_doc && tools_detail.length > 0 ? (
            <DocDetail
              file={tools_detail}
              section="HERRAMIENTAS Y METODOLOGÍAS"
            />
          ) : (
            tools_view_detail_doc && (
              <h3 className="text-center">EL ARCHIVO NO ESTÁ DISPONIBLE</h3>
            )
          )}
        </div>
        {graphic.length > 0 && graphic[1].value && graphic[1].value !== "" && (
          <div className="">
            {graphic[0].value && graphic[0].value !== "" && (
              <>
                <ButtonGraph
                  background="#D77561"
                  name={graphic[0].value}
                  action={() => toolsViewGraph(!tools_view_graph)}
                  status={tools_view_graph}
                />
                {tools_view_graph && this._renderGraphs(graphic[1].value)}
              </>
            )}
          </div>
        )}
        <MostRecent title="LO MÁS RECIENTE" label="herramientas_metodologia" />
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ toolsReducer, defaultReducer }) => {
  return {
    toolsReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  changeSelectMenu,
  toolsChangePage,
  toolsViewDoc,
  toolsSearch,
  toolsClear,
  changeMenu,
  toolsViewGraph,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolsPage);

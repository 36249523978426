import React from 'react';
import './css/Button.css';
import Tooltip from './Tooltip';

function ButtonYellow(props) {
  return(
    <button onClick={props.action} className="button button-yellow">
      <span className="icon-more"/> VER MÁS
      {props.tooltip &&
        <Tooltip text={props.tooltip}/>
      }
    </button>
  )
}
export default ButtonYellow;
import React from "react";
import { connect } from "react-redux";
import TerritoryPopulation from "./TerritoryPopulation";
import TerritoryTabContentPrivate from "./TerritoryTabContentPrivate";
import ReactPDFViewer from "./ReactPDFViewer";

function TerritoryTabContent(props) {
  const {
    userReducer: { user },
    territoryReducer: {
      territory_tab_selected,
      territory_tab_item_selected,
      territory_tab_contents_pdf,
      territory_tab_contents_graph,
    },
  } = props;

  if (!territory_tab_selected || !territory_tab_item_selected) {
    return <h3 className="mb-4">Lo sentimos, no encontramos el contenido.</h3>;
  }

  // Validate if contentet is visible to user
  let isVisibleToUser = true;
  if (parseInt(territory_tab_selected.is_private)) {
    if (!user) {
      window.location.href = "/territorio";
      return null;
    }

    const userExist = territory_tab_item_selected.users.filter(
      (item) => parseInt(item.id) === parseInt(user.id)
    );
    if (!userExist.length) {
      isVisibleToUser = false;
    }
  }

  if (!isVisibleToUser) {
    return <TerritoryTabContentPrivate />;
  }

  const renderContents = (contents) => {
    return (
      <>
        {contents.map((item) => {
          const iframeClass =
            parseInt(item.type) === 1
              ? "territoryInfoPage_graph"
              : "territoryInfoPage_doc";

          return (
            <div key={item.id} className="territoryInfoPage_item">
              <div className="territoryInfoPage_download">
                <h3 style={{ color: territory_tab_item_selected.background }}>
                  {item.title}
                </h3>
                {parseInt(item.type) === 2 && (
                  <a
                    href={item.content}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundColor: territory_tab_item_selected.background,
                      color: territory_tab_item_selected.color,
                    }}
                    title="Descargar archivo"
                  >
                    <i className="fas fa-download" />
                  </a>
                )}
              </div>
              {parseInt(item.type) === 1 ? (
                <iframe
                  src={item.content}
                  frameBorder="0"
                  className={iframeClass}
                  title={item.title}
                />
              ) : (
                <ReactPDFViewer file={item.content} />
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="territoryInfoPage_container">
      <h1 style={{ color: territory_tab_item_selected.background }}>
        {territory_tab_item_selected.title}
      </h1>
      {parseInt(territory_tab_selected.is_private) ? (
        <div className="territoryInfoPage_note">
          <p>
            <strong>Nota: </strong> La información presentada aquí es
            confidencial y está prohibida su distribución a terceros.
          </p>
        </div>
      ) : null}
      {territory_tab_item_selected.id === 6 ? (
        <>
          {renderContents(territory_tab_contents_pdf)}
          <TerritoryPopulation />
          {renderContents(territory_tab_contents_graph)}
        </>
      ) : (
        <>
          {renderContents(territory_tab_contents_graph)}
          {renderContents(territory_tab_contents_pdf)}
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ userReducer, territoryReducer }) => {
  return {
    userReducer,
    territoryReducer,
  };
};

export default connect(mapStateToProps, null)(TerritoryTabContent);

import React from 'react';

function ButtonDownload(props) {
  let action = (props.action) ? props.action : () => false;
  return (
    <button className="btn-download" onClick={action}>
      <span className="icon-download"/>{props.name}
    </button>
  )
}

export default ButtonDownload;
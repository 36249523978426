import React, { Component } from "react";
import { connect } from "react-redux";
import * as newsActions from "../functionality/actions/newsActions";
import LayoutDefault from "./layouts/LayoutDefault";
import SectionSlider from "../components/SectionSlider";
import image from "../images/bg-news.jpg";
import "./css/NewsPage.css";
import NewsSection from "../components/NewsSection";
import Counter from "../components/Counter";
import ButtonGraph from "../components/ButtonGraph";
import loaded from "../images/loading.svg";

const {
  newsViewGraph,
  newsChangeCategory,
  newsChangeSubcategory,
  newsPerCategories,
  newsClearSubcategories,
} = newsActions;

class NewsPage extends Component {
  componentDidMount() {
    const {
      newsReducer: { news_total },
      newsPerCategories,
    } = this.props;
    if (!news_total.length) {
      newsPerCategories();
    }
  }

  _renderGraphs = (value) => {
    let sources = value.split(";");
    let response = sources.map((item, index) => {
      item = item.trim(";");
      if (item.length > 0) {
        return (
          <div className="component-content" key={index}>
            <iframe src={item} title="Gráfico Documentos Técnicos" />
          </div>
        );
      }
      return null;
    });

    return response;
  };

  componentWillUnmount() {
    this.props.newsViewGraph(true);
    this.props.newsClearSubcategories();
  }

  render() {
    const {
      defaultReducer: { sliders, other_contents, texts, allNews, categories },
      newsReducer: {
        news_view_graph,
        news_cod_category,
        news_subcategories,
        news_per_category,
        news_loading,
        news_cod_subcategory,
      },
      newsViewGraph,
      newsChangeCategory,
      newsChangeSubcategory,
    } = this.props;
    const end_link = texts.filter((item) => item.label === "menu_6");
    let graphic = other_contents.filter(
      (item) => item.label.indexOf("noticias_grafico") >= 0
    );
    let end_route = !end_link.length ? "noticias" : end_link[0].value;
    end_route = end_route.toLowerCase().replace(" ", "-");

    let content = other_contents.filter((item) => item.label === "noticias");
    let slider = sliders.filter((item) => item.name === "noticias");
    let principalCategories = categories.filter(
      (item) => item.cod_category === 0
    );

    return (
      <LayoutDefault>
        {!slider.length ||
        slider[0].is_active !== 1 ||
        !slider[0].slides.length ? (
          <div
            className="bg-section"
            style={{ backgroundImage: `URL(${image})` }}
          >
            <div
              className="bg-section"
              style={{ backgroundImage: `URL(${image})` }}
            >
              <div className="title-section">{content[0].title}</div>
            </div>
          </div>
        ) : (
          <SectionSlider slides={slider[0].slides} />
        )}
        <br />
        <div
          className="description-news"
          dangerouslySetInnerHTML={{ __html: content[0].value }}
        />
        {graphic.length > 0 && graphic[1].value && graphic[1].value !== "" ? (
          <div style={{ marginTop: 20 }}>
            {graphic[0].value && graphic[0].value !== "" && (
              <>
                <ButtonGraph
                  background="#5E959D"
                  name={graphic[0].value}
                  action={() => newsViewGraph(!news_view_graph)}
                  status={news_view_graph}
                />
                {news_view_graph && this._renderGraphs(graphic[1].value)}
              </>
            )}
          </div>
        ) : (
          <br />
        )}
        <Counter
          title="Contador de noticias publicadas"
          background="#5E959D"
          total={allNews.length}
        />
        <div className="news_row-category">
          {principalCategories.map((category) => {
            let status = category.id === news_cod_category ? "selected" : "";
            return (
              <div
                key={category.id}
                onClick={() => newsChangeCategory(category.id)}
                className={`news_category ${status}`}
              >
                {category.title}
              </div>
            );
          })}
        </div>
        {news_cod_category && (
          <div className="news_row-subcategory">
            {news_subcategories.map((category) => {
              let status =
                category.id === news_cod_subcategory ? "selected" : "";
              return (
                <div
                  key={category.id}
                  onClick={() => newsChangeSubcategory(category.id)}
                  className={`news_item-subcategory ${status}`}
                >
                  {category.title}
                </div>
              );
            })}
          </div>
        )}
        <div className="news_content">
          {news_loading && (
            <div className="news_loading">
              <img src={loaded} alt="loaded" />
            </div>
          )}
          {!news_loading &&
            news_per_category.map((item) => {
              return (
                <div key={item.id} className="news_section">
                  <div className="news_title">{item.title}</div>
                  <NewsSection articles={item.articles} route={end_route} />
                  {item.subcategories &&
                    item.subcategories.map((subcategory) => {
                      return (
                        <div key={subcategory.id} className="news_subcategory">
                          <div className="news_subtitle">
                            {subcategory.title}
                          </div>
                          <NewsSection
                            articles={subcategory.articles}
                            route={end_route}
                          />
                        </div>
                      );
                    })}
                </div>
              );
            })}
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ defaultReducer, newsReducer }) => {
  return {
    defaultReducer,
    newsReducer,
  };
};

const mapDispatchToProps = {
  newsViewGraph,
  newsChangeCategory,
  newsChangeSubcategory,
  newsPerCategories,
  newsClearSubcategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);

import React from "react";
import { connect } from "react-redux";
import { getTitle } from "../../functionality/actions/currentActions";
import "./FormButton.css";

function FormButton(props) {
  const {
    defaultReducer: { ecosystem_titles },
  } = props;
  const buttonName = getTitle(ecosystem_titles, "ecosistema_form_button");
  const link = getTitle(ecosystem_titles, "ecosistema_form_link");
  if (!buttonName || !link) {
    return null;
  }
  return (
    <div className="FormButton">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="FormButton-container-circle">
          <div className="FormButton-circle">CLICK AQUÍ</div>
          <div className="FormButton-arrow" />
        </div>
        <div className="FormButton-text">{buttonName}</div>
      </a>
    </div>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default connect(mapStateToProps, null)(FormButton);

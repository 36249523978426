import Api from "../Api";
import {
  INITIATIVE_LOADING,
  INITIATIVE_ACTORS,
  INITIATIVE_STRETCHES,
  INITIATIVE_COMPONENTS,
  INITIATIVE_LEVELS,
  INITIATIVE_POPULATIONS,
  INITIATIVE_LIST,
  INITIATIVE_VIEW,
  INITIATIVE_ACTORS_FILTER,
  INITIATIVE_STRETCHES_FILTER,
  INITIATIVE_COMPONENT_FILTER,
  INITIATIVE_POPULATION_FILTER,
  INITIATIVE_CITIES_FILTER,
  INITIATIVE_TOTAL,
  INITIATIVE_INDIVIDUAL,
  INITIATIVE_SEARCH,
  INITIATIVE_T1_C1,
  INITIATIVE_T1_C2,
  INITIATIVE_T1_C3,
  INITIATIVE_T2_C1,
  INITIATIVE_T2_C2,
  INITIATIVE_T2_C3,
  INITIATIVE_T2_C4,
  INITIATIVE_T3_C3,
  INITIATIVE_T3_C4,
  INITIATIVE_T4_C3,
  INITIATIVE_T4_C4,
  INITIATIVE_P1,
  INITIATIVE_P2,
  INITIATIVE_P3,
  INITIATIVE_P4,
  INITIATIVE_P5,
  INITIATIVE_P6,
  INITIATIVE_P7,
  INITIATIVE_P8,
  INITIATIVE_P9,
  INITIATIVE_MODAL,
  INITIATIVE_WINNER,
  INITIATIVE_WINNERS,
  INITIATIVE_DETAIL,
  INITIATIVE_DETAIL_CLEAR,
  INITIATIVE_DETAIL_LOADING,
} from "../types/initiativeTypes";

const countComponentInStretch = (initiatives, stretch, componentLabel) => {
  let list = initiatives.filter((initiative) => {
    let components = initiative.components.filter(
      (component) =>
        component.complement &&
        component.complement.indexOf(stretch) >= 0 &&
        component.label === componentLabel
    );
    if (components.length) {
      return initiative;
    }
    return null;
  });
  list = list.filter((item) => item);

  return list.length;
};

const countPopulation = (initiatives, populationId) => {
  let list = initiatives.filter((initiative) => {
    let populations = initiative.populations.filter(
      (population) => parseInt(population.id) === populationId
    );
    if (populations.length) {
      return initiative;
    }
    return null;
  });
  list = list.filter((item) => item);

  return list.length;
};

export const initiativesCount = () => (dispatch, getState) => {
  const { initiative_view } = getState().initiativeReducer;

  let t1c1 = countComponentInStretch(initiative_view, "T1", "C1");
  let t1c2 = countComponentInStretch(initiative_view, "T1", "C2");
  let t1c3 = countComponentInStretch(initiative_view, "T1", "C3");
  let t2c1 = countComponentInStretch(initiative_view, "T2", "C1");
  let t2c2 = countComponentInStretch(initiative_view, "T2", "C2");
  let t2c3 = countComponentInStretch(initiative_view, "T2", "C3");
  let t3c3 = countComponentInStretch(initiative_view, "T3", "C3");
  let t3c4 = countComponentInStretch(initiative_view, "T3", "C4");
  let t4c3 = countComponentInStretch(initiative_view, "T4", "C3");
  let t4c4 = countComponentInStretch(initiative_view, "T4", "C4");
  dispatch({
    type: INITIATIVE_T1_C1,
    payload: t1c1,
  });
  dispatch({
    type: INITIATIVE_T1_C2,
    payload: t1c2,
  });
  dispatch({
    type: INITIATIVE_T1_C3,
    payload: t1c3,
  });
  dispatch({
    type: INITIATIVE_T2_C1,
    payload: t2c1,
  });
  dispatch({
    type: INITIATIVE_T2_C2,
    payload: t2c2,
  });
  dispatch({
    type: INITIATIVE_T2_C3,
    payload: t2c3,
  });
  dispatch({
    type: INITIATIVE_T2_C4,
    payload: 1,
  });
  dispatch({
    type: INITIATIVE_T3_C3,
    payload: t3c3,
  });
  dispatch({
    type: INITIATIVE_T3_C4,
    payload: t3c4,
  });
  dispatch({
    type: INITIATIVE_T4_C3,
    payload: t4c3,
  });
  dispatch({
    type: INITIATIVE_T4_C4,
    payload: t4c4,
  });

  let p1 = countPopulation(initiative_view, 1);
  let p2 = countPopulation(initiative_view, 2);
  let p3 = countPopulation(initiative_view, 3);
  let p4 = countPopulation(initiative_view, 4);
  let p5 = countPopulation(initiative_view, 5);
  let p6 = countPopulation(initiative_view, 6);
  let p7 = countPopulation(initiative_view, 7);
  let p8 = countPopulation(initiative_view, 8);
  let p9 = countPopulation(initiative_view, 9);
  dispatch({
    type: INITIATIVE_P1,
    payload: p1,
  });
  dispatch({
    type: INITIATIVE_P2,
    payload: p2,
  });
  dispatch({
    type: INITIATIVE_P3,
    payload: p3,
  });
  dispatch({
    type: INITIATIVE_P4,
    payload: p4,
  });
  dispatch({
    type: INITIATIVE_P5,
    payload: p5,
  });
  dispatch({
    type: INITIATIVE_P6,
    payload: p6,
  });
  dispatch({
    type: INITIATIVE_P7,
    payload: p7,
  });
  dispatch({
    type: INITIATIVE_P8,
    payload: p8,
  });
  dispatch({
    type: INITIATIVE_P9,
    payload: p9,
  });
};

export const getInitiatives = () => (dispatch) => {
  dispatch({
    type: INITIATIVE_LOADING,
    payload: true,
  });

  Api.callMethod(
    "initiatives",
    "get",
    null,
    (res) => {
      const { data } = res;
      dispatch({
        type: INITIATIVE_LIST,
        payload: data,
      });
      dispatch({
        type: INITIATIVE_VIEW,
        payload: data,
      });
      dispatch({
        type: INITIATIVE_LOADING,
        payload: false,
      });
      dispatch(initiativesCount());
    },
    (error) => {
      console.log("Error initiativeGet: ", error);
    }
  );
};

export const initiativeGetFilters = () => (dispatch) => {
  Api.callMethod(
    "initiatives/filters",
    "get",
    null,
    (res) => {
      if (res) {
        const {
          total,
          total_individual,
          actors,
          components,
          levels,
          populations,
          stretches,
          winners,
        } = res;
        dispatch({
          type: INITIATIVE_TOTAL,
          payload: total,
        });
        dispatch({
          type: INITIATIVE_INDIVIDUAL,
          payload: total_individual,
        });
        dispatch({
          type: INITIATIVE_ACTORS,
          payload: actors.data,
        });
        dispatch({
          type: INITIATIVE_COMPONENTS,
          payload: components.data,
        });
        dispatch({
          type: INITIATIVE_LEVELS,
          payload: levels.data,
        });
        dispatch({
          type: INITIATIVE_POPULATIONS,
          payload: populations.data,
        });
        dispatch({
          type: INITIATIVE_WINNERS,
          payload: winners,
        });
        dispatch({
          type: INITIATIVE_STRETCHES,
          payload: stretches.data,
        });
      }
    },
    (error) => {
      console.log("Error initiativeGetFilters: ", error);
    }
  );
};

export const initiativeChangeWinner = (payload) => (dispatch) => {
  dispatch({
    type: INITIATIVE_WINNER,
    payload,
  });
  dispatch(initiativeFilter());
};

export const initiativeSearch = (payload) => (dispatch) => {
  dispatch({
    type: INITIATIVE_SEARCH,
    payload,
  });
  dispatch(initiativeFilter());
};

const initiativesFilter = (filters, initiatives, type) => {
  let data = [];
  filters.forEach((filter_to) => {
    initiatives.filter((initiative) => {
      let isAdded = data.find(
        (initiative_d) => parseInt(initiative_d.id) === parseInt(initiative.id)
      );
      if (!isAdded) {
        if (type === "stretches") {
          let stretches = initiative.stretches.filter(
            (stretch) => parseInt(stretch.id) === parseInt(filter_to.id)
          );
          if (stretches.length) {
            data.push(initiative);
          }
        }
        if (type === "components") {
          let components = initiative.components.filter(
            (component) => parseInt(component.id) === parseInt(filter_to.id)
          );
          if (components.length) {
            data.push(initiative);
          }
        }
        if (type === "cities") {
          let cities = initiative.cities.filter(
            (city) => parseInt(city.id) === parseInt(filter_to.id)
          );
          if (cities.length) {
            data.push(initiative);
          }
        }
        if (type === "populations") {
          let populations = initiative.populations.filter(
            (population) => parseInt(population.id) === parseInt(filter_to.id)
          );
          if (populations.length) {
            data.push(initiative);
          }
        }
        if (type === "actors") {
          let actors = initiative.actors.filter(
            (actor) => parseInt(actor.id) === parseInt(filter_to.id)
          );
          if (actors.length) {
            data.push(initiative);
          }
        }
      }
      return initiative;
    });
  });

  return data;
};

export const initiativeFilter = () => (dispatch, getState) => {
  const {
    initiative_list,
    initiative_search,
    initiative_stretches_filter,
    initiative_component_filter,
    initiative_cities_filter,
    initiative_population_filter,
    initiative_actors_filter,
    initiative_winner,
  } = getState().initiativeReducer;
  let initiatives = initiative_list;

  if (initiative_winner) {
    initiatives = initiatives.filter(
      (item) => item.winners.indexOf(initiative_winner) >= 0
    );
  }
  if (initiative_search.length >= 4) {
    initiatives = initiatives.filter(
      (item) =>
        item.name.toLowerCase().indexOf(initiative_search.toLowerCase()) >= 0
    );
  }
  if (initiative_stretches_filter.length) {
    initiatives = initiativesFilter(
      initiative_stretches_filter,
      initiatives,
      "stretches"
    );
  }
  if (initiative_component_filter.length) {
    initiatives = initiativesFilter(
      initiative_component_filter,
      initiatives,
      "components"
    );
  }
  if (initiative_cities_filter.length) {
    initiatives = initiativesFilter(
      initiative_cities_filter,
      initiatives,
      "cities"
    );
  }
  if (initiative_population_filter.length) {
    initiatives = initiativesFilter(
      initiative_population_filter,
      initiatives,
      "populations"
    );
  }
  if (initiative_actors_filter.length) {
    initiatives = initiativesFilter(
      initiative_actors_filter,
      initiatives,
      "actors"
    );
  }

  dispatch({
    type: INITIATIVE_VIEW,
    payload: initiatives,
  });
  dispatch(initiativesCount());
};

export const initiativeAddFilterActor = (value) => (dispatch, getState) => {
  if (!value) return null;

  const { initiative_actors_filter, initiative_actors } =
    getState().initiativeReducer;
  const added = initiative_actors_filter.find(
    (item) => parseInt(item.id) === parseInt(value)
  );
  if (!added) {
    let actor = initiative_actors.find(
      (actor) => parseInt(actor.id) === parseInt(value)
    );
    initiative_actors_filter.push(actor);
    dispatch({
      type: INITIATIVE_ACTORS_FILTER,
      payload: initiative_actors_filter,
    });
    dispatch(initiativeFilter());
  }
};

export const initiativeAddFilterStretch = (value) => (dispatch, getState) => {
  if (!value) return null;

  const { initiative_stretches_filter, initiative_stretches } =
    getState().initiativeReducer;
  const added = initiative_stretches_filter.find(
    (item) => item.value === value
  );

  if (!added) {
    let stretch = initiative_stretches.find(
      (stretch) => stretch.value === value
    );
    initiative_stretches_filter.push(stretch);

    dispatch({
      type: INITIATIVE_STRETCHES_FILTER,
      payload: initiative_stretches_filter,
    });
    dispatch(initiativeFilter());
  }
};

export const initiativeAddFilterComponent = (value) => (dispatch, getState) => {
  if (!value) return null;

  const { initiative_component_filter, initiative_components } =
    getState().initiativeReducer;
  const added = initiative_component_filter.find(
    (item) => item.value === value
  );

  if (!added) {
    let component = initiative_components.find(
      (component) => component.value === value
    );
    initiative_component_filter.push(component);

    dispatch({
      type: INITIATIVE_COMPONENT_FILTER,
      payload: initiative_component_filter,
    });
    dispatch(initiativeFilter());
  }
};

export const initiativeAddFilterCity = (value) => (dispatch, getState) => {
  if (!value) return null;

  const { initiative_cities_filter } = getState().initiativeReducer;
  const { cities } = getState().defaultReducer;
  const added = initiative_cities_filter.find(
    (item) => parseInt(item.id) === parseInt(value)
  );

  if (!added) {
    let city = cities.find((city) => parseInt(city.id) === parseInt(value));

    initiative_cities_filter.push(city);

    dispatch({
      type: INITIATIVE_CITIES_FILTER,
      payload: initiative_cities_filter,
    });
    dispatch(initiativeFilter());
  }
};

export const initiativeAddFilterPopulation =
  (value) => (dispatch, getState) => {
    if (!value) return null;

    const { initiative_population_filter, initiative_populations } =
      getState().initiativeReducer;
    const added = initiative_population_filter.find(
      (item) => parseInt(item.id) === parseInt(value)
    );

    if (!added) {
      let population = initiative_populations.find(
        (population) => parseInt(population.id) === parseInt(value)
      );

      initiative_population_filter.push(population);

      dispatch({
        type: INITIATIVE_POPULATION_FILTER,
        payload: initiative_population_filter,
      });
      dispatch(initiativeFilter());
    }
  };

export const initiativeRemoveFilterActor = (index) => (dispatch, getState) => {
  const { initiative_actors_filter } = getState().initiativeReducer;
  initiative_actors_filter.splice(index, 1);

  dispatch({
    type: INITIATIVE_ACTORS_FILTER,
    payload: initiative_actors_filter,
  });
  dispatch(initiativeFilter());
};

export const initiativeRemoveFilterStretch =
  (index) => (dispatch, getState) => {
    const { initiative_stretches_filter } = getState().initiativeReducer;
    initiative_stretches_filter.splice(index, 1);

    dispatch({
      type: INITIATIVE_STRETCHES_FILTER,
      payload: initiative_stretches_filter,
    });
    dispatch(initiativeFilter());
  };

export const initiativeRemoveFilterComponent =
  (index) => (dispatch, getState) => {
    const { initiative_component_filter } = getState().initiativeReducer;
    initiative_component_filter.splice(index, 1);

    dispatch({
      type: INITIATIVE_COMPONENT_FILTER,
      payload: initiative_component_filter,
    });
    dispatch(initiativeFilter());
  };

export const initiativeRemoveFilterCity = (index) => (dispatch, getState) => {
  const { initiative_cities_filter } = getState().initiativeReducer;
  initiative_cities_filter.splice(index, 1);

  dispatch({
    type: INITIATIVE_CITIES_FILTER,
    payload: initiative_cities_filter,
  });
  dispatch(initiativeFilter());
};

export const initiativeRemoveFilterPopulation =
  (index) => (dispatch, getState) => {
    const { initiative_population_filter } = getState().initiativeReducer;
    initiative_population_filter.splice(index, 1);

    dispatch({
      type: INITIATIVE_POPULATION_FILTER,
      payload: initiative_population_filter,
    });
    dispatch(initiativeFilter());
  };

export const initiativeModal = (payload) => (dispatch) => {
  if (payload === "active") {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

  dispatch({
    type: INITIATIVE_MODAL,
    payload,
  });
};

export const initiativeDetailClear = () => (dispatch) => {
  dispatch({
    type: INITIATIVE_DETAIL_CLEAR,
  });
};

export const initiativeDetailLoading = (payload) => (dispatch) => {
  dispatch({
    type: INITIATIVE_DETAIL_LOADING,
    payload,
  });
};

export const initiativeGetDetail = (slug) => (dispatch) => {
  dispatch(initiativeDetailLoading(true));

  Api.callMethod(
    `initiatives/${slug}`,
    "get",
    null,
    (res) => {
      const { data } = res;
      dispatch({
        type: INITIATIVE_DETAIL,
        payload: data,
      });
      dispatch(initiativeDetailLoading(false));
    },
    (error) => {
      console.log("Error initiativeGet: ", error);
    }
  );
};

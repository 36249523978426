import {
  DOCS_CHANGE_PAGE,
  DOCS_CHANGE_LIST_VIEW,
  DOCS_VIEW_DETAIL,
  DOCS_DATA,
  DOCS_DETAIL,
  DOCS_SEARCH,
  DOCS_POPULATION,
  DOCS_SECTIONS,
  DOCS_COMPONENTS,
  DOCS_DRIVERS,
  DOCS_TERRITORIES,
  DOCS_CLEAR,
  DOCS_ACTION_LEVELS,
  DOCS_CLEAR_FILTERS,
  DOCS_VIEW_GRAPH,
  DOCS_LIST_TYPE,
  DOCS_AUTHOR,
  DOCS_YEAR,
} from "../types/docsTypes";

const INITIAL_STATE = {
  docs_list: [],
  docs_list_view: [],
  docs_detail: [],
  docs_current_page: 1,
  docs_items_per_page: 18,
  docs_view_detail: false,
  docs_search: "",
  docs_population_select: [],
  docs_sections_select: [],
  docs_components_select: [],
  docs_territories_select: [],
  docs_drivers_select: [],
  docs_action_levels_select: [],
  docs_author_select: [],
  docs_year_select: [],
  docs_view_graph: true,
  docs_list_type: "icon",
};

const docsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOCS_CHANGE_PAGE:
      return {
        ...state,
        docs_current_page: action.payload,
      };
    case DOCS_CHANGE_LIST_VIEW:
      return {
        ...state,
        docs_list_view: action.payload,
      };
    case DOCS_VIEW_DETAIL:
      return {
        ...state,
        docs_view_detail: action.payload,
      };
    case DOCS_DATA:
      return {
        ...state,
        docs_list: action.payload.docs,
        docs_list_view: action.payload.docs_view,
        docs_current_page: 1,
      };
    case DOCS_DETAIL:
      return {
        ...state,
        docs_detail: action.payload,
      };
    case DOCS_SEARCH:
      return {
        ...state,
        docs_search: action.payload,
      };
    case DOCS_POPULATION:
      return {
        ...state,
        docs_population_select: action.payload,
      };
    case DOCS_SECTIONS:
      return {
        ...state,
        docs_sections_select: action.payload,
      };
    case DOCS_COMPONENTS:
      return {
        ...state,
        docs_components_select: action.payload,
      };
    case DOCS_DRIVERS:
      return {
        ...state,
        docs_drivers_select: action.payload,
      };
    case DOCS_TERRITORIES:
      return {
        ...state,
        docs_territories_select: action.payload,
      };
    case DOCS_ACTION_LEVELS:
      return {
        ...state,
        docs_action_levels_select: action.payload,
      };
    case DOCS_AUTHOR:
      return {
        ...state,
        docs_author_select: action.payload,
      };
    case DOCS_YEAR:
      return {
        ...state,
        docs_year_select: action.payload,
      };
    case DOCS_CLEAR:
      return {
        ...state,
        docs_list: action.payload.docs,
        docs_list_view: action.payload.docs_view,
        docs_detail: [],
        docs_current_page: 1,
        docs_view_detail: false,
        docs_search: "",
      };
    case DOCS_CLEAR_FILTERS:
      return {
        ...state,
        docs_population_select: [],
        docs_sections_select: [],
        docs_components_select: [],
        docs_territories_select: [],
        docs_drivers_select: [],
        docs_action_levels_select: [],
        docs_author_select: [],
        docs_year_select: [],
      };
    case DOCS_VIEW_GRAPH: {
      return {
        ...state,
        docs_view_graph: action.payload,
      };
    }
    case DOCS_LIST_TYPE:
      return {
        ...state,
        docs_list_type: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default docsReducer;

import React from "react";
import ReactDOM from "react-dom";
import "./css/ModalTutorialEcosystems.css";
import { connect } from "react-redux";

function ModalTutorialInitiative(props) {
  const {
    defaultReducer: { images },
  } = props;
  let image = images.find((item) => item.label === "ecosistemas-iconos");

  return ReactDOM.createPortal(
    <div className={`tutorial-ecosystem-content ${props.visible}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tutorial-ecosystem-container">
              <div
                className="tutorial-ecosystem-close"
                onClick={() => props.openCloseModal("")}
              >
                X
              </div>
              <div className="tutorial-ecosystem-image">
                <img src={image.image} alt="Iconografía" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-initiative")
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default connect(
  mapStateToProps,
  null
)(React.memo(ModalTutorialInitiative));

import React from 'react';
import ReactDOM from 'react-dom';
import loaded from '../images/loading.svg';
import './css/ModalLoading.css';

function ModalLoading(props) {
  return ReactDOM.createPortal(
    <div className={`modal-loading ${props.visible}`}>
      <img src={loaded} alt="loaded"/>
      <div className="modal-loading-txt">Cargando...</div>
    </div>,
    document.getElementById('modal')
  )
}

export default ModalLoading;
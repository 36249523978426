import React from "react";
import "./css/InitiativeIcon.css";

function InitiativeIcon(props) {
  const { icon, name, position } = props;
  return (
    <div className={`InitiativeIcon_content ${position}`}>
      <div className="InitiativeIcon_tootip">
        <div className="InitiativeIcon_tootip-arrow" />
        {name}
      </div>
      <img src={icon} alt={name} />
    </div>
  );
}

InitiativeIcon.defaultProps = {
  position: "",
};

export default InitiativeIcon;

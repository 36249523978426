import swal from "sweetalert";
import Api from "../Api";
import {
  USER_DATA,
  USER_EMAIL,
  USER_ENTITY,
  USER_FORM_CLEAR,
  USER_FORM_MODAL,
  USER_FORM_TYPE,
  USER_LOADING,
  USER_NAME,
  USER_PASSWORD,
  USER_PHONE,
  USER_TERMS,
} from "../types/userTypes";

const email_reg = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const userLoading = (status, message) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
    payload: { status, message },
  });
};

export const userName = (payload) => (dispatch) => {
  dispatch({
    type: USER_NAME,
    payload,
  });
};

export const userEmail = (payload) => (dispatch) => {
  dispatch({
    type: USER_EMAIL,
    payload,
  });
};

export const userPassword = (payload) => (dispatch) => {
  dispatch({
    type: USER_PASSWORD,
    payload,
  });
};

export const userEntity = (payload) => (dispatch) => {
  dispatch({
    type: USER_ENTITY,
    payload,
  });
};

export const userPhone = (payload) => (dispatch) => {
  dispatch({
    type: USER_PHONE,
    payload,
  });
};

export const userFormType = (payload) => (dispatch) => {
  dispatch({
    type: USER_FORM_TYPE,
    payload,
  });
};

export const userFormModal = (payload) => (dispatch) => {
  dispatch({
    type: USER_FORM_CLEAR,
  });

  dispatch({
    type: USER_FORM_MODAL,
    payload,
  });
};

export const userTerms = () => (dispatch, getState) => {
  const { user_terms } = getState().userReducer;

  dispatch({
    type: USER_TERMS,
    payload: !user_terms,
  });
};

export const userSaveData = () => (dispatch, getState) => {
  const {
    user_name,
    user_email,
    user_password,
    user_entity,
    user_phone,
    user_form_type,
    user_terms,
  } = getState().userReducer;

  if (!user_name.length) {
    swal("Upsss!!!", "Escriba su nombre.", "warning");
    return;
  }
  if (!user_phone.length) {
    swal("Upsss!!!", "Escriba un número telefónico de contacto.", "warning");
    return;
  }
  if (!user_entity.length) {
    swal("Upsss!!!", "Escriba la entidad a la que pertenece.", "warning");
    return;
  }
  if (user_form_type === "register" && !user_terms) {
    swal(
      "Upsss!!!",
      "Debe aceptar la política de tratamiento y uso de datos.",
      "warning"
    );
    return;
  }

  const data = {
    name: user_name,
    entity: user_entity,
    phone: user_phone,
  };

  if (user_form_type === "register") {
    if (!user_email.length) {
      swal("Upsss!!!", "Escriba su email.", "warning");
      return;
    }
    if (!email_reg.test(user_email)) {
      swal("Upsss!!!", "Valide que el email este bien escrito.", "warning");
      return;
    }
    data.email = user_email;
    if (!user_password.length || user_password.length < 6) {
      swal(
        "Upsss!!!",
        "La contraseña debe tener mínimo 6 caracteres.",
        "warning"
      );
      return;
    }
    data.password = user_password;
  } else {
    if (user_password.length > 0 && user_password.length < 6) {
      swal(
        "Upsss!!!",
        "La contraseña debe tener mínimo 6 caracteres.",
        "warning"
      );
      return;
    }
    if (user_password.length >= 6) {
      data.password = user_password;
    }
  }

  const action =
    user_form_type === "register" ? "auth/register" : "auth/user/update";
  const method = user_form_type === "register" ? "POST" : "PUT";
  const code = user_form_type === "register" ? 201 : 200;
  const message =
    user_form_type === "register" ? "Registrando..." : "Actualizando datos...";
  const successMessage =
    user_form_type === "register"
      ? "Registro exitoso, ahora puede iniciar sesión."
      : "Los datos se actualzarón con éxito.";

  dispatch(userLoading(true, message));

  Api.callMethod(
    action,
    method,
    data,
    (res) => {
      if (res.code === code) {
        swal("¡Grandioso!", successMessage, "success").then(() => {
          dispatch(userLoading(false, ""));
          if (user_form_type === "register") {
            dispatch(userFormType("login"));
          } else {
            document.cookie = `includere_user=${JSON.stringify(res.user)}`;

            dispatch({
              type: USER_DATA,
              payload: res.user,
            });
          }
        });
        return;
      }
      swal(
        "Upsss!!!",
        "Hubo un error, valide los datos ingresaro e intentelo nuevamente.",
        "error"
      ).then(() => {
        dispatch(userLoading(false, ""));
      });
    },
    (error) => {
      console.log("Error Register: ", error);
      swal(
        "Upsss!!!",
        "Hubo un error, valide los datos ingresaro e intentelo nuevamente."
      ).then(() => {
        dispatch(userLoading(false, ""));
      });
    }
  );
};

export const userLogin = () => (dispatch, getState) => {
  const { user_email, user_password } = getState().userReducer;

  if (!user_email.length) {
    swal("Upsss!!!", "Escriba su email.", "warning");
    return;
  }
  if (!email_reg.test(user_email)) {
    swal("Upsss!!!", "Valide que el email este bien escrito.", "warning");
    return;
  }
  if (!user_password.length) {
    swal("Upsss!!!", "Escriba la contraseña.", "warning");
    return;
  }

  const data = {
    email: user_email,
    password: user_password,
  };

  dispatch(userLoading(true, "Iniciando sesión..."));

  Api.callMethod(
    "auth/login",
    "POST",
    data,
    (res) => {
      if (res.code === 200) {
        const { access_token, expires_at, user } = res;
        swal("¡Felicidades!", "Has iniciado sesión.", "success").then(() => {
          let expires_session = new Date(expires_at);
          expires_session = expires_session.toUTCString();
          document.cookie = `includere_token=${access_token}; expires=${expires_session}`;
          document.cookie = `includere_user=${JSON.stringify(
            user
          )}; expires=${expires_session}`;

          dispatch({
            type: USER_DATA,
            payload: user,
          });
          dispatch(userLoading(false, ""));
          dispatch(userFormModal(""));
        });
        return;
      }
      swal(
        "Upsss!!!",
        "Hubo un error, valide los datos ingresaro e intentelo nuevamente."
      );
      dispatch(userLoading(false, ""));
    },
    (error) => {
      console.log("Error Login: ", error);
      swal(
        "Upsss!!!",
        "Hubo un error, valide los datos ingresaro e intentelo nuevamente."
      );
      dispatch(userLoading(false, ""));
    }
  );
};

export const userForgot = () => (dispatch, getState) => {
  const { user_email } = getState().userReducer;

  if (!user_email.length) {
    swal("Upsss!!!", "Escriba su email.");
    return;
  }
  if (!email_reg.test(user_email)) {
    swal("Upsss!!!", "Valide que el email este bien escrito.");
    return;
  }

  const data = {
    email: user_email,
  };

  dispatch(userLoading(true, "Recuperando contraseña..."));

  Api.callMethod(
    "auth/forgot",
    "POST",
    data,
    (res) => {
      if (res.code === 200) {
        swal(
          "Upsss!!!",
          "La contraseña ha sido enviada al correo escrito.",
          "success"
        ).then(() => {
          dispatch(userLoading(false, ""));
          dispatch(userFormType("login"));
        });

        return;
      }
      swal(
        "Upsss!!!",
        "Hubo un error, valide los datos ingresaro e intentelo nuevamente.",
        "error"
      ).then(() => {
        dispatch(userLoading(false, ""));
      });
    },
    (error) => {
      console.log("Error Forgot: ", error);
      swal(
        "Upsss!!!",
        "Hubo un error, valide los datos ingresaro e intentelo nuevamente."
      ).then(() => {
        dispatch(userLoading(false, ""));
      });
    }
  );
};

export const userLogout = () => (dispatch) => {
  Api.callMethod(
    "auth/user/logout",
    "POST",
    null,
    (res) => {
      document.cookie =
        "includere_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
      document.cookie =
        "includere_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
      return res;
    },
    (err) => err
  );
};

export const userSessionStatus = () => (dispatch) => {
  const cookies = document.cookie.split(";");
  const token = cookies.filter((item) => item.indexOf("includere_token=") >= 0);
  const user = cookies.filter((item) => item.indexOf("includere_user=") >= 0);

  if (user.length) {
    let userData = user[0].split("includere_user=");
    userData = JSON.parse(userData[1]);

    dispatch({
      type: USER_DATA,
      payload: userData,
    });
  }
  if (token.length) {
    Api.callMethod(
      "auth/user",
      "GET",
      null,
      (res) => {
        if (res.code === 200) {
          dispatch({
            type: USER_DATA,
            payload: res.user,
          });
        } else {
          dispatch(userLogout());
        }
      },
      (error) => {
        console.log("Error CurrentUSer: ", error);
        dispatch(userLogout());
      }
    );
  }
};

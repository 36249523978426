import React from 'react';

function Error404(props) {
  return (
    <div className="error_content">
      <div className="error_title">Upss!!!</div>
      <div className="error_error">Error 404</div>
      <div className="error_description">La página no se encuentra disponible.</div>
    </div>
  )
}

export default Error404;

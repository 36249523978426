import React from 'react';

function EcosystemCount(props) {
  return(
    <div className="ecosystems-count">
      <div className="subtitle-section">{props.title}</div>
      <div className="ecosystems-count-content bg-gray-gradient">
        <div className="ecosystems-count-number">{props.total}</div>
      </div>
    </div>
  )
}

export default EcosystemCount;
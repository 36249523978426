export const MODAL_CHANGE_STATUS = "MODAL_CHANGE_STATUS";
export const MODAL_CHANGE_TYPE = "MODAL_CHANGE_TYPE";
export const MODAL_CHANGE_TITLE = "MODAL_CHANGE_TITLE";
export const MODAL_CHANGE_NAME = "MODAL_CHANGE_NAME";
export const MODAL_CHANGE_EMAIL = "MODAL_CHANGE_EMAIL";
export const MODAL_CHANGE_INSTITUTION = "MODAL_CHANGE_INSTITUTION";
export const MODAL_CHANGE_PHONE = "MODAL_CHANGE_PHONE";
export const MODAL_CHANGE_MESSAGE = "MODAL_CHANGE_MESSAGE";
export const MODAL_CHANGE_SECTION = "MODAL_CHANGE_SECTION";
export const MODAL_CHANGE_REQUEST = "MODAL_CHANGE_REQUEST";
export const MODAL_CLEAR = "MODAL_CLEAR";
export const MODAL_ERROR = "MODAL_ERROR";
export const MODAL_LOADED = "MODAL_LOADED";
export const MODAL_SUCCESS = "MODAL_SUCCESS";
export const MODAL_LINK = "MODAL_LINK";
export const MODAL_SURVEY = "MODAL_SURVEY";

import React from "react";

function TerritoryMenu(props) {
  const { menu, selected, onChange, value } = props;

  return (
    <>
      <select
        className="form-control territoryInfoPage_submenu-mobile"
        value={value}
        onChange={(input) => onChange(input.target.value)}
      >
        {menu.map((item, index) => (
          <option value={index} key={index}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="territoryInfoPage_submenu">
        {menu.map((item, index) => {
          const active = item.id === selected.id ? "active" : "";
          return (
            <button
              value={index}
              key={index}
              style={{
                backgroundColor: item.background,
                color: item.color,
                width: `calc(100% / ${menu.length})`,
              }}
              className={active}
              onClick={() => onChange(index)}
            >
              {item.name}
            </button>
          );
        })}
      </div>
    </>
  );
}

export default TerritoryMenu;

import React from 'react';
import ReactDOM from 'react-dom';
import './css/ModalTutorialEcosystems.css';
import arrow from '../images/intro-arrow.png';
import { connect } from 'react-redux';

function ModalTutorialEcosystems(props) {
  const {
    defaultReducer: { texts, images }
  } = props;
  let modalTexts = texts.filter(item => (item.label.indexOf('ecosistemas_tutorial') >= 0));
  let modalImages = images.filter(item => (item.label.indexOf('ecosistemas') >= 0));

  return ReactDOM.createPortal(
    <div className={`tutorial-ecosystem-content ${props.visible}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tutorial-ecosystem-container">
              <div className="tutorial-ecosystem-close" onClick={() => props.openCloseModal('')}>
                X
              </div>
              <div className="tutorial-ecosystem-header">
                <div className="tutorial-ecosystem-1">
                  <div className="tutorial-ecosystem-1-content">
                    <div className="tutorial-ecosystem-number">1.</div>
                    <img src={modalImages[0].image} alt={modalImages[0].label} className="tutorial-ecosystem-img"/>
                  </div>
                  <p>{modalTexts[0].value}</p>
                </div>
                <div className="tutorial-ecosystem-2">
                  <div className="tutorial-ecosystem-number">2.</div>
                  <div>
                    <div className="tutorial-ecosystem-2-content">
                      <img src={modalImages[1].image} alt={modalImages[1].label} className="tutorial-ecosystem-img"/>
                      <img src={arrow} alt="Arrow2" className="tutorial-ecosystem-arrow"/>
                      <p>{modalTexts[1].value}</p>
                    </div>
                    <p className="tutorial-ecosystem-2-description">
                      {modalTexts[2].value}
                    </p>
                  </div>
                </div>
              </div>
              <div className="tutorial-ecosystem-3">
                <div className="tutorial-ecosystem-number">3.</div>
                <div>
                  <div className="tutorial-ecosystem-3-content">
                    <img src={modalImages[2].image} alt={modalImages[2].label} className="tutorial-ecosystem-img"/>
                    <img src={arrow} alt="Arrow03" className="tutorial-ecosystem-arrow"/>
                    <p>{modalTexts[3].value}</p>
                  </div>
                  <p className="tutorial-ecosystem-3-description">{modalTexts[4].value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

const mapStateToProps = ({defaultReducer}) => {
  return {
    defaultReducer,
  }
}

export default connect(mapStateToProps, null)(ModalTutorialEcosystems);
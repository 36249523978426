import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  userName,
  userEntity,
  userPassword,
  userPhone,
  userSaveData,
} from "../../functionality/actions/userActions";

class Profile extends Component {
  componentDidMount() {
    const {
      userReducer: { user },
      userName,
      userEntity,
      userPhone,
    } = this.props;
    userName(user.name);
    userEntity(user.entity);
    userPhone(user.phone);
  }
  render() {
    const {
      userReducer: { user_name, user_entity, user_password, user_phone },
      userName,
      userEntity,
      userPassword,
      userPhone,
      userSaveData,
    } = this.props;
    return (
      <div>
        <h1>MIS DATOS</h1>
        <div className="form-group">
          <label>NOMBRE:</label>
          <input
            type="text"
            className="form-control"
            value={user_name}
            onChange={(input) => userName(input.target.value)}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>TELÉFONO:</label>
              <input
                type="text"
                className="form-control"
                value={user_phone}
                onChange={(input) => userPhone(input.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>ENTIDAD:</label>
              <input
                type="text"
                className="form-control"
                value={user_entity}
                onChange={(input) => userEntity(input.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>CONTRASEÑA:</label>
          <input
            type="password"
            className="form-control"
            value={user_password}
            onChange={(input) => userPassword(input.target.value)}
          />
        </div>
        <button className="Forms_button" onClick={userSaveData}>
          ACTUALIZAR
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  userName,
  userEntity,
  userPassword,
  userPhone,
  userSaveData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

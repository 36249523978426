import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

function SearchItem(props) {
  const {item, type} = props;
  let slug = (item.slug) ? item.slug : item.id;
  let to_url = (type === 1) ? `/herramientas-metodologias/${item.id}`
    : (type === 2) ? `/documentos-tecnicos/${slug}`
    : item.links;
  return(
    <div className="col-md-4">
      <div className="search-item">
        {type === 1 || type === 2 ?
          <Fragment>
            <div className="searc-box">
              <img src={item.coverpage} alt="Coverpage" className="search-coverpage"/>
              {item.title}
            </div>
            <div className="search-item-menu">
              <Link to={to_url}>Ver más</Link>
            </div>
          </Fragment>
          :
          <Fragment>
            {item.iniciativa}
            <div className="search-item-menu">
              <a href={to_url} target="_blank" rel="noopener noreferrer">Ver link</a>
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default SearchItem;
import React from "react";
import { connect } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./css/InitiativeButtonExcel.css";

function InitiativeButtonExcel(props) {
  const {
    initiativeReducer: { initiative_view },
  } = props;
  return (
    <div className="InitiativeButtonExcel_container">
      <ReactHTMLTableToExcel
        id="InitiativeButtonExcel_button"
        className="InitiativeButtonExcel_button"
        table="table-initiatives"
        filename="includere"
        sheet="includere"
        buttonText="DESCARGAR REPORTE"
      />
      <div className="table-responsive">
        <table className="InitiativeButtonExcel_table" id="table-initiatives">
          <thead>
            <tr>
              <th>Iniciativa</th>
              <th>Tipo</th>
              <th>Descripción</th>
              <th>Vigente</th>
              <th>¿Tiene elementos de Emprendimiento?</th>
              <th>Año Inicio</th>
              <th>Año Finalización</th>
              <th>Virtualidad</th>
              <th>Casos Especiales</th>
              <th>Link Oficial</th>
              <th>Links</th>
              <th>Actores</th>
              <th>Poblaciones</th>
              <th>Tramos</th>
              <th>Componentes</th>
              <th>Niveles</th>
              <th>Territorios</th>
            </tr>
          </thead>
          <tbody>
            {initiative_view.map((initiative, index) => (
              <tr key={index}>
                <td>{initiative.name}</td>
                <td>{initiative.type}</td>
                <td>{initiative.description}</td>
                <td>{initiative.validity}</td>
                <td>{initiative.entrepreneurship}</td>
                <td>{initiative.start_year}</td>
                <td>{initiative.completion_year}</td>
                <td>{initiative.virtuality}</td>
                <td>{initiative.special_cases}</td>
                <td>{initiative.official_link}</td>
                <td>{initiative.links}</td>
                <td>
                  {initiative.actors.map((actor, index) => (
                    <span key={index}>{actor.name}, </span>
                  ))}
                </td>
                <td>
                  {initiative.populations.map((population, index) => (
                    <span key={index}>{population.name}, </span>
                  ))}
                </td>
                <td>
                  {initiative.stretches.map((stretch, index) => (
                    <span key={index}>{stretch.name}, </span>
                  ))}
                </td>
                <td>
                  {initiative.components.map((component, index) => (
                    <span key={index}>{component.name}, </span>
                  ))}
                </td>
                <td>
                  {initiative.levels.map((level, index) => (
                    <span key={index}>{level.name}, </span>
                  ))}
                </td>
                <td>
                  {initiative.cities.map((city, index) => (
                    <span key={index}>{city.name}, </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = ({ initiativeReducer }) => {
  return {
    initiativeReducer,
  };
};

export default connect(mapStateToProps, null)(InitiativeButtonExcel);

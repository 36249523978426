import React from 'react';
import './css/BoxPopulation.css';

function BoxPopulation(props) {
  return(
    <button onClick={props.action} className="box-population">
      <div className="box-population-title">{props.title}</div>
      <div className="box-population-more"><span className="icon-plus"/></div>
      {props.number > 0 ?
        <div className={`box-population-number ${props.background}`}>{props.number}</div>
        :
        <div className={`box-population-number gray`}>-</div>
      }
    </button>
  )
}

export default BoxPopulation;
import React, { Fragment } from 'react';
import BoxStage from './BoxStage';

function TerritoryStages(props) {
  const {titles} = props;

  return(
    <Fragment>
      <div className="subtitle-section">
        {titles[11].value} <span>{titles[12].value}</span>
      </div>
      <div className="territory-stages bg-gray-gradient">
        <BoxStage
          title={titles[13].value}
          subtitle={titles[14].value}
          button={true}
          values={props.stage_half}
          goTo='detalle_t1'
          tooltip={titles[22].value}
          titleTooltip={titles[32].value}
          contentTooltip={titles[33].value}
        />
        <BoxStage
          title={titles[15].value}
          subtitle={titles[16].value}
          values={props.stage_postmedia}
          button={true}
          goTo='detalle_t2'
          tooltip={titles[23].value}
          titleTooltip={titles[34].value}
          contentTooltip={titles[35].value}
        />
        <BoxStage
          title={titles[17].value}
          subtitle={titles[18].value}
          values={props.stage_intermediation}
          button={true}
          goTo='detalle_t3'
          tooltip={titles[24].value}
          titleTooltip={titles[36].value}
          contentTooltip={titles[37].value}
        />
        <BoxStage
          title={titles[19].value}
          subtitle={titles[20].value}
          values={props.stage_job}
          button={true}
          goTo='detalle_t4'
          tooltip={titles[25].value}
          titleTooltip={titles[38].value}
          contentTooltip={titles[39].value}
        />
      </div>
    </Fragment>
  )
}

export default TerritoryStages;
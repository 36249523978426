import {
  DEFAULT_CITIES,
  DEFAULT_REGIONS,
  DEFAULT_OTHER_CONTENTS,
  DEFAULT_OTHER_DOCUMENTS,
  DEFAULT_DOCUMENTS,
  DEFAULT_TOOLS,
  DEFAULT_ECOSYSTEMS,
  DEFAULT_TERRITORIES,
  DEFAULT_MOST_RECENTS,
  DEFAULT_CONTENTS,
  DEFAULT_EMAIL_NEWSLETTER,
  DEFAULT_NEWSLETTER_MESSAGE,
  DEFAULT_NEWSLETTER_STATUS,
  DEFAULT_INDEX_CLEAR,
  DEFAULT_SEARCH,
  DEFAULT_SEARCH_DATA,
  DEFAULT_SEARCH_CLEAR,
  DEFAULT_SEARCH_STATUS,
  DEFAULT_MENU,
  DEFAULT_CATEGORIES,
} from "../types/defaultTypes";

const INITIAL_STATE = {
  cities: [],
  regions: [],
  documents: [],
  tools: [],
  ecosystems: [],
  territories: [],
  other_contents: [],
  other_documents: [],
  most_recents: [],
  filter_population: [],
  filter_sections: [],
  filter_components: [],
  filter_drivers: [],
  filter_action_levels: [],
  filter_tools: [],
  email_newsletter: "",
  newsletter_message: "",
  newsletter_status: false,
  search: "",
  search_data: [],
  search_status: false,
  menu_visible: "",
  social_networks: [],
  texts: [],
  territory_titles: [],
  ecosystem_titles: [],
  document_titles: [],
  news: [],
  allNews: [],
  sliders: [],
  images: [],
  categories: [],
  authors: [],
  years: [],
};

const defaultReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEFAULT_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case DEFAULT_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case DEFAULT_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case DEFAULT_TOOLS:
      return {
        ...state,
        tools: action.payload,
      };
    case DEFAULT_ECOSYSTEMS:
      return {
        ...state,
        ecosystems: action.payload,
      };
    case DEFAULT_TERRITORIES:
      return {
        ...state,
        territories: action.payload,
      };
    case DEFAULT_OTHER_CONTENTS:
      return {
        ...state,
        other_contents: action.payload,
      };
    case DEFAULT_OTHER_DOCUMENTS:
      return {
        ...state,
        other_documents: action.payload,
      };
    case DEFAULT_MOST_RECENTS:
      return {
        ...state,
        most_recents: action.payload,
      };
    case DEFAULT_CONTENTS:
      return {
        ...state,
        cities: action.payload.cities,
        regions: action.payload.regions,
        documents: action.payload.documents,
        tools: action.payload.tools,
        ecosystems: action.payload.ecosystems,
        territories: action.payload.territories,
        other_contents: action.payload.other_contents,
        other_documents: action.payload.other_documents,
        most_recents: action.payload.most_recents,
        filter_population: action.payload.filter_population,
        filter_tools: action.payload.filter_tools,
        filter_sections: action.payload.filter_sections,
        filter_components: action.payload.filter_components,
        filter_drivers: action.payload.filter_drivers,
        filter_action_levels: action.payload.filter_action_levels,
        social_networks: action.payload.social_networks,
        images: action.payload.images,
        texts: action.payload.texts,
        news: action.payload.news,
        allNews: action.payload.allNews,
        categories: action.payload.categories,
        sliders: action.payload.sliders,
        territory_titles: action.payload.texts.filter(
          (item) => item.type === 2
        ),
        ecosystem_titles: action.payload.texts.filter(
          (item) => item.type === 3
        ),
        document_titles: action.payload.texts.filter((item) => item.type === 4),
        authors: action.payload.authors,
        years: action.payload.years,
      };
    case DEFAULT_EMAIL_NEWSLETTER:
      return {
        ...state,
        email_newsletter: action.payload,
        newsletter_message: "",
      };
    case DEFAULT_NEWSLETTER_MESSAGE:
      return {
        ...state,
        newsletter_message: action.payload,
      };
    case DEFAULT_NEWSLETTER_STATUS:
      return {
        ...state,
        newsletter_status: action.payload,
      };
    case DEFAULT_INDEX_CLEAR:
      return {
        ...state,
        newsletter_message: "",
      };
    case DEFAULT_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case DEFAULT_SEARCH_DATA:
      return {
        ...state,
        search_data: action.payload,
        search_status: false,
      };
    case DEFAULT_SEARCH_STATUS:
      return {
        ...state,
        search_status: action.payload,
      };
    case DEFAULT_SEARCH_CLEAR:
      return {
        ...state,
        search: "",
        search_data: [],
        search_status: false,
      };
    case DEFAULT_MENU:
      return {
        ...state,
        menu_visible: action.payload,
      };
    case DEFAULT_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return { ...state };
  }
};

export default defaultReducer;

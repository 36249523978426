import React from "react";
import "./css/FilterSelect.css";

function FilterSelect(props) {
  let { options, value, action, type, title, color, national_value } = props;

  let is_selected = [];
  if (type === "territories" || type === "author") {
    is_selected = options.filter((item) => item.id === value);
  } else {
    is_selected = options.filter((item) => item.value === value);
  }
  let name = "";
  let national = national_value ? national_value : "nacional";
  if (is_selected.length > 0) {
    name = is_selected[0].name;
  } else if (value === "nacional") {
    name = "NACIONAL";
  } else {
    name = title;
  }

  return (
    <div className={`filter-select-box bg-gray-gradient ${color}`}>
      <div className="filter-select-header">
        <div className="filter-select-title">{name.slice(0, 30)}</div>
        <span className="icon-menu" />
      </div>
      <div className="filter-select-content">
        <button onClick={() => action(type, 0)}>{title}</button>
        {type === "territories" && (
          <button onClick={() => action(type, national)}>NACIONAL</button>
        )}
        {options.map((item) => {
          let value =
            type === "territories" || type === "author" ? item.id : item.value;

          return (
            <button onClick={() => action(type, value)} key={item.id}>
              {item.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}

FilterSelect.defaultProps = {
  color: "",
};

export default FilterSelect;

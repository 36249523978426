import React, {Fragment} from 'react';
import './css/EcosystemStage.css';
import ButtonYellow from './ButtonYellow';
import EcosystemBox from './EcosystemBox';

function EcosystemStage(props) {
  const {
    ecosystem_harsh, ecosystem_soft, ecosystem_ocupational, ecosystem_specific,
    ecosystem_general, ecosystem_vocational, ecosystem_intermediation_labor,
    ecosystem_intermediation_talent, ecosystem_orientation_labor,
    ecosystem_orientation_talent, action, cod_city, titles,
  } = props;
  let city = (cod_city) ? cod_city : null;
  return(
    <Fragment>
      <div className="subtitle-section">{titles[1].value} <span>{titles[2].value}</span></div>
      <div className="ecosystem-stage-content bg-gray-gradient">
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div className="ecosystem-stage-title-txt">
              {titles[3].value}
            </div>
            <ButtonYellow action={() => action('t1', titles[3].value, city)}
              tooltip={titles[33].value}
            />
          </div>
          <EcosystemBox
            name={titles[4].value}
            number={ecosystem_harsh}
          />
          <EcosystemBox
            name={titles[5].value}
            number={ecosystem_soft}
          />
          <EcosystemBox
            name={titles[6].value}
            number={ecosystem_ocupational}
          />
        </div>
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div className="ecosystem-stage-title-txt">
              {titles[7].value}
            </div>
            <ButtonYellow action={() => action('t2', titles[7].value, city)}
              tooltip={titles[34].value}
            />
          </div>
          <EcosystemBox
            name={titles[8].value}
            number={ecosystem_specific}
          />
          <EcosystemBox
            name={titles[9].value}
            number={ecosystem_general}
          />
          <EcosystemBox
            name={titles[10].value}
            number={ecosystem_vocational}
          />
        </div>
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div className="ecosystem-stage-title-txt">
              {titles[11].value}
            </div>
            <ButtonYellow action={() => action('t3', titles[11].value, city)}
              tooltip={titles[35].value}
            />
          </div>
          <EcosystemBox
            name={titles[12].value}
            number={ecosystem_intermediation_labor}
          />
          <EcosystemBox
            name={titles[13].value}
            number={ecosystem_intermediation_talent}
          />
        </div>
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div className="ecosystem-stage-title-txt">
              {titles[14].value}
            </div>
            <ButtonYellow action={() => action('t4', titles[14].value, city)}
              tooltip={titles[36].value}
            />
          </div>
          <EcosystemBox
            name={titles[15].value}
            number={ecosystem_orientation_labor}
          />
          <EcosystemBox
            name={titles[16].value}
            number={ecosystem_orientation_talent}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default EcosystemStage;
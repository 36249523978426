import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { initialSite } from "./functionality/actions/indexActions";
import { userSessionStatus } from "./functionality/actions/userActions";
import GA from "./utils/GoogleAnalytics";
import IndexPage from "./pages/IndexPage";
import AboutPage from "./pages/AboutPage";
import TerritoryPage from "./pages/TerritoryPage";
import ToolsPage from "./pages/ToolsPage";
import EcosystemsPage from "./pages/EcosystemsPage";
import DocumentsPage from "./pages/DocumentsPage";
import ContactPage from "./pages/ContactPage";
import SearchPage from "./pages/SearchPage";
import NewsPage from "./pages/NewsPage";
import ModalLoading from "./components/ModalLoading";
import NewsDetailPage from "./pages/NewsDetailPage";
import ErrorPage from "./pages/ErrorPage";
import TerritoryInfoPage from "./pages/TerritoryInfoPage";
import TerritoryTabPage from "./pages/TerritoryTabPage";
import InitiativesPage from "./pages/InitiativesPage";
import SurveyFormPage from "./pages/SurveyFormPage";

class Navigation extends Component {
  componentDidMount() {
    const { initialSite, userSessionStatus } = this.props;
    userSessionStatus();
    initialSite();
  }
  render() {
    const {
      loadingReducer: { loading_initial },
      defaultReducer: { texts },
    } = this.props;
    const end_link = texts.filter((item) => item.label === "menu_6");
    let end_route = !end_link.length ? "noticias" : end_link[0].value;
    end_route = end_route.toLowerCase().replace(" ", "-");

    return (
      <Router>
        {GA.init() && <GA.RouteTracker />}
        <ModalLoading visible={loading_initial} />
        {loading_initial === "hidden" && (
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/buscar/:words" component={SearchPage} />
            <Route exact path="/quienes-somos" component={AboutPage} />
            <Route
              exact
              path="/territorio/:type"
              component={TerritoryInfoPage}
            />
            <Route exact path="/datos-territorio" component={TerritoryPage} />
            <Route
              exact
              path="/datos-territorio/ciudad/:id"
              component={TerritoryPage}
            />
            <Route
              exact
              path="/herramientas-metodologias"
              component={ToolsPage}
            />
            <Route
              exact
              path="/herramientas-metodologias/:id"
              component={ToolsPage}
            />
            <Route
              exact
              path="/ecosistemas-iniciativas"
              component={InitiativesPage}
            />
            <Route
              exact
              path="/ecosistemas-iniciativas/:initiative"
              component={InitiativesPage}
            />
            <Route
              exact
              path="/ecosistemas-iniciativas/ciudad/:id"
              component={EcosystemsPage}
            />
            <Route
              exact
              path="/documentos-tecnicos"
              component={DocumentsPage}
            />
            <Route
              exact
              path="/documentos-tecnicos/:id"
              component={DocumentsPage}
            />
            <Route exact path="/contacto" component={ContactPage} />
            <Route
              exact
              path="/encuesta-formulario/:id"
              component={SurveyFormPage}
            />
            <Route exact path={`/${end_route}`} component={NewsPage} />
            <Route
              exact
              path={`/${end_route}/:id`}
              component={NewsDetailPage}
            />
            <Route
              exact
              path="/territorio/grafico/:powerBi"
              component={TerritoryPage}
            />
            <Route exact path="/territorio" component={TerritoryTabPage} />
            <Route
              exact
              path="/territorio/pestana/:tab"
              component={TerritoryTabPage}
            />
            <Route
              exact
              path="/territorio/pestana/:tab/contenido/:tab_item"
              component={TerritoryTabPage}
            />
            <Route component={ErrorPage} />
          </Switch>
        )}
      </Router>
    );
  }
}

const mapStateToProps = ({ loadingReducer, defaultReducer }) => {
  return {
    loadingReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  initialSite,
  userSessionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

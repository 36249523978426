import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import './css/Footer.css';
import * as indexActions from '../functionality/actions/indexActions';
import footerImages from '../images/logo-footer.png';
import logoFundacion from '../images/logo-fundacion.png';

const {changeEmailNewletter, sendEmailNewsletter} = indexActions;

function Footer(props) {
  const {
    social_networks, other_contents, images,
  } = props.defaultReducer;
  // const {
  //   email_newsletter, newsletter_message, newsletter_status
  // } = props.defaultReducer;
  let footer = other_contents.filter(item => item.label === "quines_somos_footer");
  let contact = other_contents.filter(item => item.label === "contacto");
  let imagesFooter = images.filter(item => (item.label.indexOf('footer') >= 0));
  let image1 = (!imagesFooter.length || !imagesFooter[0].image) ? footerImages : imagesFooter[0].image;
  let image2 = (!imagesFooter.length || !imagesFooter[1].image) ? logoFundacion : imagesFooter[1].image;

  return(
    <Fragment>
      <div className="container">
        {/* <div className="row">
          <div className="col-md-12">
            <div className="footer-subscriber">
              <div className="footer-subscriber-box">
                <div className="footer-input-subscriber">
                  <span className="icon-news"/>
                  <input type="email"
                    value={email_newsletter}
                    placeholder="SUSCRÍBETE A NUESTRO NEWSLETTER"
                    onChange={(input) => props.changeEmailNewletter(input.target.value)}
                  />
                </div>
                {!newsletter_status ?
                  <button className="footer-btn-subscriber" onClick={props.sendEmailNewsletter}>
                    ENVIAR
                  </button>
                  :
                  <button className="footer-btn-subscriber">
                    ENVIANDO...
                  </button>
                }
              </div>
              {newsletter_message !== '' &&
                <div className="footer-message">{newsletter_message}</div>
              }
            </div>
          </div>
        </div> */}
      </div>
      <footer>
        <div className="footer">
          <div className="content-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-4 footer-col">
                  <div dangerouslySetInnerHTML={{__html: footer[0].value}} />
                </div>
                <div className="col-md-4 footer-col">
                  <img src={image1} alt="Logos Includere" className="footer_images"/>
                </div>
                <div className="col-md-4 footer-col">
                  <h4>{contact[0].title}</h4>
                  <div
                    className="footer_contact"
                    dangerouslySetInnerHTML={{__html: contact[0].value}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 footer-col">
                <p>Fundación Corona © 2019  - Todos los derechos reservados</p>
              </div>
              <div className="col-md-4 footer-col">
                <div className="footer-social">
                  {social_networks.map(item => {
                    if (item.link) {
                      return (
                        <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.id}>
                          <i className={item.icon}></i>
                        </a>
                      )
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className="col-md-4 footer-col">
                <img src={image2} alt="Logo Fundación Corona" className="footer_logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          Made with ❤ by <a href="https://beit.com.co/" target="_blank" rel="noopener noreferrer">Beit!</a> © Copyrigts 2020
        </div>
      </footer>
    </Fragment>
  )
}

const mapStateToProps = ({defaultReducer}) => {
  return {
    defaultReducer
  }
}

const mapDispatchToProps = {
  changeEmailNewletter, sendEmailNewsletter
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
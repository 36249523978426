import React, {Fragment} from 'react';
import './css/EcosystemDocs.css';

function EcosystemDocs(props) {
  const {data} = props;
  return (
    <Fragment>
      {data.length > 0 ?
        <div className="ecosystem-docs-content">
          {data.map((item, index) => {
            return (
              <div key={index} className="ecosystem-docs-row">
                <div className="ecosystem-docs-title-section">
                  <h5>{item.title}</h5>
                </div>
                <div className="ecosystem-docs-col">
                  {item.data.map((initiative) => {
                    return (
                      <div key={initiative.id} className="ecosystem-docs-item">
                        <div className="ecosystem-docs-info">
                          <div className="ecosystem-docs-title">{initiative.iniciativa}</div>
                          <div className="ecosystem-docs-links">{initiative.descripcion}</div>
                        </div>
                        <div className="ecosystem-docs-menu">
                          {initiative.ficha &&
                            <a href={initiative.ficha} target="_blank" rel="noopener noreferrer"
                              className="ecosystem-docs-button"
                            >
                              <span className="icon-file"/>VER FICHA
                            </a>
                          }
                          {initiative.links &&
                            <a href={initiative.links} target="_blank" rel="noopener noreferrer"
                              className="ecosystem-docs-button"
                            >
                              <span className="icon-link"/>Link
                            </a>
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
        :
        <h3>NO HAY INICIATIVAS EN ESTE MOMENTO</h3>
      }
    </Fragment>
  )
}

export default EcosystemDocs;
import {
  TOOLS_SELECTED,
  TOOLS_CHANGE_PAGE,
  TOOLS_VIEW_DOCS,
  TOOLS_VIEW_DETAIL_DOC,
  TOOLS_DATA,
  TOOLS_SEARCH,
  TOOLS_DETAIL,
  TOOLS_CLEAR,
  TOOLS_VIEW_GRAPH,
} from "../types/toolsTypes";

export const changeSelectMenu = (cod_menu) => (dispatch, getState) => {
  const { tools_menu_select, tools_items_per_page } = getState().toolsReducer;
  const { tools } = getState().defaultReducer;
  let status = tools_menu_select === cod_menu ? "" : cod_menu;
  let tools_data = {};

  if (status === "") {
    tools_data = {
      tools,
      tools_view: tools.slice(0, tools_items_per_page),
    };
  } else {
    let items = [];
    tools.filter((item) => {
      let categories = item.category.split(",");
      let in_category = categories.indexOf(cod_menu.toString());
      if (in_category >= 0) {
        items.push(item);
      }
      return null;
    });
    tools_data = {
      tools: items,
      tools_view: items.slice(0, tools_items_per_page),
    };
  }
  dispatch({
    type: TOOLS_SELECTED,
    payload: status,
  });
  dispatch({
    type: TOOLS_DATA,
    payload: tools_data,
  });
};

export const toolsChangePage = (page) => (dispatch, getState) => {
  const { tools_docs, tools_items_per_page } = getState().toolsReducer;
  const element = document.getElementById("tools-menu");
  let init = (page - 1) * tools_items_per_page;
  let end = page * tools_items_per_page;
  let view_data = tools_docs.slice(init, end);
  dispatch({
    type: TOOLS_CHANGE_PAGE,
    payload: page,
  });
  dispatch({
    type: TOOLS_VIEW_DOCS,
    payload: view_data,
  });
  element.scrollIntoView();
};

export const toolsViewDoc = (status, cod_doc) => (dispatch, getState) => {
  const { tools } = getState().defaultReducer;
  let file = [];
  if (cod_doc !== "") {
    file = tools.filter((item) => item.id === parseInt(cod_doc));
  } else {
    file = [];
  }
  dispatch({
    type: TOOLS_VIEW_DETAIL_DOC,
    payload: status,
  });
  dispatch({
    type: TOOLS_DETAIL,
    payload: file,
  });
  if (status) {
    const element = document.getElementById("doc-detail");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export const toolsSearch = (value) => (dispatch, getState) => {
  const { tools_items_per_page } = getState().toolsReducer;
  const { tools } = getState().defaultReducer;
  let tools_data = {};
  dispatch({
    type: TOOLS_SEARCH,
    payload: value,
  });

  if (value.length >= 4) {
    value = value.toLowerCase();
    let type1 = "herramienta";
    let type2 = "metodología";
    let byType =
      type1.indexOf(value) >= 0 ? 1 : type2.indexOf(value) >= 0 ? 2 : null;
    let search = [];
    tools.filter((item) => {
      let result_search = item.searchable ? item.searchable.indexOf(value) : -1;
      if (byType) {
        let result_category = item.type.indexOf(byType);
        if (result_search >= 0 || result_category >= 0) {
          search.push(item);
        }
      } else {
        if (result_search >= 0) {
          search.push(item);
        }
      }
      return null;
    });
    tools_data = {
      tools: search,
      tools_view: search.slice(0, tools_items_per_page),
    };
  } else {
    tools_data = {
      tools,
      tools_view: tools.slice(0, tools_items_per_page),
    };
  }
  dispatch({
    type: TOOLS_DATA,
    payload: tools_data,
  });
};

export const toolsClear = () => (dispatch, getState) => {
  const { tools } = getState().defaultReducer;
  const { tools_items_per_page } = getState().toolsReducer;
  let tools_data = {
    tools,
    tools_view: tools.slice(0, tools_items_per_page),
  };
  dispatch({
    type: TOOLS_CLEAR,
    payload: tools_data,
  });
};

export const toolsViewGraph = (payload) => (dispatch) => {
  dispatch({
    type: TOOLS_VIEW_GRAPH,
    payload,
  });
};

import coverpage01 from "../../images/coverpage-01.jpg";
import coverpage02 from "../../images/coverpage-02.jpg";
import coverpage03 from "../../images/coverpage-03.jpg";
import coverpage04 from "../../images/coverpage-04.jpg";

const INITIAL_STATE = {
  about_most_recent: [
    {
      id: 1,
      link: "/documentos-tecnicos",
      image: coverpage01,
      description:
        "El presente informe entrega información sobre cómo están los territorios de Colombia, en Empleo Inclusivo, a través de datos estadísticos sobre educación, formación, intermediación y empleo.",
    },
    {
      id: 2,
      link: "/documentos-tecnicos",
      image: coverpage02,
      description:
        "Instrumento de medición de habilidades socioemocionales en etapa escolar diseñado por Fudación Corona en alianza con el Banco Mundial y Fundación Luker.",
    },
    {
      id: 3,
      link: "/documentos-tecnicos",
      image: coverpage03,
      description:
        "El presente informe del estado de la Educación orientada al Empleo (EOE) en la Red de Ciudades Cómo Vamos (RCCV) tiene como objetivo principal brindar información actualizada sobre esta temática a nivel territorial y plantear análisis relevantes para la toma de decisiones de política pública.",
    },
    {
      id: 4,
      link: "/documentos-tecnicos",
      image: coverpage04,
      description:
        "El presente informe del estado de la Educación orientada al Empleo (EOE) en la Red de Ciudades Cómo Vamos (RCCV) tiene como objetivo principal brindar información actualizada sobre esta temática a nivel territorial y plantear análisis relevantes para la toma de decisiones de política pública.",
    },
  ],
};

const aboutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default aboutReducer;

import React from "react";
import loading from "../images/loading.svg";
import "./css/Loading.css";

function Loading() {
  return (
    <div className="Loading_content">
      <img src={loading} alt="Loading..." />
    </div>
  );
}

export default Loading;

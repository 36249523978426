import React from "react";
import { connect } from "react-redux";
import BoxSearch from "./BoxSearch";
import FilterSelect from "./FilterSelect";
import "./css/InitiativeFilters.css";
import {
  initiativeSearch,
  initiativeFilter,
  initiativeAddFilterActor,
  initiativeAddFilterStretch,
  initiativeAddFilterComponent,
  initiativeAddFilterCity,
  initiativeAddFilterPopulation,
  initiativeRemoveFilterActor,
  initiativeRemoveFilterStretch,
  initiativeRemoveFilterComponent,
  initiativeRemoveFilterCity,
  initiativeRemoveFilterPopulation,
} from "../functionality/actions/initiativeActions";
import FilterItem from "./FilterItem";

function InitiativeFilters(props) {
  const {
    initiativeReducer: {
      initiative_actors,
      initiative_stretches,
      initiative_components,
      initiative_populations,
      initiative_search,
      initiative_stretches_filter,
      initiative_component_filter,
      initiative_population_filter,
      initiative_cities_filter,
      initiative_actors_filter,
    },
    defaultReducer: { cities },
    initiativeSearch,
    initiativeFilter,
    initiativeAddFilterActor,
    initiativeAddFilterStretch,
    initiativeAddFilterComponent,
    initiativeAddFilterCity,
    initiativeAddFilterPopulation,
    initiativeRemoveFilterActor,
    initiativeRemoveFilterStretch,
    initiativeRemoveFilterComponent,
    initiativeRemoveFilterCity,
    initiativeRemoveFilterPopulation,
  } = props;

  const listCities = cities.filter(
    (city) => city.is_ecosystem && city.name.toLowerCase() !== "nacional"
  );
  let national = cities.filter(
    (city) => city.is_ecosystem && city.name.toLowerCase() === "nacional"
  );

  return (
    <div>
      <BoxSearch
        placeholder="BUSCADOR PALABRAS CLAVES"
        value={initiative_search}
        onChange={initiativeSearch}
        onBlur={initiativeFilter}
      />
      <div className="InitiativeFilters_filters">
        {initiative_stretches.length ? (
          <FilterSelect
            title="TRAMOS"
            options={initiative_stretches}
            value=""
            action={(type, value) => initiativeAddFilterStretch(value)}
            type="stretch"
            color="yellow"
          />
        ) : null}
        {initiative_components.length ? (
          <FilterSelect
            title="COMPONENTES"
            options={initiative_components}
            value=""
            action={(type, value) => initiativeAddFilterComponent(value)}
            type="actor"
            color="yellow"
          />
        ) : null}
        {listCities.length ? (
          <FilterSelect
            title="TERRITORIOS"
            options={listCities}
            value=""
            action={(type, value) => initiativeAddFilterCity(value)}
            type="territories"
            national_value={national.length ? national[0].id : null}
            color="yellow"
          />
        ) : null}
        {initiative_populations.length ? (
          <FilterSelect
            title="POBLACIONES"
            options={initiative_populations}
            value=""
            action={(type, value) => initiativeAddFilterPopulation(value)}
            type="population"
            color="yellow"
          />
        ) : null}
        {initiative_actors.length ? (
          <FilterSelect
            title="ACTORES"
            options={initiative_actors}
            value=""
            action={(type, value) => initiativeAddFilterActor(value)}
            type="author"
            color="yellow"
          />
        ) : null}
      </div>
      <div className="InitiativeFilters_filters-list">
        {initiative_stretches_filter.map((item, index) => (
          <FilterItem
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterStretch}
          />
        ))}
        {initiative_component_filter.map((item, index) => (
          <FilterItem
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterComponent}
          />
        ))}
        {initiative_population_filter.map((item, index) => (
          <FilterItem
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterPopulation}
          />
        ))}
        {initiative_cities_filter.map((item, index) => (
          <FilterItem
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterCity}
          />
        ))}
        {initiative_actors_filter.map((item, index) => (
          <FilterItem
            key={index}
            index={index}
            name={item.name}
            remove={initiativeRemoveFilterActor}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ initiativeReducer, defaultReducer }) => {
  return {
    initiativeReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  initiativeSearch,
  initiativeFilter,
  initiativeAddFilterActor,
  initiativeAddFilterStretch,
  initiativeAddFilterComponent,
  initiativeAddFilterCity,
  initiativeAddFilterPopulation,
  initiativeRemoveFilterActor,
  initiativeRemoveFilterStretch,
  initiativeRemoveFilterComponent,
  initiativeRemoveFilterCity,
  initiativeRemoveFilterPopulation,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitiativeFilters);

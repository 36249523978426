import React from 'react';
import BarHorizontal from './BarHorizontal';

function EcosystemSections(props) {
  const { titles } = props;

  return(
    <div className="ecosystems-sections">
      <div className="subtitle-section">{titles[17].value}</div>
      <div className="ecosystems-sections-content bg-gray-gradient">
        <BarHorizontal
          title={titles[18].value}
          number={props.ecosystem_half}
          total={props.ecosystem_total}
          background="purple3"
        />
        <BarHorizontal
          title={titles[19].value}
          number={props.ecosystem_postmedia}
          total={props.ecosystem_total}
          background="purple3"
        />
        <BarHorizontal
          title={titles[20].value}
          number={props.ecosystem_intermediation}
          total={props.ecosystem_total}
          background="purple3"
        />
        <BarHorizontal
          title={titles[21].value}
          number={props.ecosystem_job}
          total={props.ecosystem_total}
          background="purple3"
        />
      </div>
    </div>
  )
}

export default EcosystemSections;
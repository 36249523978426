import React from 'react';
import './css/ListCities.css';
import { NavLink } from 'react-router-dom';

function renderNavLink(to, item, active) {
  return (
    <NavLink to={`/${to}/ciudad/${item.cod_city}`} key={item.id}
      className={active}
      activeClassName={`territory-active ${active}`}
    >
      <div className="territory-icon">
        <span className="icon-selected"/>
      </div>
      <div className="territory-name">
        {item.name}
      </div>
    </NavLink>
  )
}

function ListCities(props) {
  let list_cities = [];
  if (props.to === "datos-territorio") {
    list_cities = props.data.filter(item => (item.is_territory === 1));
  }
  else if (props.to === "ecosistemas-iniciativas") {
    list_cities = props.data.filter(item => (item.is_ecosystem === 1));
  }
  let total_cites = list_cities.length;
  let cities_1 = [];
  let cities_2 = [];
  if (total_cites > 0) {
    let half_cities = Math.ceil(total_cites / 2);
    cities_1 = list_cities.slice(0, half_cities);
    cities_2 = list_cities.slice(half_cities, total_cites);
  }
  return(
    <div className="territory-cities">
      <div className="subtitle-section">{props.title ? props.title : 'TERRITORIO'}</div>
      <div className="territory-container bg-gray-gradient">
        <NavLink to={`/${props.to}`}
          className={`national-territory`}
          activeClassName={`national-territory ${props.active}`}
        >
          <div className="territory-icon">
            <span className="icon-selected"/>
          </div>
          <div className="territory-name">
            NACIONAL
          </div>
        </NavLink>
        <div className="territory-row">
          <div className="territory-col">
            {cities_1.map((item) => {
              return renderNavLink(props.to, item, props.active);
            })}
          </div>
          <div className="territory-col">
            {cities_2.map((item) => {
              return renderNavLink(props.to, item, props.active);
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListCities;
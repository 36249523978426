import React from "react";
import { connect } from "react-redux";
import {
  userEmail,
  userFormType,
  userForgot,
} from "../../functionality/actions/userActions";

function Forgot(props) {
  const {
    userReducer: { user_email },
    userEmail,
    userFormType,
    userForgot,
  } = props;
  return (
    <div>
      <h1>RECUPERAR CONTRASEÑA</h1>
      <div className="form-group">
        <label>EMAIL:</label>
        <input
          type="email"
          className="form-control"
          value={user_email}
          onChange={(input) => userEmail(input.target.value)}
        />
      </div>
      <button className="Forms_button" onClick={userForgot}>
        RECUPERAR
      </button>
      <div
        className="Form_action text-center"
        onClick={() => userFormType("login")}
      >
        Iniciar Sesión
      </div>
    </div>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  userEmail,
  userFormType,
  userForgot,
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);

import React from "react";
import "./css/BarHorizontal.css";

function BarHorizontal(props) {
  let percentage = 0;
  if (props.number > 0) {
    percentage = (100 * props.number) / props.total;
  }
  return (
    <div className="bar-horizontal-content">
      <div className="bar-horizontal-title">{props.title}</div>
      <div className="bar-horizontal-container">
        <div className={`bar-horizontal-bar-content ${props.background}`}>
          <div className={`bar-horizontal-circle1`}>
            <div className={`bar-horizontal-circle2`} />
          </div>
          <div className="bar-horizontal-progress">
            <div
              className="bar-horizontal-progressbar"
              style={{ width: `${percentage}%` }}
            />
            <div className="bar-horizontal-line" />
          </div>
        </div>
        <div className="bar-horizontal-number">{props.number}</div>
      </div>
    </div>
  );
}

export default BarHorizontal;

import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import BoxSearch from "../components/BoxSearch";
import MoreWhite from "../components/MoreWhite";
import MostRecent from "../components/MostRecent";
import "./css/IndexPage.css";
import * as indexActions from "../functionality/actions/indexActions";
import SectionSlider from "../components/SectionSlider";

const { changeSearch, clearSearch, changeMenu } = indexActions;
class IndexPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { clearSearch, changeMenu } = this.props;
    changeMenu("");
    clearSearch();
  }

  render() {
    const { changeSearch } = this.props;
    const { search, other_contents, texts, sliders } =
      this.props.defaultReducer;
    let territory = other_contents.filter(
      (item) => item.label === "territorio_home"
    );
    let tool = other_contents.filter(
      (item) => item.label === "herramientas_metodologias_home"
    );
    let ecosystem = other_contents.filter(
      (item) => item.label === "ecosistemas_iniciativas_home"
    );
    let document = other_contents.filter(
      (item) => item.label === "documentos_tecnicos_home"
    );
    let how_navigate = texts.filter(
      (item) => item.label.indexOf("como_navegar") >= 0
    );
    let search_visible = texts.filter((item) => item.label === "ver_buscador");
    let slider = sliders.filter((item) => item.name === "home");

    return (
      <LayoutDefault>
        {slider.length &&
          slider[0].is_active === 1 &&
          slider[0].slides.length > 0 && (
            <SectionSlider slides={slider[0].slides} />
          )}
        {search_visible.length &&
          search_visible[0].value &&
          search_visible[0].value.toLowerCase() === "si" && (
            <BoxSearch
              type="menu"
              placeholder="BUSCADOR"
              onChange={(value) => changeSearch(value)}
              value={search}
              action={true}
            />
          )}
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center title-home">
                  {how_navigate[0].value}
                </h2>
              </div>
              <div className="col-md-4">
                <div className="index-description">
                  <div className="index-circle-description">
                    <span className="icon-search" />
                  </div>
                  <div className="index-text-description">
                    <div className="index-title-description">1.</div>
                    <div>{how_navigate[1].value}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="index-description">
                  <div className="index-circle-description">
                    <span className="icon-information" />
                  </div>
                  <div className="index-text-description">
                    <div className="index-title-description">2.</div>
                    <div>{how_navigate[2].value}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="index-description">
                  <div className="index-circle-description">
                    <span className="icon-hand" />
                  </div>
                  <div className="index-text-description">
                    <div className="index-title-description">3.</div>
                    <div>{how_navigate[3].value}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="index-boxes-container">
              <div className="index-box-container index-box-territory">
                <div className="index-box-line bg-purple" />
                <div className="index-box-content">
                  <div className="index-box-data">
                    <div
                      className="index-box-title"
                      dangerouslySetInnerHTML={{
                        __html: territory[0].title.replace(" ", "<br>"),
                      }}
                    />
                    <span className="icon-map" />
                    <div
                      className="index-box-text"
                      dangerouslySetInnerHTML={{ __html: territory[0].value }}
                    />
                  </div>
                  <MoreWhite to="/territorio" />
                </div>
              </div>
              <div className="index-box-container index-box-ecosystem">
                <div className="index-box-line bg-yellow" />
                <div className="index-box-content">
                  <div className="index-box-data">
                    <div className="index-box-title">{ecosystem[0].title}</div>
                    <span className="icon-ecosystem" />
                    <div
                      className="index-box-text"
                      dangerouslySetInnerHTML={{ __html: ecosystem[0].value }}
                    />
                  </div>
                  <MoreWhite to="/ecosistemas-iniciativas" />
                </div>
              </div>
              <div className="index-box-container index-box-documents">
                <div className="index-box-line bg-green" />
                <div className="index-box-content">
                  <div className="index-box-data">
                    <div
                      className="index-box-title"
                      dangerouslySetInnerHTML={{
                        __html: document[0].title.replace(" ", "<br>"),
                      }}
                    />
                    <span className="icon-documents" />
                    <div
                      className="index-box-text"
                      dangerouslySetInnerHTML={{ __html: document[0].value }}
                    />
                  </div>
                  <MoreWhite to="/documentos-tecnicos" />
                </div>
              </div>
              <div className="index-box-container index-box-tools">
                <div className="index-box-line bg-blue" />
                <div className="index-box-content">
                  <div className="index-box-data">
                    <div className="index-box-title">{tool[0].title}</div>
                    <span className="icon-tools" />
                    <div
                      className="index-box-text"
                      dangerouslySetInnerHTML={{ __html: tool[0].value }}
                    />
                  </div>
                  <MoreWhite to="/covid-19" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <MostRecent title="LO MÁS RECIENTE" label="quienes_somos" />
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ indexReducer, defaultReducer }) => {
  return {
    indexReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  changeSearch,
  clearSearch,
  changeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);

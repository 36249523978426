import React from "react";
import "./css/ButtonGraph.css";

function ButtonGraph(props) {
  const { action, name, background, color, status } = props;

  return (
    <button
      onClick={action}
      className="ButtonGraph"
      style={{
        background,
        color,
      }}
    >
      {name}
      {status ? (
        <i className="fas fa-chevron-up" />
      ) : (
        <i className="fas fa-chevron-down" />
      )}
    </button>
  );
}

ButtonGraph.defaultProps = {
  background: "#D77561",
  color: "#ffffff",
};

export default ButtonGraph;

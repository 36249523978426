import {
  CONTACT_NAME,
  CONTACT_INSTITUTION,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CONTACT_MESSAGE,
  CONTACT_ERROR,
  CONTACT_CLEAR,
  CONTACT_STATUS,
  CONTACT_SUCCESS,
} from "../types/contactTypes";
import Api from "../Api";

export const contactName = (value) => (dispatch) => {
  dispatch({
    type: CONTACT_NAME,
    payload: value,
  });
};

export const contactInstitution = (value) => (dispatch) => {
  dispatch({
    type: CONTACT_INSTITUTION,
    payload: value,
  });
};

export const contactEmail = (value) => (dispatch) => {
  dispatch({
    type: CONTACT_EMAIL,
    payload: value,
  });
};

export const contactPhone = (value) => (dispatch) => {
  dispatch({
    type: CONTACT_PHONE,
    payload: value,
  });
};

export const contactMessage = (value) => (dispatch) => {
  dispatch({
    type: CONTACT_MESSAGE,
    payload: value,
  });
};

export const contactClear = () => (dispatch) => {
  dispatch({
    type: CONTACT_CLEAR,
  });
};

export const contactSend = () => (dispatch, getState) => {
  const {
    contact_name,
    contact_institution,
    contact_email,
    contact_phone,
    contact_message,
  } = getState().contactReducer;
  if (
    contact_name === "" ||
    contact_institution === "" ||
    contact_email === "" ||
    contact_phone === "" ||
    contact_message === ""
  ) {
    dispatch({
      type: CONTACT_ERROR,
      payload: "Todos los campos son obligatorios.",
    });
    return;
  }
  dispatch({
    type: CONTACT_ERROR,
    payload: "",
  });
  dispatch({
    type: CONTACT_STATUS,
    payload: true,
  });
  let data = {
    name: contact_name,
    institution: contact_institution,
    email: contact_email,
    phone: contact_phone,
    message: contact_message,
  };
  Api.callMethod("contact", "POST", data, function (res) {
    if (res.error.code === 0) {
      dispatch({
        type: CONTACT_SUCCESS,
        payload:
          "El mesaje fue enviado con éxito, pronto nos pondremos en contacto.",
      });
      window.scrollTo(0, 0);
    } else {
      dispatch({
        type: CONTACT_ERROR,
        payload: "Lo sentimos, hubo un error; vuelve a intentarlo más tarde.",
      });
    }
  });
};

import React from 'react';
import { useHistory } from "react-router-dom";
import './css/Button.css';
import Tooltip from './Tooltip';

function ButtonPurple(props) {
  const { goTo } = props;
  let history = useHistory();

  return(
    <button
      onClick={() => history.push(`/territorio/grafico/${goTo}`)}
      className="button button-purple"
    >
      <span className="icon-more"/> VER MÁS
      {props.tooltip &&
        <Tooltip text={props.tooltip} />
      }
    </button>
  )
}
export default ButtonPurple;
import {
  ECOSYSTEM_CLEAR,
  ECOSYSTEM_HARSH,
  ECOSYSTEM_SOFT,
  ECOSYSTEM_OCUPATIONAL,
  ECOSYSTEM_SPECIFIC,
  ECOSYSTEM_GENERAL,
  ECOSYSTEM_VOCATIONAL,
  ECOSYSTEM_INTERMEDIATION_LABOR,
  ECOSYSTEM_INTERMEDIATION_TALENT,
  ECOSYSTEM_ORIENTATION_LABOR,
  ECOSYSTEM_ORIENTATION_TALENT,
  ECOSYSTEM_SECTIONS,
  ECOSYSTEM_POPULATION,
  ECOSYSTEM_MODELS,
  ECOSYSTEM_DOCS,
  ECOSYSTEM_MODAL,
  ECOSYSTEM_VIEW_GRAPH,
} from "../types/ecosystemsTypes";
let backgroundDoughnut = ["#FCD116", "#614784"];

const INITIAL_STATE = {
  chart_1: {
    labels: ["Demo1", "Demo2"],
    datasets: [
      {
        data: [40, 60],
        backgroundColor: backgroundDoughnut,
        hoverBackgroundColor: backgroundDoughnut,
      },
    ],
  },
  ecosystem_harsh: 0,
  ecosystem_soft: 0,
  ecosystem_ocupational: 0,
  ecosystem_specific: 0,
  ecosystem_general: 0,
  ecosystem_vocational: 0,
  ecosystem_intermediation_labor: 0,
  ecosystem_intermediation_talent: 0,
  ecosystem_orientation_labor: 0,
  ecosystem_orientation_talent: 0,
  ecosystem_total: 0,
  ecosystem_women: 0,
  ecosystem_young_boys: 0,
  ecosystem_disabality: 0,
  ecosystem_indigenios: 0,
  ecosystem_afro_descendant: 0,
  ecosystem_conflict: 0,
  ecosystem_reinstatement: 0,
  ecosystem_poverty: 0,
  ecosystem_lgbtiq: 0,
  ecosystem_half: 0,
  ecosystem_postmedia: 0,
  ecosystem_intermediation: 0,
  ecosystem_job: 0,
  ecosystem_view_docs: false,
  ecosystes_doc_title: "",
  ecosystem_docs: [],
  ecosystem_modal: "",
  ecosystem_view_graph: true,
};

const ecosystemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ECOSYSTEM_CLEAR:
      return {
        ...state,
        ecosystem_harsh: 0,
        ecosystem_soft: 0,
        ecosystem_ocupational: 0,
        ecosystem_specific: 0,
        ecosystem_general: 0,
        ecosystem_vocational: 0,
        ecosystem_intermediation_labor: 0,
        ecosystem_intermediation_talent: 0,
        ecosystem_orientation_labor: 0,
        ecosystem_orientation_talent: 0,
        ecosystem_total: 0,
        ecosystem_women: 0,
        ecosystem_young_boys: 0,
        ecosystem_disabality: 0,
        ecosystem_indigenios: 0,
        ecosystem_afro_descendant: 0,
        ecosystem_conflict: 0,
        ecosystem_reinstatement: 0,
        ecosystem_poverty: 0,
        ecosystem_half: 0,
        ecosystem_postmedia: 0,
        ecosystem_intermediation: 0,
        ecosystem_job: 0,
        ecosystem_view_docs: false,
        ecosystes_doc_title: "",
        ecosystem_docs: [],
      };
    case ECOSYSTEM_HARSH:
      return {
        ...state,
        ecosystem_harsh: action.payload,
      };
    case ECOSYSTEM_SOFT:
      return {
        ...state,
        ecosystem_soft: action.payload,
      };
    case ECOSYSTEM_OCUPATIONAL:
      return {
        ...state,
        ecosystem_ocupational: action.payload,
      };
    case ECOSYSTEM_SPECIFIC:
      return {
        ...state,
        ecosystem_specific: action.payload,
      };
    case ECOSYSTEM_GENERAL:
      return {
        ...state,
        ecosystem_general: action.payload,
      };
    case ECOSYSTEM_VOCATIONAL:
      return {
        ...state,
        ecosystem_vocational: action.payload,
      };
    case ECOSYSTEM_INTERMEDIATION_LABOR:
      return {
        ...state,
        ecosystem_intermediation_labor: action.payload,
      };
    case ECOSYSTEM_INTERMEDIATION_TALENT:
      return {
        ...state,
        ecosystem_intermediation_talent: action.payload,
      };
    case ECOSYSTEM_ORIENTATION_LABOR:
      return {
        ...state,
        ecosystem_orientation_labor: action.payload,
      };
    case ECOSYSTEM_ORIENTATION_TALENT:
      return {
        ...state,
        ecosystem_orientatio_talent: action.payload,
      };
    case ECOSYSTEM_SECTIONS:
      return {
        ...state,
        ecosystem_harsh: action.payload.ecosystem_harsh,
        ecosystem_soft: action.payload.ecosystem_soft,
        ecosystem_ocupational: action.payload.ecosystem_ocupational,
        ecosystem_specific: action.payload.ecosystem_specific,
        ecosystem_general: action.payload.ecosystem_general,
        ecosystem_vocational: action.payload.ecosystem_vocational,
        ecosystem_intermediation_labor:
          action.payload.ecosystem_intermediation_labor,
        ecosystem_intermediation_talent:
          action.payload.ecosystem_intermediation_talent,
        ecosystem_orientation_labor: action.payload.ecosystem_orientation_labor,
        ecosystem_orientation_talent:
          action.payload.ecosystem_orientation_talent,
        ecosystem_total: action.payload.ecosystem_total,
        ecosystem_view_docs: false,
        ecosystes_doc_title: "",
        ecosystem_docs: [],
      };
    case ECOSYSTEM_POPULATION:
      return {
        ...state,
        ecosystem_women: action.payload.ecosystem_women,
        ecosystem_young_boys: action.payload.ecosystem_young_boys,
        ecosystem_disabality: action.payload.ecosystem_disabality,
        ecosystem_indigenios: action.payload.ecosystem_indigenios,
        ecosystem_afro_descendant: action.payload.ecosystem_afro_descendant,
        ecosystem_conflict: action.payload.ecosystem_conflict,
        ecosystem_reinstatement: action.payload.ecosystem_reinstatement,
        ecosystem_poverty: action.payload.ecosystem_poverty,
        ecosystem_lgbtiq: action.payload.ecosystem_lgbtiq,
      };
    case ECOSYSTEM_MODELS:
      return {
        ...state,
        ecosystem_half: action.payload.ecosystem_half,
        ecosystem_postmedia: action.payload.ecosystem_postmedia,
        ecosystem_intermediation: action.payload.ecosystem_intermediation,
        ecosystem_job: action.payload.ecosystem_job,
      };
    case ECOSYSTEM_DOCS:
      return {
        ...state,
        ecosystem_view_docs: true,
        ecosystes_doc_title: action.payload.title,
        ecosystem_docs: action.payload.docs,
      };
    case ECOSYSTEM_MODAL:
      return {
        ...state,
        ecosystem_modal: action.payload,
      };
    case ECOSYSTEM_VIEW_GRAPH:
      return {
        ...state,
        ecosystem_view_graph: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ecosystemReducer;

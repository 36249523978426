import React from 'react';
import './css/Tooltip.css';

function Tooltip(props) {
  return (
    <div className="tooltip_container">
      <div className="tooltip_arrow"/>
      <div className="tooltip_content">
        {props.text}
      </div>
    </div>
  )
}

export default Tooltip;

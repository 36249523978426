import React from "react";
import Item from "./Item";
import { connect } from "react-redux";

function List(props) {
  const {
    defaultReducer: { ecosystem_titles },
    initiatives,
  } = props;
  let buttonName = ecosystem_titles.find(
    (item) => item.label === "ecosistema_detalle_boton"
  );
  buttonName = buttonName ? buttonName.value : "Ver detalle";

  return (
    <div className="row">
      {initiatives.map((initiative) => (
        <Item
          initiative={initiative}
          key={initiative.id}
          buttonName={buttonName}
        />
      ))}
    </div>
  );
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default connect(mapStateToProps, null)(List);

import { LOADING_INITIAL } from "../types/loadingReducer";

const INITIAL_STATE = {
  loading_initial: "visible",
};

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_INITIAL:
      return {
        ...state,
        loading_initial: action.payload,
      };
    default:
      return { ...state };
  }
};

export default loadingReducer;

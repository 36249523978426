import React, { Component } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

class NewsSection extends Component {
  renderItem = (item) => {
    const { route } = this.props;
    return (
      <div className="doc-item-content" key={item.id}>
        <div className="news_item">
          <div className="doc-item-box">
            <div className="doc-item-coverpage">
              {item.coverpage && item.coverpage !== '' &&
                <img src={item.coverpage} alt="coverpage"/>
              }
            </div>
            <div className="doc-item-description">
              {item.title}
            </div>
            {item.target === 'here' &&
              <Link className="doc-item-button" to={`/${route}/${item.id}`}>
                VER MÁS
              </Link>
            }
            {item.target === 'link' &&
              <Link className="doc-item-button" to={item.link}>
                VER MÁS
              </Link>
            }
            {item.target === '_blank' &&
              <a className="doc-item-button" href={item.link} target="_blank" rel="noopener noreferrer">
                VER MÁS
              </a>
            }
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {articles} = this.props;
    const settings = {
			dots: false,
			arrows: true,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 50000,
			speed: 500,
			slidesToShow: 6,
			slidesToScroll: 6,
			responsive: [
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 813,
					settings: {
					slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		};
    return(
      <div>
        {articles.length > 6 ?
          <div>
            <Slider {...settings} ref={c => (this.slider = c)}>
              {articles.map(item => this.renderItem(item))}
            </Slider>
          </div>
          :
          <div className="new_row">
            {articles.map(item => this.renderItem(item))}
          </div>
        }
      </div>
    )
  }
}

export default NewsSection;
import React from 'react';
// import {Doughnut} from 'react-chartjs-2';
import ButtonYellow from './ButtonYellow';
import './css/EcosystemPopulation.css';
import BarHorizontal from './BarHorizontal';

function EcosystemPopulation(props) {
  const {cod_city, action, titles} = props;
  let city = (cod_city) ? cod_city : null;

  return(
    <div className="ecosystem-population-content">
      <div className="ecosystem-population-bars">
        <div className="ecosystem-population-title">
          <div className="subtitle-section">{titles[23].value}</div>
          <ButtonYellow action={() => action('population', titles[23].value, city)}
            tooltip={titles[37].value}
          />
        </div>
        <BarHorizontal
          title={titles[24].value}
          number={props.ecosystem_women}
          total={props.ecosystem_total}
          background="purple1"
        />
        <BarHorizontal
          title={titles[25].value}
          number={props.ecosystem_young_boys}
          total={props.ecosystem_total}
          background="purple2"
        />
        <BarHorizontal
          title={titles[26].value}
          number={props.ecosystem_disabality}
          total={props.ecosystem_total}
          background="purple3"
        />
        <BarHorizontal
          title={titles[27].value}
          number={props.ecosystem_indigenios}
          total={props.ecosystem_total}
          background="purple4"
        />
        <BarHorizontal
          title={titles[28].value}
          number={props.ecosystem_afro_descendant}
          total={props.ecosystem_total}
          background="purple5"
        />
        <BarHorizontal
          title={titles[29].value}
          number={props.ecosystem_conflict}
          total={props.ecosystem_total}
          background="purple6"
        />
        <BarHorizontal
          title={titles[30].value}
          number={props.ecosystem_reinstatement}
          total={props.ecosystem_total}
          background="purple7"
        />
        <BarHorizontal
          title={titles[31].value}
          number={props.ecosystem_poverty}
          total={props.ecosystem_total}
          background="purple8"
        />
        <BarHorizontal
          title={titles[44].value}
          number={props.ecosystem_lgbtiq}
          total={props.ecosystem_total}
          background="purple8"
        />
      </div>
      {/* <div className="ecosystem-population-chart">
        <Doughnut data={props.doughnut}/>
      </div> */}
    </div>
  )
}

export default EcosystemPopulation;
import React from 'react';
import './css/ToolsMenu.css';

function ToolsMenu(props) {
  return(
    <div className="tools-menu-content" id="tools-menu">
      {props.data.map((item, index) => {
        let is_active = (item.value === props.status) ? 'active' : "";
        return (
          <div key={item.id} className="tools-menu-item">
            <button
              className={`tools-menu-button-${index+1} ${is_active}`}
              onClick={() => props.selectMenu(item.value)}
            >
              {item.name}
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default ToolsMenu;
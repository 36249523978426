import React from "react";
import { connect } from "react-redux";
import { getTitle } from "../../functionality/actions/currentActions";
import { territoryTooltip } from "../../functionality/actions/territoryActions";
import "./TerritoryTooltip.css";

function TerritoryTooltip(props) {
  const {
    defaultReducer: { territory_titles },
    territoryReducer: { territory_tooltip },
    territoryTooltip,
  } = props;

  const message = getTitle(territory_titles, "territorio_tooltip_text");
  const link = getTitle(territory_titles, "territorio_tooltip_link");
  const visible = territory_tooltip ? "visible" : "";

  if (!message || !link) {
    return null;
  }

  return (
    <div className="TerritoryTooltip">
      <div className={`TerritoryTooltip-container ${visible}`}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {message}
        </a>
      </div>
      <button
        onClick={() => territoryTooltip(!territory_tooltip)}
        className={`TerritoryTooltip-button ${visible}`}
      >
        {territory_tooltip ? (
          <i className="fas fa-times" />
        ) : (
          <i className="far fa-file-alt" />
        )}
      </button>
    </div>
  );
}

const mapStateToProps = ({ territoryReducer, defaultReducer }) => {
  return {
    territoryReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  territoryTooltip,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryTooltip);

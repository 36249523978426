import React from "react";
import { connect } from "react-redux";
import { getTitle } from "../functionality/actions/currentActions";
import BarHorizontal from "./BarHorizontal";
import "./css/InitiativeCount.css";

function InitiativeCount(props) {
  const {
    defaultReducer: { ecosystem_titles },
    initiativeReducer: {
      initiative_list,
      initiative_p1,
      initiative_p2,
      initiative_p3,
      initiative_p4,
      initiative_p5,
      initiative_p6,
      initiative_p7,
      initiative_p8,
      initiative_p9,
    },
  } = props;

  let total = initiative_list.length;

  return (
    <div>
      <h5 className="subtitle-section">
        {getTitle(ecosystem_titles, "ecosistema_txt_18")}
      </h5>
      <div className="InitiativeCount_container bg-gray-gradient">
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_25")}
          number={initiative_p1}
          total={total}
          background="purple1"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_26")}
          number={initiative_p2}
          total={total}
          background="purple2"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_27")}
          number={initiative_p3}
          total={total}
          background="purple3"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_28")}
          number={initiative_p4}
          total={total}
          background="purple4"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_29")}
          number={initiative_p5}
          total={total}
          background="purple5"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_30")}
          number={initiative_p6}
          total={total}
          background="purple6"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_31")}
          number={initiative_p7}
          total={total}
          background="purple7"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_32")}
          number={initiative_p8}
          total={total}
          background="purple8"
        />
        <BarHorizontal
          title={getTitle(ecosystem_titles, "ecosistema_txt_35")}
          number={initiative_p9}
          total={total}
          background="purple8"
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ defaultReducer, initiativeReducer }) => {
  return {
    defaultReducer,
    initiativeReducer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InitiativeCount);

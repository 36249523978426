import React, { Component } from 'react'
import LayoutDefault from './layouts/LayoutDefault'
import Error404 from '../components/Error404';

class ErrorPage extends Component {
  render() {
    return(
      <LayoutDefault>
        <Error404 />
      </LayoutDefault>
    )
  }
}

export default ErrorPage;
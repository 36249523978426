import React from 'react';
import './css/TooltipIndicator.css';

function TooltipIndicator(props) {
  let { title, content, colorTitle } = props;
  colorTitle = (colorTitle) ? colorTitle : '#5F3C7A';
  return (
    <div className="tooltipIndicator_container">
      <div className="tooltipIndicator_content">
        {title &&
          <h4 className="tooltipIndicator_title" style={{color: colorTitle}}>
            {title}
          </h4>
        }
        <div
          className="tooltipIndicator_description"
          dangerouslySetInnerHTML={{__html: content.replace("\n","<br/>")}}
        />
      </div>
      <div className="tooltipIndicator_angle"/>
    </div>
  )
}

export default TooltipIndicator;
import React from "react";
import DocItem from "./DocItem";

function DocsList(props) {
  let { link, data, listType, changeType } = props;
  link = link ? link : "/documentos-tecnicos";
  const listActive = listType === "list" ? "active" : "";
  const iconActive = listType === "icon" ? "active" : "";

  return (
    <>
      {changeType && (
        <div className="docs-menu-content">
          <div className="docs-menu-content-title">
            Selecciona el tipo de visualización:
          </div>
          <div className="docs-menu-content-buttons">
            <button
              onClick={() => changeType("icon")}
              title="Iconos"
              className={iconActive}
            >
              <i className="fas fa-th" />
            </button>
            <button
              onClick={() => changeType("list")}
              title="Lista"
              className={listActive}
            >
              <i className="fas fa-list" />
            </button>
          </div>
        </div>
      )}
      <div className="docs-list-content">
        {data.map((item) => {
          return (
            <DocItem
              key={item.id}
              data={item}
              link={link}
              listType={listType}
            />
          );
        })}
      </div>
    </>
  );
}

export default DocsList;

import { LOADING_INITIAL } from "../types/loadingReducer";
import {
  DEFAULT_CONTENTS,
  DEFAULT_EMAIL_NEWSLETTER,
  DEFAULT_NEWSLETTER_MESSAGE,
  DEFAULT_NEWSLETTER_STATUS,
  DEFAULT_SEARCH,
  DEFAULT_SEARCH_CLEAR,
  DEFAULT_SEARCH_STATUS,
  DEFAULT_SEARCH_DATA,
  DEFAULT_MENU,
} from "../types/defaultTypes";
import { TOOLS_DATA } from "../types/toolsTypes";
import { DOCS_DATA } from "../types/docsTypes";
import Api from "../Api";

export const initialSite = () => (dispatch, getState) => {
  const { tools_items_per_page } = getState().toolsReducer;
  const { docs_items_per_page } = getState().docsReducer;
  document.body.style.overflow = "hidden";
  Api.callMethod("initial-site", "GET", null, function (res) {
    if (res.error.code === 0) {
      const {
        data,
        data: { tools, documents },
      } = res;
      let data_tools = {
        tools,
        tools_view: tools.slice(0, tools_items_per_page),
      };
      let data_docs = {
        docs: documents,
        docs_view: documents.slice(0, docs_items_per_page),
      };
      setTimeout(function () {
        document.body.style.overflow = "";
        dispatch({
          type: LOADING_INITIAL,
          payload: "hidden",
        });
      }, 2500);
      dispatch({
        type: DEFAULT_CONTENTS,
        payload: data,
      });
      dispatch({
        type: TOOLS_DATA,
        payload: data_tools,
      });
      dispatch({
        type: DOCS_DATA,
        payload: data_docs,
      });
    } else {
      window.location.reload();
    }
  });
};

export const changeEmailNewletter = (value) => (dispatch) => {
  dispatch({
    type: DEFAULT_EMAIL_NEWSLETTER,
    payload: value,
  });
};

export const sendEmailNewsletter = () => (dispatch, getState) => {
  const { email_newsletter } = getState().defaultReducer;
  let emailExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  dispatch({
    type: DEFAULT_NEWSLETTER_MESSAGE,
    payload: "",
  });
  if (email_newsletter.length === 0) {
    dispatch({
      type: DEFAULT_NEWSLETTER_MESSAGE,
      payload: "Debes escribir un email.",
    });
    return null;
  }
  if (!emailExp.test(email_newsletter)) {
    dispatch({
      type: DEFAULT_NEWSLETTER_MESSAGE,
      payload: "Valida que el email este bien escrito.",
    });
    return null;
  }
  let data = {
    email: email_newsletter,
  };
  dispatch({
    type: DEFAULT_NEWSLETTER_STATUS,
    payload: true,
  });
  Api.callMethod("newsletter-inscribe", "POST", data, function (res) {
    if (res.error.code === 0) {
      dispatch({
        type: DEFAULT_NEWSLETTER_MESSAGE,
        payload: "El email ha sido registrado con éxito.",
      });
    } else {
      dispatch({
        type: DEFAULT_NEWSLETTER_MESSAGE,
        payload: "Hubo un error, vuelve a intentarlo más tarde.",
      });
    }
    dispatch({
      type: DEFAULT_NEWSLETTER_STATUS,
      payload: false,
    });
  });
};

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: DEFAULT_SEARCH_CLEAR,
  });
};

export const changeSearch = (value) => (dispatch) => {
  dispatch({
    type: DEFAULT_SEARCH,
    payload: value,
  });
};

export const toSearch = () => (dispatch, getState) => {
  const { tools, documents, ecosystems, search } = getState().defaultReducer;
  dispatch({
    type: DEFAULT_SEARCH_STATUS,
    payload: true,
  });
  let results = [];
  let result_tools = [];
  tools.filter((item) => {
    let value = search.toLowerCase();
    let result_title = item.title.toLowerCase().indexOf(value);
    let result_description = item.description.toLowerCase().indexOf(value);
    if (result_title >= 0 || result_description >= 0) {
      result_tools.push(item);
    }
    return null;
  });
  let result_documents = [];
  documents.filter((item) => {
    let value = search.toLowerCase();
    let result_title = item.title.toLowerCase().indexOf(value);
    let result_description = item.description.toLowerCase().indexOf(value);
    if (result_title >= 0 || result_description >= 0) {
      result_documents.push(item);
    }
    return null;
  });
  let result_ecosystems = [];
  ecosystems.filter((item) => {
    let value = search.toLowerCase();
    let result_iniciativa = item.iniciativa.toLowerCase().indexOf(value);
    if (result_iniciativa >= 0) {
      result_ecosystems.push(item);
    }
    return null;
  });
  if (result_tools.length > 0) {
    results.push({
      title: "HERRAMIENTAS Y TECONOLOGÍA",
      data: result_tools,
      type: 1,
    });
  }
  if (result_documents.length > 0) {
    results.push({
      title: "DOCUMENTOS TÉCNICOS",
      data: result_documents,
      type: 2,
    });
  }
  if (result_ecosystems.length > 0) {
    results.push({
      title: "ECOSISTEMAS DE INICIATIVAS",
      data: result_ecosystems,
      type: 3,
    });
  }
  setTimeout(function () {
    dispatch({
      type: DEFAULT_SEARCH_DATA,
      payload: results,
    });
  }, 2500);
};

export const changeMenu = (status) => (dispatch) => {
  dispatch({
    type: DEFAULT_MENU,
    payload: status,
  });
};

import React from "react";
import ReactDOM from "react-dom";
import Forgot from "./Forgot";
import Login from "./Login";
import Profile from "./Profile";
import Register from "./Register";
import "./Forms.css";
import { connect } from "react-redux";
import { userFormModal } from "../../functionality/actions/userActions";
import loading from "../../images/loading.svg";

function Forms(props) {
  const {
    userReducer: {
      user_form_modal,
      user_form_type,
      user_loading,
      user_loading_message,
    },
    userFormModal,
  } = props;
  return ReactDOM.createPortal(
    <div className={`Forms_modal ${user_form_modal}`}>
      {user_loading && (
        <div className="Forms_loading">
          <img src={loading} alt="Loading..." />
          <p>{user_loading_message}</p>
        </div>
      )}
      <div className="Forms_container">
        <button className="Forms_close" onClick={() => userFormModal("")}>
          <i className="far fa-times-circle" />
        </button>
        {user_form_type === "register" && <Register />}
        {user_form_type === "login" && <Login />}
        {user_form_type === "forgot" && <Forgot />}
        {user_form_type === "profile" && <Profile />}
      </div>
    </div>,
    document.getElementById("forms")
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  userFormModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Forms);

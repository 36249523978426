import React from "react";
import { connect } from "react-redux";
import InitiativeIcon from "./InitiativeIcon";
import "./css/InitiativeList.css";
import InitiativeButtonExcel from "./InitiativeButtonExcel";
import List from "./Initiative/List";
import Winners from "./Initiative/Winners";

function InitiativeList(props) {
  const {
    initiativeReducer: { initiative_view, initiative_winners },
  } = props;

  return (
    <div className="InitiativeList_content">
      <InitiativeButtonExcel />
      {initiative_winners.length ? <Winners /> : null}
      <List initiatives={initiative_view} />
    </div>
  );
}

const mapStateToProps = ({ initiativeReducer }) => {
  return {
    initiativeReducer,
  };
};

export default connect(mapStateToProps, null)(InitiativeList);

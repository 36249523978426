import { API_ENDPOINT } from "../config/config";

class Api {
  callMethod(path, method, data = {}, callback, error) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    const cookies = document.cookie.split(";");
    let token = cookies.filter((item) => item.indexOf("includere_token=") >= 0);
    if (token.length) {
      token = token[0].split("=");
      headers.Authorization = `Bearer ${token[1]}`;
    }

    let params = {
      mode: "cors",
      method,
      headers,
    };

    if (method.toLowerCase() !== "get") {
      params.body = JSON.stringify(data);
    }

    const url = API_ENDPOINT + path;

    fetch(url, params)
      .then(function (res) {
        res.json().then(function (response) {
          callback(response);
        });
      })
      .catch(function (err) {
        if (typeof error === "function") {
          error(err);
        } else {
          console.log(err);
        }
      });
  }
}

export default new Api();

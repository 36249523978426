export const USER_DATA = "USER_DATA";
export const USER_NAME = "USER_NAME";
export const USER_EMAIL = "USER_EMAIL";
export const USER_PASSWORD = "USER_PASSWORD";
export const USER_ENTITY = "USER_ENTITY";
export const USER_PHONE = "USER_PHONE";
export const USER_FORM_TYPE = "USER_FORM_TYPE";
export const USER_FORM_MODAL = "USER_FORM_MODAL";
export const USER_FORM_CLEAR = "USER_FORM_CLEAR";
export const USER_LOADING = "USER_LOADING";
export const USER_TERMS = "USER_TERMS";

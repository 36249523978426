import React from 'react';
import './css/Pagination.css';

function renderPages(total_pages, current_page, change_page) {
  let html = [];
  let initPage = (current_page > 2) ? (current_page - 2) : 1;
  let maxPage = (current_page < (total_pages - 2)) ? (current_page + 2) : total_pages;

  for (let i = initPage; i <= maxPage; i++) {
    let isActive = (current_page === i) ? 'active' : '';
    let button = <button key={i} className={isActive} onClick={() => change_page(i)}>{i}</button>;
    html.push(button);
  }

  return html;
}

function Pagination(props) {
  const {current_page, all_data, items_per_page, change_page} = props;
  let total_items = all_data.length;
  let total_pages = Math.ceil(total_items / items_per_page);
  return (
    <div className="pagination-content">
      {current_page > 1 &&
        <button
          className="pagination-arrow" onClick={() => change_page(current_page - 1)}
        >
          <span className="icon-arrow-left"/>
        </button>
      }
      {current_page > 3 &&
        <button onClick={() => change_page(1)}>1</button>
      }
      {current_page > 4 &&
        <button>...</button>
      }
      {renderPages(total_pages, current_page, change_page)}
      {current_page < (total_pages - 3) &&
        <button>...</button>
      }
      {current_page < (total_pages - 2) &&
        <button onClick={() => change_page(total_pages)}>{total_pages}</button>
      }
      {current_page < total_pages &&
        <button
          className="pagination-arrow" onClick={() => change_page(current_page + 1)}
        >
          <span className="icon-arrow-right"/>
        </button>
      }
    </div>
  )
}

export default Pagination;
import React from 'react';
import {Link} from 'react-router-dom';
import "./css/MoreWhite.css";

function MoreWhite(props) {
  if (props.target === "_blank") {
    return (
      <a href={props.to} className="btn-more-white" target="_blank" rel="noopener noreferrer">
        <i className="icon-more"/> VER MÁS
      </a>
    )
  }
  return(
    <Link to={props.to} className="btn-more-white">
      <i className="icon-more"/> VER MÁS
    </Link>
  )
}

export default MoreWhite;
export const TERRITORY_MODAL = "TERRITORY_MODAL";
export const TERRITORY_VIEW_COMPONENT = "TERRITORY_VIEW_COMPONENT";
export const TERRITORY_TITLE_COMPONENT = "TERRITORY_TITLE_COMPONENT";
export const TERRITORY_CLEAR_STAGES = "TERRITORY_CLEAR_STAGES";
export const TERRITORY_DATA_STAGES = "TERRITORY_DATA_STAGES";
export const TERRITORY_MAP = "TERRITORY_MAP";
export const TERRITORY_IFRAME = "TERRITORY_IFRAME";
export const TERRITORY_CLEAR = "TERRITORY_CLEAR";
export const TERRITORY_TYPE = "TERRITORY_TYPE";
export const TERRITORY_DATA = "TERRITORY_DATA";
export const TERRITORY_DATA_SELECTED = "TERRITORY_DATA_SELECTED";
export const TERRITORY_LOADING_DATA = "TERRITORY_LOADING_DATA";
export const TERRITORY_PROGRAMS = "TERRITORY_PROGRAMS";
export const TERRITORY_PROGRAM = "TERRITORY_PROGRAM";
export const TERRITORY_LOADING_PROGRAMS = "TERRITORY_LOADING_PROGRAMS";
export const TERRITORY_LOADING_SELECTED = "TERRITORY_LOADING_SELECTED";
export const TERRITORY_REDIRECT = "TERRITORY_REDIRECT";
export const TERRITORY_INDEX = "TERRITORY_INDEX";
export const TERRITORY_REQUEST_LOADING = "TERRITORY_REQUEST_LOADING";
export const TERRITORY_TAB_LOADING = "TERRITORY_TAB_LOADING";
export const TERRITORY_TABS = "TERRITORY_TABS";
export const TERRITORY_TAB_ITEMS = "TERRITORY_TAB_ITEMS";
export const TERRITORY_TAB_CONTENTS = "TERRITORY_TAB_CONTENTS";
export const TERRITORY_TAB_SELECTED = "TERRITORY_TAB_SELECTED";
export const TERRITORY_TAB_SELECTED_ITEMS = "TERRITORY_TAB_SELECTED_ITEMS";
export const TERRITORY_TAB_ITEM_SELECTED = "TERRITORY_TAB_ITEM_SELECTED";
export const TERRITORY_TAB_CONTENTS_PDF = "TERRITORY_TAB_CONTENTS_PDF";
export const TERRITORY_TAB_CONTENTS_GRAPH = "TERRITORY_TAB_CONTENTS_GRAPH";
export const TERRITORY_TOOLTIP = "TERRITORY_TOOLTIP";

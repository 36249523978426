import React from 'react';
import MoreWhite from './MoreWhite';
import './css/MostRecent.css';
import { connect } from 'react-redux';
import ecosystemImage from '../images/coverpage-ecosystem.jpg';

function SquareMostRecent(data) {
  if (data == null) {
    return (<div />)
  }
  data = JSON.parse(data);
  let target = (Number(data.destination) === 0) ? '' : '_blank';
  return (
    <div className="col-sm-6">
      <div className="most-recent-box">
        {data.coverpage ?
          <img src={data.coverpage} alt="coverpage"/>
          :
          <img src={ecosystemImage} alt="coverpage"/>
        }
        <div className="most-recent-description">
          {data.description && `${data.description.slice(0, 220)}...`}
        </div>
        <MoreWhite
          to={data.link}
          target={target}
        />
      </div>
    </div>
  )
}

function MostRecent(props) {
  let most_recents = props.defaultReducer.most_recents.filter((item) => item.label === props.label);
  return(
    <div className="row">
      <div className="col-md-12">
        <h2>{props.title}</h2>
      </div>
      {most_recents[0].item_1 !== '' && most_recents[0].item_1 && SquareMostRecent(most_recents[0].item_1)}
      {most_recents[0].item_2 !== '' && most_recents[0].item_2 && SquareMostRecent(most_recents[0].item_2)}
      {most_recents[0].item_3 !== '' && most_recents[0].item_3 && SquareMostRecent(most_recents[0].item_3)}
      {most_recents[0].item_4 !== '' && most_recents[0].item_4 && SquareMostRecent(most_recents[0].item_4)}
    </div>
  )
}

const mapStateToProps = ({defaultReducer}) => {
  return {
    defaultReducer
  }
}

export default connect(mapStateToProps)(MostRecent);
import React from "react";

function ButtonTutorial(props) {
  const { name, classButton, onClick } = props;
  return (
    <div className={`button-modal ${classButton}`} onClick={onClick}>
      <div className="button-modal-circle">
        <span className="icon-view"></span>
      </div>
      <div className="button-modal-text">{name}</div>
    </div>
  );
}

ButtonTutorial.defaultProps = {
  name: "CÓMO BUSCAR LOS DATOS",
};

export default ButtonTutorial;

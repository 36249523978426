import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import InitiativeIcon from "../InitiativeIcon";

function Item(props) {
  const { initiative, columnClass, buttonName } = props;

  return (
    <div className={columnClass}>
      <div className="InitiativeList_item">
        <div className="InitiativeList_title">
          <h2>{initiative.name}</h2>
          {initiative.official_link && !initiative.content && (
            <a
              href={initiative.official_link}
              target="_blank"
              rel="noopener noreferrer"
              className="InitiativeList_link"
            >
              <span className="icon-link" />
              Link
            </a>
          )}
        </div>
        <div className="InitiativeList_icons">
          {initiative.stretches.map((stretch, index) => (
            <InitiativeIcon
              key={index}
              label={stretch.label}
              name={stretch.name}
              icon={stretch.icon}
            />
          ))}
          {initiative.components.map((component, index) => (
            <InitiativeIcon
              key={index}
              label={component.label}
              name={component.name}
              icon={component.icon}
            />
          ))}
          {initiative.levels.map((level, index) => (
            <InitiativeIcon
              key={index}
              label={level.label}
              name={level.name}
              icon={level.icon}
            />
          ))}
        </div>
        <div className="InitiativeList_actors">
          {initiative.actors.map((actor, index) => (
            <span key={index}>{actor.name}</span>
          ))}
        </div>
        {initiative.description && (
          <div dangerouslySetInnerHTML={{ __html: initiative.description }} />
        )}
        {initiative.content && (
          <div className="InitiativeList_detail">
            <Link to={`/ecosistemas-iniciativas/${initiative.slug}`}>
              {buttonName}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

Item.defaultProps = {
  columnClass: "col-md-6",
  buttonName: "Ver detalle",
};

export default withRouter(Item);

import React from "react";
import { connect } from "react-redux";
import {
  userEmail,
  userPassword,
  userFormType,
  userLogin,
} from "../../functionality/actions/userActions";

function Login(props) {
  const {
    userReducer: { user_email, user_password },
    userEmail,
    userPassword,
    userFormType,
    userLogin,
  } = props;
  return (
    <div>
      <h1>INICIO DE SESIÓN</h1>
      <div className="form-group">
        <label>EMAIL:</label>
        <input
          type="email"
          className="form-control"
          value={user_email}
          onChange={(input) => userEmail(input.target.value)}
        />
      </div>
      <div className="form-group">
        <label>CONTRASEÑA:</label>
        <input
          type="password"
          className="form-control"
          value={user_password}
          onChange={(input) => userPassword(input.target.value)}
        />
        <div
          className="Form_action text-center"
          onClick={() => userFormType("forgot")}
        >
          Recuperar contraseña
        </div>
      </div>
      <button className="Forms_button" onClick={userLogin}>
        INICIAR SESIÓN
      </button>
      <div
        className="Form_action text-center"
        onClick={() => userFormType("register")}
      >
        Registrarme
      </div>
    </div>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  userEmail,
  userPassword,
  userFormType,
  userLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import {
    SURVEY_NAME_COMPANY,
    SURVEY_NAME,
    SURVEY_EMAIL,
    SURVEY_PHONE,
    SURVEY_STATE,
    SURVEY_SELECT,
    SURVEY_DATA,
  } from "../types/surveyTypes";
  import Api from "../Api";
  
  export const surveyNameCompany = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_NAME_COMPANY,
      payload: value,
    });
  };
  
  export const surveyName = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_NAME,
      payload: value,
    });
  };

  export const surveyEmail = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_EMAIL,
      payload: value,
    });
  };

  export const surveyPhone = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_PHONE,
      payload: value,
    });
  };
  
  export const surveyState = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_STATE,
      payload: value,
    });
  };
  
  export const surveySelect = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_SELECT,
      payload: value,
    });
  };

  export const surveyData = (value) => (dispatch) => {
    dispatch({
      type: SURVEY_DATA,
      payload: value,
    });
  }

  export const surveyGetQuestion = () => (dispatch, getState) => {
    console.log("entra a peticioń")
    Api.callMethod("surveyGetQuestion", "GET", function (res) {
      if (res.error.code === 0) {
        const { data } = res;
        dispatch({
          type: SURVEY_DATA,
          payload: data,
        });
        
      } else {
        /*dispatch({
          type: CONTACT_ERROR,
          payload: "Lo sentimos, hubo un error; vuelve a intentarlo más tarde.",
        });*/
      }
    });
  }
  
  /*export const pollMessage = (value) => (dispatch) => {
    dispatch({
      type: CONTACT_MESSAGE,
      payload: value,
    });
  };
  
  export const contactClear = () => (dispatch) => {
    dispatch({
      type: CONTACT_CLEAR,
    });
  };
  
  export const contactSend = () => (dispatch, getState) => {
    const {
      contact_name,
      contact_institution,
      contact_email,
      contact_phone,
      contact_message,
    } = getState().contactReducer;
    if (
      contact_name === "" ||
      contact_institution === "" ||
      contact_email === "" ||
      contact_phone === "" ||
      contact_message === ""
    ) {
      dispatch({
        type: CONTACT_ERROR,
        payload: "Todos los campos son obligatorios.",
      });
      return;
    }
    dispatch({
      type: CONTACT_ERROR,
      payload: "",
    });
    dispatch({
      type: CONTACT_STATUS,
      payload: true,
    });
    let data = {
      name: contact_name,
      institution: contact_institution,
      email: contact_email,
      phone: contact_phone,
      message: contact_message,
    };
    Api.callMethod("contact", "POST", data, function (res) {
      if (res.error.code === 0) {
        dispatch({
          type: CONTACT_SUCCESS,
          payload:
            "El mesaje fue enviado con éxito, pronto nos pondremos en contacto.",
        });
        window.scrollTo(0, 0);
      } else {
        dispatch({
          type: CONTACT_ERROR,
          payload: "Lo sentimos, hubo un error; vuelve a intentarlo más tarde.",
        });
      }
    });
  };*/
  
import React from "react";

function EcosystemBox(props) {
  return (
    <div className="ecosystem-stage-square">
      <div className="ecosystem-stage-square-title">{props.name}</div>
      <div className="ecosystem-stage-square-number">
        {props.number ? props.number : "-"}
      </div>
    </div>
  );
}

export default EcosystemBox;

import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import ModalTutorialEcosystems from "../components/ModalTutorialEcosystems";
import SectionSlider from "../components/SectionSlider";
import ButtonGraph from "../components/ButtonGraph";
import ButtonTutorial from "../components/ButtonTutorial";
import image from "../images/bg-ecosystems.jpg";
import InitiativeTotal from "../components/InitiativeTotal";
import InitiativeFilters from "../components/InitiativeFilters";
import Loading from "../components/Loading";
import InitiativeSections from "../components/InitiativeSections";
import InitiativeCount from "../components/InitiativeCount";
import InitiativeList from "../components/InitiativeList";
import ModalTutorialInitiative from "../components/ModalTutorialInitiative";
import FormButton from "../components/FormButton";
import {
  openCloseModal,
  ecosystemViewGraph,
} from "../functionality/actions/ecosystemsActions";
import {
  initiativeGetFilters,
  getInitiatives,
  initiativeModal,
} from "../functionality/actions/initiativeActions";
import Detail from "../components/Initiative/Detail";

class InitiativesPage extends Component {
  componentDidMount() {
    const {
      initiativeReducer: { initiative_loading },
      initiativeGetFilters,
      getInitiatives,
    } = this.props;
    window.scrollTo(0, 0);

    if (initiative_loading) {
      initiativeGetFilters();
      getInitiatives();
    }
  }

  _renderGraphs = (value) => {
    let sources = value.split(";");
    let response = sources.map((item, index) => {
      item = item.trim(";");
      if (item.length > 0) {
        return (
          <div className="component-content" key={index}>
            <iframe src={item} title="Gráfico Ecosistemas de Iniciativas" />
          </div>
        );
      }
      return null;
    });

    return response;
  };

  render() {
    const {
      ecosystemsReducer: { ecosystem_modal, ecosystem_view_graph },
      defaultReducer: { other_contents, sliders, images },
      initiativeReducer: { initiative_loading, initiative_modal },
      openCloseModal,
      ecosystemViewGraph,
      initiativeModal,
      match: {
        params: { initiative },
      },
    } = this.props;

    let ecosystem = other_contents.filter(
      (item) => item.label === "ecosistemas_iniciativas"
    );
    let slider = sliders.filter(
      (item) => item.name === "ecosistemas_inciativas"
    );
    let graphic = other_contents.filter(
      (item) => item.label.indexOf("ecosistemas_grafico") >= 0
    );
    let imageModalIcons = images.find(
      (item) => item.label === "ecosistemas-iconos"
    );

    return (
      <LayoutDefault>
        {initiative && <Detail slug={initiative} />}
        <ModalTutorialEcosystems
          visible={ecosystem_modal}
          openCloseModal={openCloseModal}
        />
        {imageModalIcons && imageModalIcons.image && (
          <ModalTutorialInitiative
            visible={initiative_modal}
            openCloseModal={initiativeModal}
          />
        )}
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div className="title-section">{ecosystem[0].title}</div>
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-yellow"
              dangerouslySetInnerHTML={{ __html: ecosystem[0].value }}
            />
            <InitiativeTotal />
          </div>
        </div>
        {graphic.length > 0 && graphic[1].value && graphic[1].value !== "" && (
          <div className="">
            {graphic[0].value && graphic[0].value !== "" && (
              <>
                <ButtonGraph
                  background="#F59C01"
                  name={graphic[0].value}
                  action={() => ecosystemViewGraph(!ecosystem_view_graph)}
                  status={ecosystem_view_graph}
                />
                {ecosystem_view_graph && this._renderGraphs(graphic[1].value)}
              </>
            )}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <ButtonTutorial
            onClick={() => openCloseModal("active")}
            classButton="InitiativeButton_modal"
          />
          {imageModalIcons && imageModalIcons.image && (
            <ButtonTutorial
              onClick={() => initiativeModal("active")}
              name="INIDICE DE ICONOS"
              classButton="InitiativeButton_modal"
            />
          )}
        </div>
        <div className="InitiativePage_content">
          {initiative_loading ? (
            <Loading />
          ) : (
            <>
              <InitiativeFilters />
              <div className="row">
                <div className="col-md-8">
                  <InitiativeSections />
                </div>
                <div className="col-md-4">
                  <InitiativeCount />
                </div>
              </div>
              <FormButton />
              <InitiativeList />
            </>
          )}
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({
  defaultReducer,
  ecosystemsReducer,
  initiativeReducer,
}) => {
  return {
    defaultReducer,
    ecosystemsReducer,
    initiativeReducer,
  };
};

const mapDispatchToProps = {
  openCloseModal,
  ecosystemViewGraph,
  initiativeGetFilters,
  getInitiatives,
  initiativeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitiativesPage);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutDefault from './layouts/LayoutDefault';
import image from '../images/bg-territory.jpg';
import ListCities from '../components/ListCities';
import TerritoryPopulation from '../components/TerritoryPopulation';
import TerritoryStages from '../components/TerritoryStages';
import TerritoryMap from '../components/TerritoryMap';
import TerritoryComponent from '../components/TerritoryComponent';
// import PopulationDetail from '../components/PopulationDetail';
import ModalTutorial from '../components/ModalTutorial';
import * as territoryActions from '../functionality/actions/territoryActions';
import * as indexActions from '../functionality/actions/indexActions';
import './css/TerritoryPage.css';
import SectionSlider from '../components/SectionSlider';

const {
  openCloseModal, territoryViewComponent, territoryClearStages, territoryDataStages,
  territoryClear
} = territoryActions;

const {changeMenu} = indexActions;
class TerritoryPage extends Component {
  componentDidMount() {
    const {territoryDataStages} = this.props;
    if (this.props.match.params.id) {
      territoryDataStages(this.props.match.params.id);
    } else {
      window.scrollTo(0,0);
      territoryDataStages(null);
    }
    if (this.props.match.params.powerBi) {
      this.showComponent(this.props.match.params.powerBi, true);
    }
  }

  componentDidUpdate(prevProps) {
    const {territoryDataStages} = this.props;
    if (!this.props.match.params.id && prevProps.match.params.id) {
      territoryDataStages(null);
    }
    if (this.props.match.params.id && prevProps.match.params.id !== this.props.match.params.id) {
      territoryDataStages(this.props.match.params.id);
    }
    if (this.props.match.params.powerBi && prevProps.match.params.powerBi !== this.props.match.params.powerBi) {
      this.showComponent(this.props.match.params.powerBi, false);
    }
  }

  componentWillUnmount() {
    const {territoryClear, changeMenu} = this.props;
    changeMenu('');
    territoryClear();
  }

  showComponent = (graph, loadTime) => {
    const {
      defaultReducer: { territory_titles },
      territoryViewComponent,
    } = this.props;
    let title = '';
    switch(graph) {
      case 'detalle_t1':
        title = territory_titles[40].value;
        break;
      case 'detalle_t2':
        title = territory_titles[41].value;
        break;
      case 'detalle_t3':
        title = territory_titles[42].value;
        break;
      case 'detalle_t4':
        title = territory_titles[43].value;
        break;
      default:
        title = '';
        break;
    }

    territoryViewComponent(true, title, graph, loadTime);
  }

  render() {
    const {
      territory_view_component, territory_modal, territory_iframe,
      territory_title_component, territory_stage_half, territory_stage_postmedia,
      territory_stage_intermediation, territory_stage_job, territory_map
    } = this.props.territoryReducer;
    const {
      openCloseModal
    } = this.props;
    const {
      cities, other_contents, territory_titles, sliders,
    } = this.props.defaultReducer;
    let territory = other_contents.filter(item => (item.label === 'territorio'));
    let slider = sliders.filter(item => (item.name === 'datos_territorio'));
    return (
      <LayoutDefault>
        <ModalTutorial
          visible={territory_modal}
          openCloseModal={openCloseModal}
        />
        <div className="row">
          <div className="col-md-12">
            {!slider.length || slider[0].is_active !== 1 || !slider[0].slides.length ?
              <div className="bg-section" style={{backgroundImage: `URL(${image})`}}>
                <div className="title-section"
                  dangerouslySetInnerHTML={{__html: territory[0].title.replace(' ', '</br>')}}
                />
              </div>
              :
              <SectionSlider
                slides={slider[0].slides}
              />
            }
            <div className="description-section bg-purple"
              dangerouslySetInnerHTML={{__html: territory[0].value}}
            />
          </div>
        </div>
        <div className="map-button" onClick={() => openCloseModal('active')}>
          <div className="map-button-circle"><span className="icon-view"/></div>
          <div className="map-button-text">CÓMO BUSCAR LOS DATOS</div>
        </div>
        <TerritoryPopulation />
        <div className="territory-content">
          <ListCities
            data={cities}
            to='datos-territorio'
            active="purple"
            title={territory_titles[10].value}
          />
          <div className="territory-stages-container" id="territory-stages">
            <TerritoryStages
              stage_half={territory_stage_half}
              stage_postmedia={territory_stage_postmedia}
              stage_intermediation={territory_stage_intermediation}
              stage_job={territory_stage_job}
              titles={territory_titles}
            />
          </div>
          <div className="territory-map">
            <TerritoryMap map={territory_map}/>
          </div>
        </div>
        <div id="territory-component">
          {territory_view_component &&
            <TerritoryComponent
              principalTitle={territory_titles[21].value}
              title={territory_title_component}
              sources={territory_iframe}
            />
          }
        </div>
        {/* <PopulationDetail
          title="Mujeres"
          chart_1={chart_1}
          chart_2={chart_2}
          chart_3={chart_3}
        /> */}
      </LayoutDefault>
    )
  }
}

const mapStateToProps = ({territoryReducer, defaultReducer}) => {
  return {
    territoryReducer, defaultReducer,
  }
}

const mapDispatchToProps = {
  openCloseModal, territoryViewComponent, territoryClearStages, territoryDataStages,
  territoryClear, changeMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryPage);
import React from "react";
import { connect } from "react-redux";
import { getTitle, getIcon } from "../functionality/actions/currentActions";
import EcosystemBox from "./EcosystemBox";

function InitiativeSections(props) {
  const {
    defaultReducer: { ecosystem_titles },
    initiativeReducer: {
      initiative_stretches,
      initiative_t1_c1,
      initiative_t1_c2,
      initiative_t1_c3,
      initiative_t2_c1,
      initiative_t2_c2,
      initiative_t2_c3,
      initiative_t3_c3,
      initiative_t3_c4,
      initiative_t4_c3,
      initiative_t4_c4,
    },
  } = props;

  return (
    <div>
      <h5 className="subtitle-section">
        {getTitle(ecosystem_titles, "ecosistema_txt_2")}
      </h5>
      <div className="ecosystem-stage-content bg-gray-gradient">
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div
              className="ecosystem-stage-icon"
              dangerouslySetInnerHTML={{
                __html: getIcon(initiative_stretches, "T1"),
              }}
            />
            <div className="ecosystem-stage-title-txt">
              {getTitle(ecosystem_titles, "ecosistema_txt_4")}
            </div>
          </div>
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_5")}
            number={initiative_t1_c1}
          />
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_6")}
            number={initiative_t1_c2}
          />
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_7")}
            number={initiative_t1_c3}
          />
        </div>
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div
              className="ecosystem-stage-icon"
              dangerouslySetInnerHTML={{
                __html: getIcon(initiative_stretches, "T2"),
              }}
            />
            <div className="ecosystem-stage-title-txt">
              {getTitle(ecosystem_titles, "ecosistema_txt_8")}
            </div>
          </div>
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_9")}
            number={initiative_t2_c1}
          />
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_10")}
            number={initiative_t2_c2}
          />
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_11")}
            number={initiative_t2_c3}
          />
        </div>
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div
              className="ecosystem-stage-icon"
              dangerouslySetInnerHTML={{
                __html: getIcon(initiative_stretches, "T3"),
              }}
            />
            <div className="ecosystem-stage-title-txt">
              {getTitle(ecosystem_titles, "ecosistema_txt_12")}
            </div>
          </div>
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_13")}
            number={initiative_t3_c3}
          />
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_14")}
            number={initiative_t3_c4}
          />
        </div>
        <div className="ecosystem-stage-row">
          <div className="ecosystem-stage-title">
            <div
              className="ecosystem-stage-icon"
              dangerouslySetInnerHTML={{
                __html: getIcon(initiative_stretches, "T4"),
              }}
            />
            <div className="ecosystem-stage-title-txt">
              {getTitle(ecosystem_titles, "ecosistema_txt_15")}
            </div>
          </div>
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_15_b")}
            number={initiative_t4_c3}
          />
          <EcosystemBox
            name={getTitle(ecosystem_titles, "ecosistema_txt_16")}
            number={initiative_t4_c4}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ defaultReducer, initiativeReducer }) => {
  return {
    defaultReducer,
    initiativeReducer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InitiativeSections);

import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import LayoutDefault from './layouts/LayoutDefault';
import image from '../images/bg-contact.png';
import * as surveyActions from '../functionality/actions/surveyActions';
import Api from "../functionality/Api";
import { API_ENDPOINT } from "../config/config";

const {
    surveyNameCompany, 
    surveyName,
    surveyEmail,
    surveyPhone,
    surveyState, 
    surveySelect, 
    surveyGetQuestion, 
    surveyData
  } = surveyActions;

var step = 1,
  count = 1,
  visible = [],
  url = window.location.href.split('/'),
  id = url[url.length-1],
  urlHome = window.location.href.split('encuesta-formulario')[0]

function validateQuestions(){
  let questions = document.getElementById('categories_'+step).querySelectorAll('.card-body')
    
  for(let i = 0; i < questions.length; i++){
    let question = questions[i],
      answers = question.querySelectorAll('.answers'),
      checked = 0

    for(let j = 0; j < answers.length; j++){
      let answer = answers[j]

      if(answer.checked === true){
        checked = 1
        break
      }
    }

    if(answers.length == 0){
      let questionChield = question.querySelectorAll('.row'),
        checkedChield = 0

      //console.log(questionChield,'hijas')

      for(let j = 0; j < questionChield.length; j++){
        let row = questionChield[j],
          answerChields = row.querySelectorAll('.answerChield')

        for(let k = 0; k < answerChields.length; k++){
          let ac = answerChields[k]

          if(ac.checked === true){
            checkedChield++
            break
          }
        }
      }

      if(checkedChield == questionChield.length){
        checked = 1
      }
    }
    
    if(checked == 0){
      document.getElementById('alerta').innerHTML = "Para continuar debe contestar todas las preguntas"
      document.getElementById('alerta').style.display = 'block'
      return false
    }
  }

  document.getElementById('alerta').style.display = 'none'
  return true
}

function validateForm(){
  let empresa = document.getElementById('empresa').value,
    nombre = document.getElementById('nombre').value,
    email = document.getElementById('email').value,
    telefono = document.getElementById('telefono').value,
    text = ''

  if(empresa == ''){
    text += 'El campo "Nombre de su empresa" no puede estar vacio'
  }

  if(nombre == ''){
    text += '<br><br>El campo "su Nombre" no puede estar vacio'
  }

  if(email == ''){
    text += '<br><br>El campo "Su Correo" no puede estar vacio'
  } else {
    if(email.indexOf('@') == -1){
      text += '<br><br>El campo "Su Correo" no es valido debe contener el simbolo @'
    }
  }

  if(telefono == ''){
    text += '<br><br>El campo "Teléfono de contacto" no puede estar vacio'
  }

  if(text == ''){
    document.getElementById('alerta').style.display = 'none'
    return true
  } else {
    document.getElementById('alerta').innerHTML = text
    document.getElementById('alerta').style.display = 'block'
    return false
  }
  
}
class SurveyFormPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      data: {}
    };
  }

  componentDidMount() {

    var that = this

    Api.callMethod("getSurveyData/"+id, "GET", null,
     function (res) {
        that.setState({
          isLoaded: true,
          data: res.data
        });
    })
    
    window.scrollTo(0,0);
  }

  next(){
    console.log("entra en next")

    if(validateQuestions() == true  && validateForm() == true){
      //ocultar actual
      document.getElementById('categories_'+step).style.display = 'none'
      
      step++

      if(step < count){
        //mostrar siguiente
        document.getElementById('categories_'+step).style.display = 'block'
      }

      if(step == count-1){
        document.getElementById('enviar').style.display = 'block'
        document.getElementById('siguiente').style.display = 'none'
      }
    }
  }

  send(){
    document.getElementById('send').disabled = true
    
    if(validateQuestions() == true  && validateForm() == true){
    
      document.getElementById('send').disable = true

      var answers = [],
        inputs = document.getElementsByClassName('answers'),
        chield = document.getElementsByClassName('answerChield'),
        empresa = document.getElementById('empresa').value,
        nombre = document.getElementById('nombre').value,
        telefono = document.getElementById('telefono').value,
        email = document.getElementById('email').value
      
      for(let i = 0; i < inputs.length; i++ )
      {
        let input = inputs[i]

        if(input.checked === true){
          let name = input.getAttribute('name').split('_'),
            idCategory = name[1],
            idQuestion = name[2]

          answers.push(idCategory+'_'+idQuestion+'_'+input.value)
        }
      }

      for(let i = 0; i < chield.length; i++){
        let answer = chield[i]

        if(answer.checked === true){
          let name = answer.getAttribute('name').split('_'),
            idCategory = name[1],
            idQuestion = name[2],
            idAnswer = name[3]

          answers.push(idCategory+'_'+idQuestion+'_'+idAnswer+'_'+answer.value)
        }
      }

      Api.callMethod("survey/save", "POST", { 
        id: id,
        answers: answers,
        company: empresa,
        name: nombre,
        phone: telefono,
        email: email
      }, function (res) {
        console.log("aqui en save")
        if (res.resp === 1) {
          document.getElementById('correcto').style.display = 'block'

          setTimeout(function(){
            window.location.href = urlHome
          },'10000')
          
        } else {
          
        }
      })
    } else {
      document.getElementById('send').disabled = false
    }
  }

  render() {
    const { error, isLoaded, items, data } = this.state;
    const {
        surveyNameCompany, surveyName, surveyEmail, surveyPhone, surveyState, surveySelect
      } = this.props;

    
    setTimeout(() => {
      console.log('cargando',data.categories)
    },'3000')
    
    if(isLoaded == false ) return <label>Cargando</label>;

    //contador de preguntas
    let countQuestion = 0

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            <div className="bg-section contact-bg" style={{backgroundImage: `URL(${API_ENDPOINT}./../../skins/admin/img/surveys/${data.image})`}}>
              <div className="title-section">Herramienta: { data.survey } </div>
            </div>
          </div>
          <div className="offset-md-1 col-md-10">
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
            <br/>
          </div>
          <div className="offset-md-1 col-md-10">
            <div>
              
                <label>Nombre de su empresa:</label>
                <input type="text" className="form-control" placeholder="Nombre empresa"
                  id="empresa"
                />
                <label>Su Nombre:</label>
                <input type="text" className="form-control" placeholder="Nombre"
                  id="nombre"
                />
                <label>Su Correo:</label>
                <input type="text" className="form-control" placeholder="Correo"
                  id="email"
                />
                <label>Teléfono de contacto:</label>
                <input type="text" className="form-control" placeholder="Teléfono"
                  id="telefono"
                />
                <br/>
                <br/>
                
                {data.categories.map((categorie,c) => {
                  let style = { border:"solid 2px "+categorie.color },
                    id = "categories_"+count
                    
                  visible[count] = {};
                  
                  if(count != step){
                    visible[count]= { display: 'none' }
                  }

                  /*
                  * se agrega uno mas al count
                  * pero para imprimir se debe restar uno
                  * se hace de esta forma ya que despues no es posible amentar count
                  * contador de categorias para los pasos
                  */
                  count++
                  console.log(count, 'aqui contador', visible[count-1],'...')

                  return (
                    <div id={id} style={visible[count-1]}>
                      <div style={style}>
                        <div class="row col-sm-12 justify-content-center align-items-center">
                          <h2>{categorie.name}</h2>
                        </div>
                        
                        {' '}
                        {categorie.questions.map((question,q) => {
                          countQuestion++

                          return (
                            <div class="card">
                              <div class="card-body">
                                <h5 class="card-title">{countQuestion}){question.question}</h5>
                                {question.answers.map((answer,a) => {
                                  let name = "question_"+categorie.id+"_"+question.id
                                  return(
                                    answer.is_parent == 0 ?
                                      <div class="row">
                                        <div class="col-sm-1"></div>
                                        <div class="col-sm-11 form-check">
                                          
                                          <input class="form-check-input answers" type="radio" name={name} id={name} value={answer.id}/>
                                          <label class="form-check-label">
                                            { answer.text }
                                          </label>
                                        </div>
                                      </div> 
                                      :
                                      
                                      <div class="row">
                                        <div class="col-sm-1"></div>
                                        <div class="col-sm-5">
                                          <h6 class="card-title">-{ answer.text }</h6>
                                        </div>
                                        <div class="col-sm-6">
                                        <div style={{display:'none'}}>{name = name+'_'+answer.id}</div>
                                        {
                                        answer.answers_child.length <= 2 ?
                                          answer.answers_child.map((ac,ackey) => {
                                            
                                            return(
                                              <span class="col-sm-6">
                                                <input type="radio" class="answerChield" value={ac.id} name={name} />
                                                <span class="col-sm-6"><label> { ac.text } </label></span>
                                              </span>
                                            )
                                          })
                                        :
                                          <div>
                                            {answer.answers_child.map((ac,ackey) => {
                                              return(
                                                <div class="col-sm-12">
                                                  <input type="radio" class="answerChield" value={ac.id} name={name} />
                                                  <span> <label> { ac.text } </label></span>
                                                </div>
                                              )
                                            })}
                                            <hr/> 
                                          </div>
                                        }
                                        </div>
                                      </div>
                                      
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    <div><br/></div>
                  </div>
                  )
                  
                })}
              
                
                <div style={{display: 'none'}} id="enviar">
                  <button id="send" class="btn btn-block btn-send" onClick={this.send}>
                    ENVIAR
                  </button>
                  <br></br>
                </div>
              
                <div id="siguiente">
                  <button class="btn btn-block btn-send" onClick={this.next}>
                    SIGUIENTE
                  </button>
                  <br></br>
                </div>

                <div id="alerta" class="alert alert-danger" style={{display: 'none'}}></div>

                <div id="correcto" class="alert alert-success" style={{display: 'none'}}>
                  ¡Se han guardado los datos correctamente!<br/>
                  Se enviará al correo ingresado los resultados de la Herramienta.<br/>
                  Por favor revise su carpeta de correo no deseado, es posible que los resultados lleguen allí.
                </div>

            </div>
          </div>
        </div>
      </LayoutDefault>
    )
  }
}

const mapStateToProps = ({contactReducer}) => {
  return {
    contactReducer
  }
}

const mapDispatchToProps = {
    surveyNameCompany, surveyName, surveyEmail, surveyPhone, surveyState, surveySelect
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyFormPage);
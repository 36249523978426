import {
  NEWS_VIEW_GRAPH,
  NEWS_TOTAL,
  NEWS_SUBCATEGORIES,
  NEWS_COD_CATEGORY,
  NEWS_COD_SUBCATEGORY,
  NEWS_PER_CATEGORY,
  NEWS_LOADING,
} from "../types/newsTypes";

const INITIAL_STATE = {
  news_view_graph: true,
  news_total: [],
  news_per_category: [],
  news_subcategories: [],
  news_cod_category: null,
  news_cod_subcategory: null,
  news_loading: true,
};

const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEWS_VIEW_GRAPH:
      return {
        ...state,
        news_view_graph: action.payload,
      };
    case NEWS_TOTAL:
      return {
        ...state,
        news_total: action.payload,
      };
    case NEWS_PER_CATEGORY:
      return {
        ...state,
        news_per_category: action.payload,
      };
    case NEWS_SUBCATEGORIES:
      return {
        ...state,
        news_subcategories: action.payload,
      };
    case NEWS_COD_CATEGORY:
      return {
        ...state,
        news_cod_category: action.payload,
      };
    case NEWS_COD_SUBCATEGORY:
      return {
        ...state,
        news_cod_subcategory: action.payload,
      };
    case NEWS_LOADING:
      return {
        ...state,
        news_loading: action.payload,
      };
    default:
      return { ...state };
  }
};

export default newsReducer;

import React, { Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import "./css/Header.css";
import logo from "../images/logo.png";
import { connect } from "react-redux";
import { changeMenu } from "../functionality/actions/indexActions";
import {
  userFormType,
  userFormModal,
  userLogout,
} from "../functionality/actions/userActions";

function Header(props) {
  const {
    defaultReducer: { menu_visible, texts, images },
    userReducer: { user },
    changeMenu,
    userFormType,
    userFormModal,
    userLogout,
  } = props;
  const menu_names = texts.filter((item) => item.label.indexOf("menu") >= 0);
  const slogan = texts.filter((item) => item.label === "slogan");
  let end_link =
    menu_names[5].value.toLowerCase() === "contacto"
      ? "contacto"
      : menu_names[5].value;
  end_link = end_link.toLowerCase().replace(" ", "-");
  let img_logo = images.filter((item) => item.label === "logo");
  img_logo = !img_logo.length || !img_logo[0].image ? logo : img_logo[0].image;

  return (
    <Fragment>
      <header>
        <div className="Header_session">
          {user ? (
            <>
              <button
                onClick={() => {
                  userFormType("profile");
                  userFormModal("visible");
                }}
              >
                Mis datos
              </button>
              /<button onClick={userLogout}>Cerrar sesión</button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  userFormType("login");
                  userFormModal("visible");
                }}
              >
                Iniciar Sesión
              </button>
              /
              <button
                onClick={() => {
                  userFormType("register");
                  userFormModal("visible");
                }}
              >
                Registrarme
              </button>
            </>
          )}
        </div>
        <Link to="/">
          <img src={img_logo} alt="logo" className="header-logo" />
          <p>{slogan[0].value}</p>
        </Link>
      </header>
      <div className="header-box-menu">
        <div className="header-line" />
        <button
          className="menu-responsive"
          onClick={() => changeMenu("visible")}
        >
          <span className="icon-menu" />
          MENÚ
        </button>
        <div className={`header-menu ${menu_visible}`}>
          <button onClick={() => changeMenu("")} className="close-menu">
            <span className="icon-plus" />
          </button>
          <NavLink
            to="/quienes-somos"
            className="header-about"
            activeClassName="header-about-active"
          >
            {menu_names[0].value}
            <div />
          </NavLink>
          <NavLink
            to="/territorio"
            className="header-territory"
            activeClassName="header-territory-active"
          >
            {menu_names[1].value}
            <div />
          </NavLink>
          {/* <NavLink
            to="/herramientas-metodologias"
            className="header-tool"
            activeClassName="header-tool-active"
          >
            {menu_names[2].value}
            <div />
          </NavLink> */}
          <NavLink
            to="/ecosistemas-iniciativas"
            className="header-ecosystem"
            activeClassName="header-ecosystem-active"
          >
            {menu_names[3].value}
            <div />
          </NavLink>
          <NavLink
            to="/documentos-tecnicos"
            className="header-document"
            activeClassName="header-document-active"
          >
            {menu_names[4].value}
            <div />
          </NavLink>
          <NavLink
            to={`/${end_link}`}
            className="header-contact"
            activeClassName="header-contact-active"
          >
            {menu_names[5].value}
            <div />
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ defaultReducer, userReducer }) => {
  return {
    defaultReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  changeMenu,
  userFormType,
  userFormModal,
  userLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

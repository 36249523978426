import React from "react";
import { connect } from "react-redux";
import { getTitle } from "../functionality/actions/currentActions";

function InitiativeTotal(props) {
  const {
    defaultReducer: { ecosystem_titles },
    initiativeReducer: { initiative_total, initiative_individual },
  } = props;

  return (
    <div className="ecosystems-count">
      <div className="ecosystems-count-title">
        {getTitle(ecosystem_titles, "ecosistema_txt_23")}
      </div>
      <div className="ecosystems-count-container bg-gray-gradient">
        <div className="ecosystems-count-item">
          <div className="ecosystems-count-subtitle">
            {getTitle(ecosystem_titles, "ecosistema_totales")}
          </div>
          <div className="ecosystems-count-content">
            <div className="ecosystems-count-number">{initiative_total}</div>
          </div>
        </div>
        <div className="ecosystems-count-item">
          <div className="ecosystems-count-subtitle">
            {getTitle(ecosystem_titles, "ecosistema_unicas")}
          </div>
          <div className="ecosystems-count-content">
            <div className="ecosystems-count-number">
              {initiative_individual}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ defaultReducer, initiativeReducer }) => {
  return {
    defaultReducer,
    initiativeReducer,
  };
};

export default connect(mapStateToProps, null)(InitiativeTotal);

import React from "react";
import { connect } from "react-redux";
import {
  userName,
  userEmail,
  userEntity,
  userPassword,
  userPhone,
  userSaveData,
  userFormType,
  userTerms,
} from "../../functionality/actions/userActions";

function Register(props) {
  const {
    userReducer: {
      user_name,
      user_email,
      user_entity,
      user_password,
      user_phone,
      user_terms,
    },
    defaultReducer: { other_contents },
    userName,
    userEmail,
    userEntity,
    userPassword,
    userPhone,
    userSaveData,
    userFormType,
    userTerms,
  } = props;

  let linkTerms = other_contents.filter(
    (item) => item.label === "tratamiento_datos"
  );
  linkTerms = linkTerms.length
    ? linkTerms[0].value
    : "https://www.fundacioncorona.org/es/sobre-nosotros/tratamiento-de-datos";

  let checked = user_terms ? "checked" : "";

  return (
    <div>
      <h1>REGISTRO</h1>
      <div className="form-group">
        <label>NOMBRE:</label>
        <input
          type="text"
          className="form-control"
          value={user_name}
          onChange={(input) => userName(input.target.value)}
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>TELÉFONO:</label>
            <input
              type="text"
              className="form-control"
              value={user_phone}
              onChange={(input) => userPhone(input.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>ENTIDAD:</label>
            <input
              type="text"
              className="form-control"
              value={user_entity}
              onChange={(input) => userEntity(input.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>EMAIL:</label>
        <input
          type="email"
          className="form-control"
          value={user_email}
          onChange={(input) => userEmail(input.target.value)}
        />
      </div>
      <div className="form-group">
        <label>CONTRASEÑA:</label>
        <input
          type="password"
          className="form-control"
          value={user_password}
          onChange={(input) => userPassword(input.target.value)}
        />
        <label className="Forms_terms">
          <input type="checkbox" onChange={userTerms} checked={checked} />
          <p>
            La información aquí recopilada será incorporada en la base de datos
            de Includere. Al enviar este formulario usted acepta la{" "}
            <a href={linkTerms} target="_blank" rel="noopener noreferrer">
              política de tratamiento y uso de datos
            </a>
            .
          </p>
        </label>
      </div>
      <button className="Forms_button" onClick={userSaveData}>
        REGISTRARME
      </button>
      <div
        className="Form_action text-center"
        onClick={() => userFormType("login")}
      >
        Iniciar Sesión
      </div>
    </div>
  );
}

const mapStateToProps = ({ userReducer, defaultReducer }) => {
  return {
    userReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  userName,
  userEmail,
  userEntity,
  userPassword,
  userPhone,
  userSaveData,
  userFormType,
  userTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

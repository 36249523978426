import React from 'react';
import { connect } from 'react-redux';
import BoxPopulation from './BoxPopulation';

function viewFile(name, documents) {
  let file = documents.filter((item) => (item.label === name));
  if (!file.length) {
    return;
  }
  if (file[0].type && file[0].type === 1) {
    window.open(file[0].link, '_blank');
  } else if (file[0].file) {
    window.open(file[0].file, '_blank');
  }
}

function TerritoryPopulation(props) {
  const {other_documents, territories, territory_titles} = props.defaultReducer;
  let population = territories.filter((item) => (item.tramo === 'POB'));
  return(
    <div className="territory-population-container">
      <div className="territory-population-title">
        <div className="subtitle-section">
          {territory_titles[0].value}<br/>
          <span>{territory_titles[1].value}</span>
        </div>
      </div>
      <div className="territory-population bg-gray-gradient">
        {population.map((item, index) => {
          let position = index + 2;
          return (
            <BoxPopulation
              key={item.id}
              action={() => viewFile(item.desagregacion, other_documents)}
              title={territory_titles[position].value}
              background={`bg-purple-${index}`}
              number={item.valor}
            />
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = ({defaultReducer}) => {
  return {
    defaultReducer
  }
}

export default connect(mapStateToProps)(TerritoryPopulation);
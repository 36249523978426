import {
  TOOLS_SELECTED,
  TOOLS_CHANGE_PAGE,
  TOOLS_VIEW_DOCS,
  TOOLS_VIEW_DETAIL_DOC,
  TOOLS_DATA,
  TOOLS_SEARCH,
  TOOLS_DETAIL,
  TOOLS_CLEAR,
  TOOLS_VIEW_GRAPH,
} from "../types/toolsTypes";

const INITIAL_STATE = {
  tools_menu: [
    {
      id: 1,
      name: "Competencias duras",
    },
    {
      id: 2,
      name: "Competencias blandas",
    },
    {
      id: 3,
      name: "Orientación",
    },
    {
      id: 4,
      name: "Gestión del talento humano",
    },
    {
      id: 5,
      name: "Transversal",
    },
  ],
  tools_menu_select: "",
  tools_docs: [],
  tools_view_docs: [],
  tools_current_page: 1,
  tools_items_per_page: 12,
  tools_view_detail_doc: false,
  tools_search: "",
  tools_detail: [],
  tools_view_graph: true,
};

const toolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOOLS_SELECTED:
      return {
        ...state,
        tools_menu_select: action.payload,
      };
    case TOOLS_CHANGE_PAGE:
      return {
        ...state,
        tools_current_page: action.payload,
      };
    case TOOLS_VIEW_DOCS:
      return {
        ...state,
        tools_view_docs: action.payload,
      };
    case TOOLS_VIEW_DETAIL_DOC:
      return {
        ...state,
        tools_view_detail_doc: action.payload,
      };
    case TOOLS_DATA:
      return {
        ...state,
        tools_docs: action.payload.tools,
        tools_view_docs: action.payload.tools_view,
      };
    case TOOLS_SEARCH:
      return {
        ...state,
        tools_search: action.payload,
        tools_menu_select: "",
        tools_current_page: 1,
      };
    case TOOLS_DETAIL:
      return {
        ...state,
        tools_detail: action.payload,
      };
    case TOOLS_CLEAR:
      return {
        ...state,
        tools_menu_select: "",
        tools_docs: action.payload.tools,
        tools_view_docs: action.payload.tools_view,
        tools_current_page: 1,
        tools_view_detail_doc: false,
        tools_search: "",
        tools_detail: [],
      };
    case TOOLS_VIEW_GRAPH:
      return {
        ...state,
        tools_view_graph: action.payload,
      };
    default:
      return { ...state };
  }
};

export default toolsReducer;

import React from "react";
import { connect } from "react-redux";
import { territoryTabRequest } from "../functionality/actions/territoryActions";

function TerritoryTabContentPrivate(props) {
  const {
    territoryReducer: {
      territory_tab_item_selected,
      territory_request_loading,
    },
    territoryTabRequest,
  } = props;

  return (
    <div className="territoryInfoPage_container mb-4">
      <h1 style={{ color: territory_tab_item_selected.background }}>
        {territory_tab_item_selected.title}
      </h1>
      <h3>
        Esta sección cuenta con información confidencial de algunos programas,
        si desea conocer esta información lo invitamos a pedir acceso aquí y
        pronto se le notificara si es aprobada o no.
      </h3>
      {!territory_request_loading ? (
        <button
          style={{
            backgroundColor: territory_tab_item_selected.background,
            color: territory_tab_item_selected.color,
          }}
          onClick={territoryTabRequest}
          className="territoryInfoPage_btn-request"
        >
          SOLICITAR ACCESO
        </button>
      ) : (
        <button
          style={{
            backgroundColor: territory_tab_item_selected.background,
            color: territory_tab_item_selected.color,
          }}
          className="territoryInfoPage_btn-request"
        >
          ENVIANDO SOLICITUD...
        </button>
      )}
    </div>
  );
}

const mapStateToProps = ({ territoryReducer }) => {
  return {
    territoryReducer,
  };
};

const mapDispatchToProps = {
  territoryTabRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TerritoryTabContentPrivate);

import swal from "sweetalert";
import Api from "../Api";
import {
  TERRITORY_MODAL,
  TERRITORY_VIEW_COMPONENT,
  TERRITORY_TITLE_COMPONENT,
  TERRITORY_CLEAR_STAGES,
  TERRITORY_DATA_STAGES,
  TERRITORY_MAP,
  TERRITORY_IFRAME,
  TERRITORY_CLEAR,
  TERRITORY_REDIRECT,
  TERRITORY_TYPE,
  TERRITORY_LOADING_DATA,
  TERRITORY_DATA,
  TERRITORY_DATA_SELECTED,
  TERRITORY_LOADING_PROGRAMS,
  TERRITORY_PROGRAM,
  TERRITORY_PROGRAMS,
  TERRITORY_INDEX,
  TERRITORY_REQUEST_LOADING,
  TERRITORY_TAB_LOADING,
  TERRITORY_TABS,
  TERRITORY_TAB_ITEMS,
  TERRITORY_TAB_SELECTED_ITEMS,
  TERRITORY_TAB_CONTENTS,
  TERRITORY_TAB_SELECTED,
  TERRITORY_TAB_ITEM_SELECTED,
  TERRITORY_TAB_CONTENTS_GRAPH,
  TERRITORY_TAB_CONTENTS_PDF,
  TERRITORY_TOOLTIP,
} from "../types/territoryTypes";

export const openCloseModal = (status) => (dispatch) => {
  if (status === "active") {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  dispatch({
    type: TERRITORY_MODAL,
    payload: status,
  });
};

export const territoryViewComponent =
  (status, title, type, loadTime) => (dispatch, getState) => {
    const { other_contents } = getState().defaultReducer;
    let source = other_contents.filter((item) => item.label === type);
    source =
      source.length > 0 && source[0].value && source[0].value !== ""
        ? source[0].value.split(";")
        : [];
    dispatch({
      type: TERRITORY_VIEW_COMPONENT,
      payload: status,
    });
    dispatch({
      type: TERRITORY_TITLE_COMPONENT,
      payload: title,
    });
    dispatch({
      type: TERRITORY_IFRAME,
      payload: source,
    });
    if (status) {
      const element = document.getElementById("territory-component");
      if (loadTime) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 500);
      } else {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

export const territoryClearStages = () => (dispatch) => {
  dispatch({
    type: TERRITORY_CLEAR_STAGES,
  });
};

export const territoryClear = () => (dispatch) => {
  dispatch({
    type: TERRITORY_CLEAR,
  });
};

export const territoryDataStages = (cod_city) => (dispatch, getState) => {
  const { territories, regions, cities } = getState().defaultReducer;
  const element = document.getElementById("territory-stages");
  let national = territories.filter((item) => item.codigo_dane == null);
  let national_half = national.filter((item) => item.tramo === "T1");
  let national_postmedia = national.filter((item) => item.tramo === "T2");
  let national_intermediation = national.filter((item) => item.tramo === "T3");
  let national_job = national.filter((item) => item.tramo === "T4");
  let country_half = "";
  let city_half = "";
  let country_postmedia = "";
  let city_postmedia = "";
  let country_intermediation = "";
  let city_intermediation = "";
  let country_job = "";
  let city_job = "";
  let map = "";
  if (national_half.length > 0) {
    country_half = 0;
    national_half.map((item) => {
      country_half += item.valor;
      return null;
    });
  }
  if (national_postmedia.length > 0) {
    country_postmedia = 0;
    national_postmedia.map((item) => {
      country_postmedia += item.valor;
      return null;
    });
  }
  if (national_intermediation.length > 0) {
    country_intermediation = 0;
    national_intermediation.map((item) => {
      country_intermediation += item.valor;
      return null;
    });
  }
  if (national_job.length > 0) {
    country_job = 0;
    national_job.map((item) => {
      country_job += item.valor;
      return null;
    });
  }
  if (cod_city) {
    let city = cities.filter((item) => item.cod_city === cod_city);
    let region = regions.filter((item) => item.id === city[0].cod_region);
    map = region[0].image;
    let city_info = territories.filter((item) => item.codigo_dane === cod_city);
    let data_city_half = city_info.filter((item) => item.tramo === "T1");
    let data_city_postmedia = city_info.filter((item) => item.tramo === "T2");
    let data_city_intermediation = city_info.filter(
      (item) => item.tramo === "T3"
    );
    let data_city_job = city_info.filter((item) => item.tramo === "T4");
    if (data_city_half.length > 0) {
      city_half = 0;
      data_city_half.map((item) => {
        city_half += item.valor;
        return null;
      });
    }
    if (data_city_postmedia.length > 0) {
      city_postmedia = 0;
      data_city_postmedia.map((item) => {
        city_postmedia += item.valor;
        return null;
      });
    }
    if (data_city_intermediation.length > 0) {
      city_intermediation = 0;
      data_city_intermediation.map((item) => {
        city_intermediation += item.valor;
        return null;
      });
    }
    if (data_city_job.length > 0) {
      city_job = 0;
      data_city_job.map((item) => {
        city_job += item.valor;
        return null;
      });
    }
    element.scrollIntoView();
  }
  let data = {
    country_half,
    city_half,
    country_postmedia,
    city_postmedia,
    country_intermediation,
    city_intermediation,
    country_job,
    city_job,
  };
  dispatch({
    type: TERRITORY_DATA_STAGES,
    payload: data,
  });
  dispatch({
    type: TERRITORY_MAP,
    payload: map,
  });
};

export const territoryRedirect = (payload) => (dispatch) => {
  dispatch({
    type: TERRITORY_REDIRECT,
    payload,
  });
};

export const selectTerritoryData = (index) => (dispatch, getState) => {
  const { territory_data } = getState().territoryReducer;

  dispatch({
    type: TERRITORY_INDEX,
    payload: index,
  });
  dispatch({
    type: TERRITORY_DATA_SELECTED,
    payload: territory_data[index],
  });
};

export const selectTerritoryProgram = (index) => (dispatch, getState) => {
  const { territory_programs } = getState().territoryReducer;

  dispatch({
    type: TERRITORY_INDEX,
    payload: index,
  });
  dispatch({
    type: TERRITORY_PROGRAM,
    payload: territory_programs[index],
  });
};

const territoryData = () => (dispatch, getState) => {
  const { territory_data } = getState().territoryReducer;

  dispatch({
    type: TERRITORY_LOADING_DATA,
    payload: true,
  });

  if (territory_data) {
    setTimeout(() => {
      dispatch({
        type: TERRITORY_LOADING_DATA,
        payload: false,
      });
    }, 500);
    dispatch({
      type: TERRITORY_DATA,
      payload: territory_data,
    });
    setTimeout(() => {
      dispatch(selectTerritoryData(0));
    }, 100);
  } else {
    Api.callMethod(
      "data-territories",
      "GET",
      null,
      (res) => {
        if (res.code === 200) {
          setTimeout(() => {
            dispatch({
              type: TERRITORY_LOADING_DATA,
              payload: false,
            });
          }, 500);
          dispatch({
            type: TERRITORY_DATA,
            payload: res.data,
          });
          setTimeout(() => {
            dispatch(selectTerritoryData(0));
          }, 100);
          return;
        }
        dispatch(territoryData());
      },
      (error) => {
        console.log("Error territoryData: ", error);
        dispatch(territoryData());
      }
    );
  }
};

const territoryPrograms = () => (dispatch, getState) => {
  const { territory_programs } = getState().territoryReducer;

  dispatch({
    type: TERRITORY_LOADING_PROGRAMS,
    payload: true,
  });

  if (territory_programs) {
    setTimeout(() => {
      dispatch({
        type: TERRITORY_LOADING_PROGRAMS,
        payload: false,
      });
    }, 500);
    dispatch({
      type: TERRITORY_PROGRAMS,
      payload: territory_programs,
    });
    setTimeout(() => {
      dispatch(selectTerritoryProgram(0));
    }, 100);
  } else {
    Api.callMethod(
      "auth/territory-programs",
      "GET",
      null,
      (res) => {
        if (res.code === 200) {
          setTimeout(() => {
            dispatch({
              type: TERRITORY_LOADING_PROGRAMS,
              payload: false,
            });
          }, 500);
          dispatch({
            type: TERRITORY_PROGRAMS,
            payload: res.data,
          });
          setTimeout(() => {
            dispatch(selectTerritoryProgram(0));
          }, 100);
          return;
        }
        dispatch(territoryData());
      },
      (error) => {
        console.log("Error territoryData: ", error);
        dispatch(territoryData());
      }
    );
  }
};

export const territoryType = (payload) => (dispatch, getState) => {
  const { user } = getState().userReducer;

  if (payload === "programas") {
    if (!user) {
      setTimeout(() => {
        dispatch(territoryRedirect(true));
      }, 250);
      return;
    }
    dispatch(territoryPrograms());
  } else {
    dispatch(territoryRedirect(false));
    dispatch(territoryData());
  }

  dispatch({
    type: TERRITORY_TYPE,
    payload,
  });
};

export const territoryProgramRequest = () => (dispatch, getState) => {
  const { territory_program } = getState().territoryReducer;

  dispatch({
    type: TERRITORY_REQUEST_LOADING,
    payload: true,
  });

  Api.callMethod(
    `auth/territory-programs/${territory_program.id}/request`,
    "POST",
    null,
    (res) => {
      swal("¡Grandioso!", "La solicitud ha sido enviada.", "success").then(
        () => {
          dispatch({
            type: TERRITORY_REQUEST_LOADING,
            payload: false,
          });
        }
      );
    },
    (error) => {
      console.log("Error territoryProgramRequest: ", error);
    }
  );
};

export const territoryTabRequest = () => (dispatch, getState) => {
  const { territory_tab_item_selected } = getState().territoryReducer;

  dispatch({
    type: TERRITORY_REQUEST_LOADING,
    payload: true,
  });

  Api.callMethod(
    `auth/tabs/${territory_tab_item_selected.id}/request`,
    "POST",
    null,
    (res) => {
      swal("¡Grandioso!", "La solicitud ha sido enviada.", "success").then(
        () => {
          dispatch({
            type: TERRITORY_REQUEST_LOADING,
            payload: false,
          });
        }
      );
    },
    (error) => {
      console.log("Error territoryProgramRequest: ", error);
    }
  );
};

export const changeTerrituryTabLoading = (payload) => (dispatch) => {
  dispatch({
    type: TERRITORY_TAB_LOADING,
    payload,
  });
};

export const tabItemContentsView = (tab_item) => (dispatch, getState) => {
  const { territory_tab_selected_items, territory_tab_contents } =
    getState().territoryReducer;
  let tabItem = null;
  if (!tab_item || tab_item === undefined || tab_item === "undefined") {
    tabItem = territory_tab_selected_items[0];
  } else {
    tabItem = territory_tab_selected_items.filter(
      (item) => item.slug === tab_item
    );
    tabItem = tabItem[0];
  }

  if (tabItem) {
    const contents = territory_tab_contents.filter(
      (item) => parseInt(item.tab_item_id) === parseInt(tabItem.id)
    );
    const graphs = contents.filter((item) => parseInt(item.type) === 1);
    const pdfs = contents.filter((item) => parseInt(item.type) === 2);

    dispatch({
      type: TERRITORY_TAB_CONTENTS_GRAPH,
      payload: graphs,
    });
    dispatch({
      type: TERRITORY_TAB_CONTENTS_PDF,
      payload: pdfs,
    });
  }

  dispatch({
    type: TERRITORY_TAB_ITEM_SELECTED,
    payload: tabItem,
  });
};

export const tabSelected = (tab, tab_item) => (dispatch, getState) => {
  const { territory_tabs, territory_tab_items } = getState().territoryReducer;
  let tab_selected = null;

  if (!tab || tab === undefined || tab === "undefined") {
    tab_selected = territory_tabs[0];
  } else {
    tab_selected = territory_tabs.filter((item) => item.slug === tab);
    tab_selected = tab_selected[0];
  }

  if (tab_selected) {
    const items = territory_tab_items.filter(
      (item) => parseInt(item.tab_id) === parseInt(tab_selected.id)
    );
    dispatch({
      type: TERRITORY_TAB_SELECTED_ITEMS,
      payload: items,
    });
  }

  dispatch({
    type: TERRITORY_TAB_SELECTED,
    payload: tab_selected,
  });
  dispatch(tabItemContentsView(tab_item));
  dispatch(changeTerrituryTabLoading(false));
};

export const getTabs = (tab, tab_item) => (dispatch) => {
  Api.callMethod(
    `tabs`,
    "GET",
    null,
    (res) => {
      if (res.code === 200) {
        dispatch({
          type: TERRITORY_TABS,
          payload: res.data.tabs,
        });
        dispatch({
          type: TERRITORY_TAB_ITEMS,
          payload: res.data.tabItems,
        });
        dispatch({
          type: TERRITORY_TAB_CONTENTS,
          payload: res.data.tabContents,
        });
        dispatch(tabSelected(tab, tab_item));
      } else {
        dispatch(changeTerrituryTabLoading(false));
      }
    },
    (error) => {
      console.log("Error getTabs: ", error);
      dispatch(changeTerrituryTabLoading(false));
    }
  );
};

export const territoryTooltip = (payload) => (dispatch) => {
  dispatch({
    type: TERRITORY_TOOLTIP,
    payload,
  });
};

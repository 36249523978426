export const getTitle = (list, label) => {
  const title = list.filter((item) => item.label === label);
  return title.length ? title[0].value : "";
};

export const getIcon = (list, label) => {
  const icon = list.filter((item) => item.value === label);
  return icon.length
    ? `<img src='${icon[0].icon}' alt='${icon[0].name}'/>`
    : "";
};

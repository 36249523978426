export const DOCS_DATA = "DOCS_DATA";
export const DOCS_CHANGE_PAGE = "DOCS_CHANGE_PAGE";
export const DOCS_CHANGE_LIST_VIEW = "DOCS_CHANGE_LIST_VIEW";
export const DOCS_VIEW_DETAIL = "DOCS_VIEW_DETAIL";
export const DOCS_DETAIL = "DOCS_DETAIL";
export const DOCS_SEARCH = "DOCS_SEARCH";
export const DOCS_POPULATION = "DOCS_POPULATION";
export const DOCS_SECTIONS = "DOCS_SECTIONS";
export const DOCS_COMPONENTS = "DOCS_COMPONENTS";
export const DOCS_DRIVERS = "DOCS_DRIVERS";
export const DOCS_ACTION_LEVELS = "DOCS_ACTION_LEVELS";
export const DOCS_TERRITORIES = "DOCS_TERRITORIES";
export const DOCS_CLEAR = "DOCS_CLEAR";
export const DOCS_CLEAR_FILTERS = "DOCS_CLEAR_FILTERS";
export const DOCS_VIEW_GRAPH = "DOCS_VIEW_GRAPH";
export const DOCS_LIST_TYPE = "DOCS_LIST_TYPE";
export const DOCS_AUTHOR = "DOCS_AUTHOR";
export const DOCS_YEAR = "DOCS_YEAR";

import React, { Component } from 'react'
import LayoutDefault from './layouts/LayoutDefault'
import { connect } from 'react-redux';
import './css/NewsPage.css';
import Error404 from '../components/Error404';

class NewsDetailPage extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    const {
      defaultReducer: { allNews },
      match: { params: { id } }
    } = this.props;
    let news = allNews.filter(item => item.id === parseInt(id));

    return(
      <LayoutDefault>
        {!news.length || !news[0].description.length ?
          <Error404 />
          :
          <>
            <div className="news_detail-header">
              <div className="slider_overlay">
                <div className="slider_content">
                  <div className="slider_title">{news[0].title}</div>
                </div>
              </div>
              <img src={news[0].coverpage} alt={news[0].title}/>
            </div>
            <div className="news_detail-content"
              dangerouslySetInnerHTML={{__html: news[0].description}}
            />
          </>
        }
      </LayoutDefault>
    )
  }
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  }
}

export default connect(mapStateToProps, null)(NewsDetailPage);
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
import image from "../images/bg-documents.jpg";
import DocDetail from "../components/DocDetail";
import MostRecent from "../components/MostRecent";
import BoxSearch from "../components/BoxSearch";
import Pagination from "../components/Pagination";
import DocsList from "../components/DocsList";
import * as docsActions from "../functionality/actions/docsActions";
import * as indexActions from "../functionality/actions/indexActions";
import FilterSelect from "../components/FilterSelect";
import SectionSlider from "../components/SectionSlider";
import Counter from "../components/Counter";
import ButtonGraph from "../components/ButtonGraph";
import FilterItem from "../components/FilterItem";

const {
  docsChangePage,
  docsViewDetail,
  docsSearch,
  docsFilters,
  docsClear,
  docsViewGraph,
  changeDocsListType,
  docFilterRemovePopulation,
  docFilterRemoveSection,
  docFilterRemoveComponent,
  docFilterRemoveDriver,
  docFilterRemoveTerritory,
  docFilterRemoveActionLevel,
  docFilterRemoveAuthor,
  docFilterRemoveYear,
} = docsActions;

const { changeMenu } = indexActions;
class DocumentsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { docsViewDetail } = this.props;
    if (this.props.match.params.id) {
      docsViewDetail(true, this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { docsViewDetail } = this.props;
    if (!this.props.match.params.id && prevProps.match.params.id) {
      window.scrollTo(0, 0);
      docsViewDetail(false, "");
    }
    if (
      this.props.match.params.id &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      docsViewDetail(true, this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    const { docsClear, changeMenu, docsViewGraph } = this.props;
    changeMenu("");
    docsClear();
    docsViewGraph(true);
  }

  _renderGraphs = (value) => {
    let sources = value.split(";");
    let response = sources.map((item, index) => {
      item = item.trim(";");
      if (item.length > 0) {
        return (
          <div className="component-content" key={index}>
            <iframe src={item} title="Gráfico Documentos Técnicos" />
          </div>
        );
      }
      return null;
    });

    return response;
  };

  render() {
    const {
      docs_list,
      docs_current_page,
      docs_items_per_page,
      docs_list_view,
      docs_view_detail,
      docs_detail,
      docs_search,
      docs_view_graph,
      docs_list_type,
      docs_population_select,
      docs_sections_select,
      docs_components_select,
      docs_drivers_select,
      docs_territories_select,
      docs_action_levels_select,
      docs_author_select,
      docs_year_select,
    } = this.props.docsReducer;
    const {
      filter_population,
      filter_sections,
      filter_components,
      filter_drivers,
      cities,
      filter_action_levels,
      other_contents,
      document_titles,
      sliders,
      documents,
      authors,
      years,
    } = this.props.defaultReducer;
    const {
      docsChangePage,
      docsSearch,
      docsFilters,
      docsViewGraph,
      changeDocsListType,
      docFilterRemovePopulation,
      docFilterRemoveSection,
      docFilterRemoveComponent,
      docFilterRemoveDriver,
      docFilterRemoveTerritory,
      docFilterRemoveActionLevel,
      docFilterRemoveAuthor,
      docFilterRemoveYear,
    } = this.props;
    let document = other_contents.filter(
      (item) => item.label === "documentos_tecnicos"
    );
    let document_cities = cities.filter((item) => item.is_document === 1);
    let slider = sliders.filter((item) => item.name === "documentos_tecnicos");
    let graphic = other_contents.filter(
      (item) => item.label.indexOf("documentos_grafico") >= 0
    );

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div
                  className="title-section"
                  dangerouslySetInnerHTML={{
                    __html: document[0].title.replace(" ", "</br>"),
                  }}
                />
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-green"
              dangerouslySetInnerHTML={{ __html: document[0].value }}
            />
          </div>
        </div>
        {graphic.length > 0 && graphic[1].value && graphic[1].value !== "" && (
          <div className="">
            {graphic[0].value && graphic[0].value !== "" && (
              <>
                <ButtonGraph
                  background="#8C8536"
                  name={graphic[0].value}
                  action={() => docsViewGraph(!docs_view_graph)}
                  status={docs_view_graph}
                />
                {docs_view_graph && this._renderGraphs(graphic[1].value)}
              </>
            )}
          </div>
        )}
        <Counter
          title="Contador de documentos publicados"
          background="#8C8536"
          total={documents.length}
        />
        <div className="section-content" id="docs-container">
          <BoxSearch
            placeholder="BUSCADOR PALABRAS CLAVES"
            value={docs_search}
            onChange={docsSearch}
          />
          <div className="docs-container-filter">
            <FilterSelect
              title={document_titles[0].value}
              options={filter_population}
              value=""
              action={docsFilters}
              type="population"
            />
            <FilterSelect
              title={document_titles[1].value}
              options={filter_sections}
              value=""
              action={docsFilters}
              type="sections"
            />
            <FilterSelect
              title={document_titles[2].value}
              options={filter_components}
              value=""
              action={docsFilters}
              type="components"
            />
            <FilterSelect
              title={document_titles[3].value}
              options={filter_drivers}
              value=""
              action={docsFilters}
              type="driver"
            />
            <FilterSelect
              title={document_titles[4].value}
              options={document_cities}
              value=""
              action={docsFilters}
              type="territories"
            />
            <FilterSelect
              title={document_titles[5].value}
              options={filter_action_levels}
              value=""
              action={docsFilters}
              type="action_levels"
            />
          </div>
          <div className="docs-container-filter-2">
            <FilterSelect
              title="AUTORES"
              options={authors}
              value=""
              action={docsFilters}
              type="author"
            />
            <FilterSelect
              title="AÑO"
              options={years}
              value=""
              action={docsFilters}
              type="year"
            />
          </div>
          <div className="InitiativeFilters_filters-list">
            {docs_population_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemovePopulation}
                color="#8c8536"
              />
            ))}
            {docs_sections_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveSection}
                color="#8c8536"
              />
            ))}
            {docs_components_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveComponent}
                color="#8c8536"
              />
            ))}
            {docs_drivers_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveDriver}
                color="#8c8536"
              />
            ))}
            {docs_territories_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveTerritory}
                color="#8c8536"
              />
            ))}
            {docs_action_levels_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveActionLevel}
                color="#8c8536"
              />
            ))}
            {docs_author_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveAuthor}
                color="#8c8536"
              />
            ))}
            {docs_year_select.map((item, index) => (
              <FilterItem
                key={index}
                index={index}
                name={item.name}
                remove={docFilterRemoveYear}
                color="#8c8536"
              />
            ))}
          </div>
          {docs_search.length >= 4 && (
            <div className="tools-search">
              Resultados de la busqueda: {docs_search}
            </div>
          )}
          {docs_list.length > 0 ? (
            <Fragment>
              <DocsList
                data={docs_list_view}
                listType={docs_list_type}
                changeType={changeDocsListType}
              />
              <Pagination
                all_data={docs_list}
                items_per_page={docs_items_per_page}
                current_page={docs_current_page}
                change_page={docsChangePage}
              />
            </Fragment>
          ) : (
            <div className="section-content">
              <h3 className="text-center">NO HAY ARCHIVOS DISPONIBLES</h3>
            </div>
          )}
        </div>
        <div id="doc-detail">
          {docs_view_detail && docs_detail.length > 0 ? (
            <DocDetail file={docs_detail} section="DOCUMENTOS TÉCNICOS" />
          ) : (
            docs_view_detail && (
              <h3 className="text-center">EL ARCHIVO NO ESTÁ DISPONIBLE</h3>
            )
          )}
        </div>
        <MostRecent title="LO MÁS RECIENTE" label="documentos_tecnicos" />
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ docsReducer, defaultReducer }) => {
  return {
    docsReducer,
    defaultReducer,
  };
};

const mapDispatchToProps = {
  docsChangePage,
  docsViewDetail,
  docsSearch,
  docsFilters,
  docsClear,
  changeMenu,
  docsViewGraph,
  changeDocsListType,
  docFilterRemovePopulation,
  docFilterRemoveSection,
  docFilterRemoveComponent,
  docFilterRemoveDriver,
  docFilterRemoveTerritory,
  docFilterRemoveActionLevel,
  docFilterRemoveAuthor,
  docFilterRemoveYear,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  userFormType,
  userFormModal,
} from "../functionality/actions/userActions";

function TerritoryTabs(props) {
  const {
    territoryReducer: { territory_tabs },
    userReducer: { user },
    match: {
      params: { tab: tab_select },
    },
    userFormType,
    userFormModal,
  } = props;

  if (!territory_tabs.length) {
    return <h3 className="text-center mb-4">No hay contenidos que mostrar.</h3>;
  }

  return (
    <div className="territoryInfoPage_menu-container">
      <div className="territoryInfoPage_menu">
        {territory_tabs.map((tab, index) => {
          let classMenu = tab.slug === tab_select ? "active" : "";
          if (!tab_select) {
            classMenu = index === 0 ? "active" : "";
          }
          if (user || !parseInt(tab.is_private)) {
            return (
              <Link
                key={tab.id}
                to={`/territorio/pestana/${tab.slug}`}
                className={classMenu}
              >
                {tab.title}
              </Link>
            );
          } else {
            return (
              <button
                onClick={() => {
                  userFormType("login");
                  userFormModal("visible");
                }}
                key={tab.id}
              >
                {tab.title}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
}

const mapStateToProps = ({ territoryReducer, userReducer }) => {
  return {
    territoryReducer,
    userReducer,
  };
};
const mapDispatchToProps = {
  userFormType,
  userFormModal,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TerritoryTabs)
);

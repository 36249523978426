import React from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import TerritoryContent from "./TerritoryContent";
import TerritoryMenu from "./TerritoryMenu";
import { selectTerritoryData } from "../functionality/actions/territoryActions";

function TerritoryData(props) {
  const {
    territoryReducer: {
      territory_loading_data,
      territory_data,
      territory_data_selected,
      territory_index,
    },
    selectTerritoryData,
  } = props;

  if (territory_loading_data || !territory_data) {
    return (
      <>
        <br />
        <Loading />
      </>
    );
  }

  return (
    <>
      <TerritoryMenu
        menu={territory_data}
        selected={territory_data_selected}
        onChange={selectTerritoryData}
        value={territory_index}
      />
      <TerritoryContent information={territory_data_selected} />
    </>
  );
}

const mapStateToProps = ({ territoryReducer }) => {
  return {
    territoryReducer,
  };
};

const mapDispatchProps = {
  selectTerritoryData,
};

export default connect(mapStateToProps, mapDispatchProps)(TerritoryData);

import React from 'react';
import map from '../images/map.png';
import './css/TerritoryMap.css';

function TerritoryMap(props) {
  return (
    <div className="map-content">
      {props.map === "" ?
        <img src={map} alt="Mapa"/>
        :
        <img src={props.map} alt="Mapa"/>
      }
    </div>
  )
}

export default TerritoryMap;
import React from 'react';
import ReactDOM from 'react-dom';
import './css/ModalTutorial.css';
import arrow from '../images/intro-arrow.png';
import { connect } from 'react-redux';

function ModalTutorial(props) {
  const {
    defaultReducer: { texts, images }
  } = props;
  let modalTexts = texts.filter(item => (item.label.indexOf('territorio_tutorial') >= 0));
  let modalImages = images.filter(item => (item.label.indexOf('territorio') >= 0));

  return ReactDOM.createPortal(
    <div className={`modal-tutorial-content ${props.visible}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tutorial-container">
              <div className="tutorial-close" onClick={() => props.openCloseModal('')}>
                X
              </div>
              <div className="tutorial-number tutorial-step4">1.</div>
              <div className="tutorial-footer">
                <img src={modalImages[0].image} alt={modalImages[0].label} />
                <div className="intro-txt">
                  {modalTexts[0].value}
                </div>
              </div>
              <div className="tutorial-header">
                <div className="tutorial-col-01">
                  <div className="tutorial-number">2.</div>
                  <img src={modalImages[1].image} alt={modalImages[1].label} className="intro-table"/>
                  <div className="intro-txt">
                    {modalTexts[1].value}
                  </div>
                </div>
                <div className="tutorial-col-02">
                  <div className="tutorial-number">3.</div>
                  <div className="tutorial-step02">
                    <img src={modalImages[2].image} alt={modalImages[2].label} className="intro-table"/>
                    <img src={arrow} alt="Arrow" className="intro-arrow"/>
                    <div className="intro-txt intro-txt-step02">
                      {modalTexts[2].value}
                    </div>
                  </div>
                  <div className="intro-txt intro-txt-02">
                    {modalTexts[3].value}
                  </div>
                </div>
                <div className="tutorial-col-02">
                  <div className="tutorial-number">4.</div>
                  <img src={modalImages[3].image} alt={modalImages[3].label} className="intro-box"/>
                  <div className="intro-txt intro-txt-04">
                    {modalTexts[4].value}
                  </div>
                </div>
              </div>
              <div className="tutorial-end">
                <div className="tutorial-number tutorial-step5">5.</div>
                <img src={modalImages[4].image} alt={modalImages[4].label} />
                <div className="intro-txt">
                  {modalTexts[5].value}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

const mapStateToProps = ({defaultReducer}) => {
  return {
    defaultReducer,
  }
}

export default connect(mapStateToProps, null)(ModalTutorial);
import React from "react";
import { Link } from "react-router-dom";
import "./css/DocItem.css";

function DocItem(props) {
  const { data, link, listType } = props;
  let slug = data.slug ? data.slug : data.id;
  const contentClass =
    !listType || listType === "icon" ? "doc-item-content" : "doc-item-list";

  return (
    <div className={contentClass}>
      <div className="doc-item-box">
        {listType === "icon" && (
          <div className="doc-item-coverpage">
            {data.coverpage && data.coverpage !== "" && (
              <img src={data.coverpage} alt="coverpage" />
            )}
          </div>
        )}
        <div className="doc-item-description">{data.title}</div>
        <Link className="doc-item-button" to={`${link}/${slug}`}>
          VER MÁS
        </Link>
      </div>
    </div>
  );
}

export default DocItem;

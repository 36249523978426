export const DEFAULT_CITIES = "DEFAULT_CITIES";
export const DEFAULT_REGIONS = "DEFAULT_REGIONS";
export const DEFAULT_DOCUMENTS = "DEFAULT_DOCUMENTS";
export const DEFAULT_TOOLS = "DEFAULT_TOOLS";
export const DEFAULT_ECOSYSTEMS = "DEFAULT_ECOSYSTEMS";
export const DEFAULT_TERRITORIES = "DEFAULT_TERRITORIES";
export const DEFAULT_OTHER_DOCUMENTS = "DEFAULT_OTHER_DOCUMENTS";
export const DEFAULT_OTHER_CONTENTS = "DEFAULT_OTHER_CONTENTS";
export const DEFAULT_MOST_RECENTS = "DEFAULT_MOST_RECENTS";
export const DEFAULT_CONTENTS = "DEFAULT_CONTENTS";
export const DEFAULT_EMAIL_NEWSLETTER = "DEFAULT_EMAIL_NEWSLETTER";
export const DEFAULT_NEWSLETTER_MESSAGE = "DEFAULT_NEWSLETTER_MESSAGE";
export const DEFAULT_NEWSLETTER_STATUS = "DEFAULT_NEWSLETTER_STATUS";
export const DEFAULT_SEARCH = "DEFAULT_SEARCH";
export const DEFAULT_SEARCH_DATA = "DEFAULT_SEARCH_DATA";
export const DEFAULT_INDEX_CLEAR = "DEFAULT_INDEX_CLEAR";
export const DEFAULT_SEARCH_CLEAR = "DEFAULT_SEARCH_CLEAR";
export const DEFAULT_SEARCH_STATUS = "DEFAULT_SEARCH_STATUS";
export const DEFAULT_MENU = "DEFAULT_MENU";
export const DEFAULT_CATEGORIES = "DEFAULT_CATEGORIES";
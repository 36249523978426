import {
  ECOSYSTEM_SECTIONS,
  ECOSYSTEM_POPULATION,
  ECOSYSTEM_MODELS,
  ECOSYSTEM_DOCS,
  ECOSYSTEM_MODAL,
  ECOSYSTEM_VIEW_GRAPH,
} from "../types/ecosystemsTypes";

export const ecosystemSteps = (cod_city) => (dispatch, getState) => {
  const { ecosystems } = getState().defaultReducer;
  const element = document.getElementById("ecosystems-stages");
  let data = [];
  if (cod_city) {
    data = ecosystems.filter((item) => item.codigo_dane === cod_city);
    element.scrollIntoView();
  } else {
    data = ecosystems.filter(
      (item) => item.codigo_dane === null || item.territorio === "Nacional"
    );
  }
  let ecosystem_harsh = 0;
  let ecosystem_soft = 0;
  let ecosystem_ocupational = 0;
  let ecosystem_specific = 0;
  let ecosystem_general = 0;
  let ecosystem_vocational = 0;
  let ecosystem_intermediation_labor = 0;
  let ecosystem_intermediation_talent = 0;
  let ecosystem_orientation_labor = 0;
  let ecosystem_orientation_talent = 0;
  let ecosystem_total = 0;
  let ecosystem_women = 0;
  let ecosystem_young_boys = 0;
  let ecosystem_disabality = 0;
  let ecosystem_indigenios = 0;
  let ecosystem_afro_descendant = 0;
  let ecosystem_conflict = 0;
  let ecosystem_reinstatement = 0;
  let ecosystem_poverty = 0;
  let ecosystem_half = 0;
  let ecosystem_postmedia = 0;
  let ecosystem_intermediation = 0;
  let ecosystem_job = 0;
  let ecosystem_lgbtiq = 0;

  data.map((item) => {
    if (item.t1c1 === "1") {
      ecosystem_harsh += parseInt(item.t1c1);
    }
    if (item.t1c2 === "1") {
      ecosystem_soft += parseInt(item.t1c2);
    }
    if (item.t1c3 === "1") {
      ecosystem_ocupational += parseInt(item.t1c3);
    }
    if (item.t2c1 === "1") {
      ecosystem_specific += parseInt(item.t2c1);
    }
    if (item.t2c2 === "1") {
      ecosystem_general += parseInt(item.t2c2);
    }
    if (item.t2c3 === "1") {
      ecosystem_vocational += parseInt(item.t2c3);
    }
    if (item.t3c3 === "1") {
      ecosystem_intermediation_labor += parseInt(item.t3c3);
    }
    if (item.t3c4 === "1") {
      ecosystem_intermediation_talent += parseInt(item.t3c4);
    }
    if (item.t4c3 === "1") {
      ecosystem_orientation_labor += parseInt(item.t4c3);
    }
    if (item.t4c4 === "1") {
      ecosystem_orientation_talent += parseInt(item.t4c4);
    }
    if (item.mujeres === "1") {
      ecosystem_women += parseInt(item.mujeres);
    }
    if (item.jovenes === "1") {
      ecosystem_young_boys += parseInt(item.jovenes);
    }
    if (item.poblacion_lgbt === "1") {
      ecosystem_lgbtiq += parseInt(item.poblacion_lgbt);
    }
    if (item.poblacion_discapacidad === "1") {
      ecosystem_disabality += parseInt(item.poblacion_discapacidad);
    }
    if (item.poblacion_indigena === "1") {
      ecosystem_indigenios += parseInt(item.poblacion_indigena);
    }
    if (item.poblacion_afrodescendiente === "1") {
      ecosystem_afro_descendant += parseInt(item.poblacion_afrodescendiente);
    }
    if (item.victimas === "1") {
      ecosystem_conflict += parseInt(item.victimas);
    }
    if (item.personas_proceso_reintegracion === "1") {
      ecosystem_reinstatement += parseInt(item.personas_proceso_reintegracion);
    }
    if (item.poblacion_situacion_pobreza === "1") {
      ecosystem_poverty += parseInt(item.poblacion_situacion_pobreza);
    }
    if (item.t1 === "1") {
      ecosystem_half += parseInt(item.t1);
    }
    if (item.t2 === "1") {
      ecosystem_postmedia += parseInt(item.t2);
    }
    if (item.t3 === "1") {
      ecosystem_intermediation += parseInt(item.t3);
    }
    if (item.t4 === "1") {
      ecosystem_job += parseInt(item.t4);
    }
    return null;
  });
  ecosystem_total = data.length;
  let data_sections = {
    ecosystem_harsh,
    ecosystem_soft,
    ecosystem_ocupational,
    ecosystem_specific,
    ecosystem_general,
    ecosystem_vocational,
    ecosystem_intermediation_labor,
    ecosystem_intermediation_talent,
    ecosystem_orientation_labor,
    ecosystem_orientation_talent,
    ecosystem_total,
  };
  let data_population = {
    ecosystem_women,
    ecosystem_young_boys,
    ecosystem_disabality,
    ecosystem_indigenios,
    ecosystem_afro_descendant,
    ecosystem_conflict,
    ecosystem_reinstatement,
    ecosystem_poverty,
    ecosystem_lgbtiq,
  };
  let data_models = {
    ecosystem_half,
    ecosystem_postmedia,
    ecosystem_intermediation,
    ecosystem_job,
  };
  dispatch({
    type: ECOSYSTEM_SECTIONS,
    payload: data_sections,
  });
  dispatch({
    type: ECOSYSTEM_POPULATION,
    payload: data_population,
  });
  dispatch({
    type: ECOSYSTEM_MODELS,
    payload: data_models,
  });
};

export const ecosystemViewDocs =
  (section, title, cod_city) => (dispatch, getState) => {
    const { ecosystems } = getState().defaultReducer;
    let data = [];
    let docs_data = [];
    if (cod_city) {
      data = ecosystems.filter((item) => item.codigo_dane === cod_city);
    } else {
      data = ecosystems.filter(
        (item) => item.codigo_dane === null || item.territorio === "Nacional"
      );
    }
    if (section === "t1") {
      let ecosystem_harsh = data.filter((item) => item.t1c1 === "1");
      let ecosystem_soft = data.filter((item) => item.t1c2 === "1");
      let ecosystem_ocupational = data.filter((item) => item.t1c3 === "1");
      if (ecosystem_harsh.length > 0) {
        docs_data.push({
          title: "Competencias Duras",
          data: ecosystem_harsh,
        });
      }
      if (ecosystem_soft.length > 0) {
        docs_data.push({
          title: "Competencias Blandas",
          data: ecosystem_soft,
        });
      }
      if (ecosystem_ocupational.length > 0) {
        docs_data.push({
          title: "Orientación Socio-Ocupacional",
          data: ecosystem_ocupational,
        });
      }
    }
    if (section === "t2") {
      let ecosystem_specific = data.filter((item) => item.t2c1 === "1");
      let ecosystem_general = data.filter((item) => item.t2c2 === "1");
      let ecosystem_vocational = data.filter((item) => item.t2c3 === "1");
      if (ecosystem_specific.length > 0) {
        docs_data.push({
          title: "Competencias Laborales Específicas",
          data: ecosystem_specific,
        });
      }
      if (ecosystem_general.length > 0) {
        docs_data.push({
          title: "Competencias Laborales Generales",
          data: ecosystem_general,
        });
      }
      if (ecosystem_vocational.length > 0) {
        docs_data.push({
          title: "Orientación Vocacional",
          data: ecosystem_vocational,
        });
      }
    }
    if (section === "t3") {
      let ecosystem_intermediation_labor = data.filter(
        (item) => item.t3c3 === "1"
      );
      let ecosystem_intermediation_talent = data.filter(
        (item) => item.t3c4 === "1"
      );
      if (ecosystem_intermediation_labor.length > 0) {
        docs_data.push({
          title: "Orientación Laboral",
          data: ecosystem_intermediation_labor,
        });
      }
      if (ecosystem_intermediation_talent.length > 0) {
        docs_data.push({
          title: "Gestión del Talento Humano",
          data: ecosystem_intermediation_talent,
        });
      }
    }
    if (section === "t4") {
      let ecosystem_orientation_labor = data.filter(
        (item) => item.t4c3 === "1"
      );
      let ecosystem_orientation_talent = data.filter(
        (item) => item.t4c4 === "1"
      );
      if (ecosystem_orientation_labor.length > 0) {
        docs_data.push({
          title: "Orientación Laboral",
          data: ecosystem_orientation_labor,
        });
      }
      if (ecosystem_orientation_talent.length > 0) {
        docs_data.push({
          title: "Gestión del Talento Humano",
          data: ecosystem_orientation_talent,
        });
      }
    }
    if (section === "population") {
      let ecosystem_women = data.filter((item) => item.mujeres === "1");
      let ecosystem_young_boys = data.filter((item) => item.Jóvenes === "1");
      let ecosystem_disabality = data.filter(
        (item) => item.poblacion_discapacidad === "1"
      );
      let ecosystem_indigenios = data.filter(
        (item) => item.poblacion_indigena === "1"
      );
      let ecosystem_afro_descendant = data.filter(
        (item) => item.poblacion_afrodescendiente === "1"
      );
      let ecosystem_conflict = data.filter((item) => item.victimas === "1");
      let ecosystem_reinstatement = data.filter(
        (item) => item.personas_proceso_reintegracion === "1"
      );
      let ecosystem_poverty = data.filter(
        (item) => item.poblacion_situacion_pobreza === "1"
      );
      if (ecosystem_women.length > 0) {
        docs_data.push({
          title: "Mujeres",
          data: ecosystem_women,
        });
      }
      if (ecosystem_young_boys.length > 0) {
        docs_data.push({
          title: "Jóvenes",
          data: ecosystem_young_boys,
        });
      }
      if (ecosystem_disabality.length > 0) {
        docs_data.push({
          title: "Población con discapacidad",
          data: ecosystem_disabality,
        });
      }
      if (ecosystem_indigenios.length > 0) {
        docs_data.push({
          title: "Población indígena",
          data: ecosystem_indigenios,
        });
      }
      if (ecosystem_afro_descendant.length > 0) {
        docs_data.push({
          title: "Población afrodescendiente",
          data: ecosystem_afro_descendant,
        });
      }
      if (ecosystem_conflict.length > 0) {
        docs_data.push({
          title: "Víctimas del conflicto",
          data: ecosystem_conflict,
        });
      }
      if (ecosystem_reinstatement.length > 0) {
        docs_data.push({
          title: "Población en proceso de reintegración",
          data: ecosystem_reinstatement,
        });
      }
      if (ecosystem_poverty.length > 0) {
        docs_data.push({
          title: "Población en condición de pobreza",
          data: ecosystem_poverty,
        });
      }
    }
    let docs = {
      docs: docs_data,
      title,
    };
    dispatch({
      type: ECOSYSTEM_DOCS,
      payload: docs,
    });
    const element = document.getElementById("ecosystems-docs");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

export const openCloseModal = (status) => (dispatch) => {
  if (status === "active") {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  dispatch({
    type: ECOSYSTEM_MODAL,
    payload: status,
  });
};

export const ecosystemViewGraph = (payload) => (dispatch) => {
  dispatch({
    type: ECOSYSTEM_VIEW_GRAPH,
    payload,
  });
};

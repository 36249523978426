import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  getTabs,
  tabSelected,
  tabItemContentsView,
} from "../functionality/actions/territoryActions";
import image from "../images/bg-territory.jpg";
import LayoutDefault from "./layouts/LayoutDefault";
import SectionSlider from "../components/SectionSlider";
import TerritoryTabs from "../components/TerritoryTabs";
import TerritorySubTabs from "../components/TerritorySubTabs";
import TerritoryTabContent from "../components/TerritoryTabContent";
import Loading from "../components/Loading";
import TerritoryTooltip from "../components/TerritoryTooltip";

class TerritoryTabPage extends Component {
  componentDidMount() {
    const {
      territoryReducer: { territory_tab_loading },
      match: {
        params: { tab, tab_item },
      },
      getTabs,
    } = this.props;
    if (territory_tab_loading) {
      getTabs(tab, tab_item);
    }
  }

  componentDidUpdate(oldProps) {
    const {
      match: {
        params: { tab: tab_old, tab_item: tab_item_old },
      },
    } = oldProps;
    const {
      match: {
        params: { tab, tab_item },
      },
      tabSelected,
      tabItemContentsView,
    } = this.props;
    if (tab !== tab_old) {
      tabSelected(tab, tab_item);
    }
    if (tab_item !== tab_item_old) {
      tabItemContentsView(tab_item);
    }
  }

  render() {
    const {
      defaultReducer: { other_contents, sliders },
      territoryReducer: { territory_redirect, territory_tab_loading },
    } = this.props;

    if (territory_redirect) {
      return <Redirect to="/territorio/datos" />;
    }

    let slider = sliders.filter((item) => item.name === "datos_territorio");
    let territory = other_contents.filter(
      (item) => item.label === "territorio"
    );

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div
                  className="title-section"
                  dangerouslySetInnerHTML={{
                    __html: territory[0].title.replace(" ", "</br>"),
                  }}
                />
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-purple"
              dangerouslySetInnerHTML={{ __html: territory[0].value }}
            />
            {territory_tab_loading ? (
              <div className="mb-4">
                <Loading />
              </div>
            ) : (
              <>
                <TerritoryTabs />
                <TerritorySubTabs />
                <TerritoryTabContent />
              </>
            )}
          </div>
        </div>
        <TerritoryTooltip />
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ territoryReducer, defaultReducer }) => {
  return { territoryReducer, defaultReducer };
};

const mapDispatchToProps = {
  getTabs,
  tabSelected,
  tabItemContentsView,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryTabPage);

import {
  USER_EMAIL,
  USER_ENTITY,
  USER_FORM_MODAL,
  USER_FORM_TYPE,
  USER_NAME,
  USER_PASSWORD,
  USER_PHONE,
  USER_FORM_CLEAR,
  USER_LOADING,
  USER_DATA,
  USER_TERMS,
} from "../types/userTypes";

const INITIAL_STATE = {
  user: null,
  user_name: "",
  user_email: "",
  user_password: "",
  user_entity: "",
  user_phone: "",
  user_form_type: "",
  user_form_modal: "",
  user_loading: false,
  user_loading_message: "",
  user_terms: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case USER_NAME:
      return {
        ...state,
        user_name: action.payload,
      };
    case USER_EMAIL:
      return {
        ...state,
        user_email: action.payload,
      };
    case USER_PASSWORD:
      return {
        ...state,
        user_password: action.payload,
      };
    case USER_ENTITY:
      return {
        ...state,
        user_entity: action.payload,
      };
    case USER_PHONE:
      return {
        ...state,
        user_phone: action.payload,
      };
    case USER_FORM_TYPE:
      return {
        ...state,
        user_form_type: action.payload,
      };
    case USER_FORM_MODAL:
      return {
        ...state,
        user_form_modal: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        user_loading: action.payload.status,
        user_loading_message: action.payload.message,
      };
    case USER_FORM_CLEAR:
      return {
        ...state,
        user_name: "",
        user_email: "",
        user_password: "",
        user_entity: "",
        user_phone: "",
      };
    case USER_TERMS:
      return {
        ...state,
        user_terms: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;

export const ECOSYSTEM_HARSH = 'ECOSYSTEM_HARSH';
export const ECOSYSTEM_SOFT = 'ECOSYSTEM_SOFT';
export const ECOSYSTEM_OCUPATIONAL = 'ECOSYSTEM_OCUPATIONAL';
export const ECOSYSTEM_SPECIFIC = 'ECOSYSTEM_SPECIFIC';
export const ECOSYSTEM_GENERAL = 'ECOSYSTEM_GENERAL';
export const ECOSYSTEM_VOCATIONAL = 'ECOSYSTEM_VOCATIONAL';
export const ECOSYSTEM_INTERMEDIATION_LABOR = 'ECOSYSTEM_INTERMEDIATION_LABOR';
export const ECOSYSTEM_INTERMEDIATION_TALENT = 'ECOSYSTEM_INTERMEDIATION_TALENT';
export const ECOSYSTEM_ORIENTATION_LABOR = 'ECOSYSTEM_ORIENTATION_LABOR';
export const ECOSYSTEM_ORIENTATION_TALENT = 'ECOSYSTEM_ORIENTATION_TALENT';
export const ECOSYSTEM_CLEAR = 'ECOSYSTEM_CLEAR';
export const ECOSYSTEM_SECTIONS = 'ECOSYSTEM_SECTIONS';
export const ECOSYSTEM_POPULATION = 'ECOSYSTEM_POPULATION';
export const ECOSYSTEM_MODELS = 'ECOSYSTEM_MODELS';
export const ECOSYSTEM_DOCS = 'ECOSYSTEM_DOCS';
export const ECOSYSTEM_MODAL = 'ECOSYSTEM_MODAL';
export const ECOSYSTEM_VIEW_GRAPH = 'ECOSYSTEM_VIEW_GRAPH';
import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import Navigation from "./Navigation";
import reducers from "./functionality/reducers";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/global.css";
import "./css/icons.css";
import "./css/fonts.css";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(reduxThunk));

const store = createStore(
  reducers,
  {},
  applyMiddleware(reduxThunk),
  // enhancer
);

function App() {
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

export default App;

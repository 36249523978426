import React, { Component } from "react";
import { connect } from "react-redux";
import { initiativeChangeWinner } from "../../functionality/actions/initiativeActions";
import "./css/winner.css";

class Winners extends Component {
  componentDidMount() {
    const {
      initiativeReducer: { initiative_winners },
      initiativeChangeWinner,
    } = this.props;

    initiativeChangeWinner(initiative_winners[0].id);
  }

  render() {
    const {
      initiativeReducer: { initiative_winners, initiative_winner },
      initiativeChangeWinner,
    } = this.props;

    return (
      <div className="winner_menu">
        {initiative_winners.map((item) => {
          let active = initiative_winner === item.id ? "active" : "";
          return (
            <button
              className={`winner_button ${active}`}
              key={item.id}
              onClick={() => initiativeChangeWinner(item.id)}
            >
              {item.name}
            </button>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = ({ initiativeReducer }) => {
  return {
    initiativeReducer,
  };
};

const mapDispatchToProps = {
  initiativeChangeWinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Winners);

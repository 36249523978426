import React from 'react';
import './css/Counter.css';

function Counter(props) {
  const {title, background, total} = props;
  return (
    <div>
      <div className="counter_title">{title}</div>
      <div className="bg-gray-gradient counter_container">
        <div style={{backgroundColor: background}} className="counter_box">
          { total }
        </div>
      </div>
    </div>
  )
}

export default Counter;
import React from 'react';
import SearchItem from './SearchItem';

function SearchResult(props) {
  return(
    <div className="col-md-12">
      <h4>{props.data.title}</h4>
      <div className="row">
        {props.data.data.map((item) => {
          return (
            <SearchItem key={item.id} item={item} type={props.data.type}/>
          )
        })}
      </div>
    </div>
  )
}

export default SearchResult;
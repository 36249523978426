import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Forms from "../../components/Forms";

function LayoutDefault(props) {
  return (
    <>
      <Forms />
      <Header />
      <div className="container">{props.children}</div>
      <Footer />
    </>
  );
}

export default LayoutDefault;

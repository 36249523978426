import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import LayoutDefault from "./layouts/LayoutDefault";
import SectionSlider from "../components/SectionSlider";
import image from "../images/bg-territory.jpg";
import "./css/TerritoryInfoPage.css";
import { territoryType } from "../functionality/actions/territoryActions";
import {
  userFormModal,
  userFormType,
} from "../functionality/actions/userActions";
import TerritoryData from "../components/TerritoryData";
import TerritoryProgram from "../components/TerritoryProgram";

class TerritoryInfoPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { type },
      },
      territoryType,
    } = this.props;

    territoryType(type);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { type },
      },
      territoryType,
    } = this.props;
    const {
      match: {
        params: { type: oldType },
      },
    } = prevProps;

    if (type !== oldType) {
      territoryType(type);
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      defaultReducer: { other_contents, sliders, texts },
      territoryReducer: { territory_redirect },
      userReducer: { user },
      match: {
        params: { type },
      },
      userFormModal,
      userFormType,
    } = this.props;

    let eyelash1 = texts.filter(
      (item) => item.label === "territorio_pestana_1"
    );
    eyelash1 = eyelash1.length ? eyelash1[0].value : "DATOS TERRITORIALES";

    let eyelash2 = texts.filter(
      (item) => item.label === "territorio_pestana_2"
    );
    eyelash2 = eyelash2.length ? eyelash2[0].value : "PROGRAMAS";

    if (territory_redirect) {
      return <Redirect to="/territorio/datos" />;
    }

    let territory = other_contents.filter(
      (item) => item.label === "territorio"
    );
    let slider = sliders.filter((item) => item.name === "datos_territorio");
    const statusData = type === "datos" ? "active" : "";
    const statusPrograms = type === "programas" ? "active" : "";

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            {!slider.length ||
            slider[0].is_active !== 1 ||
            !slider[0].slides.length ? (
              <div
                className="bg-section"
                style={{ backgroundImage: `URL(${image})` }}
              >
                <div
                  className="title-section"
                  dangerouslySetInnerHTML={{
                    __html: territory[0].title.replace(" ", "</br>"),
                  }}
                />
              </div>
            ) : (
              <SectionSlider slides={slider[0].slides} />
            )}
            <div
              className="description-section bg-purple"
              dangerouslySetInnerHTML={{ __html: territory[0].value }}
            />
          </div>
        </div>
        <div className="territoryInfoPage_menu">
          <Link to="/territorio/datos" className={statusData}>
            {eyelash1}
          </Link>
          {user ? (
            <Link to="/territorio/programas" className={statusPrograms}>
              {eyelash2}
            </Link>
          ) : (
            <button
              onClick={() => {
                userFormType("login");
                userFormModal("visible");
              }}
            >
              {eyelash2}
            </button>
          )}
        </div>
        <div className="territoryInfoPage_content">
          {type === "datos" ? <TerritoryData /> : <TerritoryProgram />}
        </div>
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ territoryReducer, defaultReducer, userReducer }) => {
  return {
    territoryReducer,
    defaultReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  territoryType,
  userFormModal,
  userFormType,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryInfoPage);

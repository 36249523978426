import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutDefault from "./layouts/LayoutDefault";
// import MostRecent from '../components/MostRecent';
import image from "../images/bg-about.jpg";
import "./css/AboutPage.css";
import * as indexActions from "../functionality/actions/indexActions";
import SectionSlider from "../components/SectionSlider";

const { changeMenu } = indexActions;
class AboutPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { changeMenu } = this.props;
    changeMenu("");
  }

  render() {
    const { other_contents, sliders, texts } = this.props.defaultReducer;
    let what_is_it = other_contents.filter(
      (item) => item.label === "que_es_includere"
    );
    let what_is_it_for = other_contents.filter(
      (item) => item.label === "para_que_sire"
    );
    let objective = other_contents.filter((item) => item.label === "objetivo");
    let model = other_contents.filter((item) => item.label === "que_es_modelo");
    let slider = sliders.filter((item) => item.name === "que_es_includere");

    let home_video = other_contents.filter(
      (item) => item.label === "home_video"
    );
    let video_visible = texts.filter((item) => item.label === "ver_video");

    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            {slider.length &&
            slider[0].is_active === 1 &&
            slider[0].slides.length > 0 ? (
              <>
                <SectionSlider slides={slider[0].slides} />
                <br />
              </>
            ) : (
              <img src={image} alt="About" className="about-bg" />
            )}
          </div>
          <div className="col-md-12">
            {what_is_it[0].title && (
              <div className="about-title">
                {what_is_it[0].icon && (
                  <div className="about-box-circle">
                    <div className="about-circle-title">
                      <span className={what_is_it[0].icon} />
                    </div>
                  </div>
                )}
                <h2>{what_is_it[0].title}</h2>
              </div>
            )}
            <div
              className="about-text"
              dangerouslySetInnerHTML={{ __html: what_is_it[0].value }}
            />
          </div>
          <div className="col-md-12">
            <div className="about-title">
              {what_is_it_for[0].icon && (
                <div className="about-box-circle">
                  <div className="about-circle-title">
                    <span className={what_is_it_for[0].icon} />
                  </div>
                </div>
              )}
              <h2>{what_is_it_for[0].title}</h2>
            </div>
            <div
              className="about-text"
              dangerouslySetInnerHTML={{ __html: what_is_it_for[0].value }}
            />
          </div>
          <div className="col-md-12">
            <div className="about-title">
              {objective[0].icon && (
                <div className="about-box-circle">
                  <div className="about-circle-title">
                    <span className={objective[0].icon} />
                  </div>
                </div>
              )}
              <h2>{objective[0].title}</h2>
            </div>
            <div
              className="about-text"
              dangerouslySetInnerHTML={{ __html: objective[0].value }}
            />
          </div>
          <div className="col-md-12">
            <div className="about-title">
              {model[0].icon && (
                <div className="about-box-circle">
                  <div className="about-circle-title">
                    <span className={model[0].icon} />
                  </div>
                </div>
              )}
              <h2>{model[0].title}</h2>
            </div>
            {video_visible.length &&
              video_visible[0].value &&
              video_visible[0].value.toLowerCase() === "si" && (
                <div className="video-home">
                  {home_video.length > 0 && home_video[0].value && (
                    <div
                      dangerouslySetInnerHTML={{ __html: home_video[0].value }}
                      className="video-box"
                    ></div>
                  )}
                </div>
              )}
            <div
              className="about-text"
              dangerouslySetInnerHTML={{ __html: model[0].value }}
            />
          </div>
        </div>
        {/* <MostRecent
          title="LO MÁS RECIENTE"
          label="quienes_somos"
        /> */}
      </LayoutDefault>
    );
  }
}

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

const mapDispatchToProps = {
  changeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutDefault from './layouts/LayoutDefault';
import image from '../images/bg-contact.png';
import './css/ContactPage.css';
import * as contactActions from '../functionality/actions/contactActions';
import * as indexActions from '../functionality/actions/indexActions';

const {
  contactName, contactInstitution, contactEmail, contactPhone, contactMessage,
  contactSend, contactClear
} = contactActions;

const {changeMenu} = indexActions;

class ContactPage extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  componentWillUnmount() {
    const {changeMenu} = this.props;
    changeMenu('');
  }

  render() {
    const {
      contactName, contactInstitution, contactEmail, contactPhone, contactMessage,
      contactSend
    } = this.props;
    const {
      contact_name, contact_institution, contact_email, contact_phone, contact_message,
      contact_error, contact_success, contact_status,
    } = this.props.contactReducer;
    return (
      <LayoutDefault>
        <div className="row">
          <div className="col-md-12">
            <div className="bg-section contact-bg" style={{backgroundImage: `URL(${image})`}}>
              <div className="title-section">CONTACTO</div>
            </div>
          </div>
          <div className="offset-md-2 col-md-8">
            <div className="contact-form">
              {contact_success !== '' &&
                <div className="alert alert-success" role="alert">
                  {contact_success}
                </div>
              }
              <label>Nombre y Apellidos:</label>
              <input
                type="text" className="form-control" placeholder="Nombre y Apellidos"
                value={contact_name}
                onChange={(input) => contactName(input.target.value)}
              />
              <label>Institución:</label>
              <input
                type="text" className="form-control" placeholder="Institución"
                value={contact_institution}
                onChange={(input) => contactInstitution(input.target.value)}
              />
              <label>Email:</label>
              <input
                type="text" className="form-control" placeholder="demo@demo.com"
                value={contact_email}
                onChange={(input) => contactEmail(input.target.value)}
              />
              <label>Teléfono:</label>
              <input
                type="number" className="form-control" placeholder="Teléfono"
                value={contact_phone}
                onChange={(input) => contactPhone(input.target.value)}
              />
              <label>Mensaje:</label>
              <textarea
                className="form-control" rows="10" placeholder="Mensaje..."
                value={contact_message}
                onChange={(input) => contactMessage(input.target.value)}
              />
              {contact_error !== '' &&
                <div className="alert alert-danger" role="alert">
                  {contact_error}
                </div>
              }
              {!contact_status ?
                <button className="btn btn-block btn-send" onClick={contactSend}>
                  ENVIAR
                </button>
                :
                <button className="btn btn-block btn-send">ENVIANDO...</button>
              }
            </div>
          </div>
        </div>
      </LayoutDefault>
    )
  }
}

const mapStateToProps = ({contactReducer}) => {
  return {
    contactReducer
  }
}

const mapDispatchToProps = {
  contactName, contactInstitution, contactEmail, contactPhone, contactMessage,
  contactSend, contactClear, changeMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
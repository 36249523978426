import React, {Fragment} from 'react';
import './css/TerritoryComponent.css';

// function itemBox(item) {
//   return(
//     <div className="component-row" key={item.id}>
//       <div className="component-name">
//         <div className="component-tooltip">
//           {item.tooltip}
//           <div className="component-tooltip-angle"/>
//         </div>
//         {item.name}
//       </div>
//       <div className="component-number">{item.nal}</div>
//       <div className="component-number">{item.esp}</div>
//     </div>
//   )
// }

function TerritoryComponent(props) {
  const { principalTitle, title, sources} = props;
  return (
    <Fragment>
      <div className="subtitle-section">
        { principalTitle ? principalTitle : 'COMPONENTES' }
      </div>
      <div className="component-title">
        <span className="icon-dot"/> {title}
      </div>
      {sources.map((item, index) => {
        item = item.trim(';');
        if (item.length > 0) {
          return (
            <div className="component-content" key={index}>
              <iframe src={item} title={title}/>
            </div>
          )
        }
        return null;
      })}
    </Fragment>
  )
}

export default TerritoryComponent;
import React from "react";
import { withRouter } from "react-router-dom";
import "./css/BoxSearch.css";
import { connect } from "react-redux";

function toSearch(props, search) {
  let to_search = `/buscar/${search}`;
  props.history.push(to_search);
}

function BoxSearch(props) {
  const {
    defaultReducer: { search },
    onBlur,
  } = props;
  let classInput =
    props.type === "menu" ? "boxSearch-input-menu" : "boxSearch-input-all";
  return (
    <div className="boxSearch">
      {props.action ? (
        <button
          onClick={() => {
            if (search.length >= 4) {
              toSearch(props, search);
            }
          }}
        >
          <span className="icon-search" />
        </button>
      ) : (
        <div>
          <span className="icon-search" />
        </div>
      )}
      <input
        type="text"
        className={classInput}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(input) => props.onChange(input.target.value)}
        onKeyPress={(event) => {
          if (event.key === "Enter" && props.action && search.length >= 4) {
            toSearch(props, search);
          }
        }}
        onBlur={onBlur}
      />
      {props.type === "menu" && (
        <button>
          <span className="icon-menu" />
        </button>
      )}
    </div>
  );
}

BoxSearch.defaultProps = {
  onBlur: () => null,
};

const mapStateToProps = ({ defaultReducer }) => {
  return {
    defaultReducer,
  };
};

export default withRouter(connect(mapStateToProps)(BoxSearch));

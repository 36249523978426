import React from 'react';
import './css/BoxStage.css';
import ButtonPurple from './ButtonPurple';
import TooltipIndicator from './TooltipIndicator';

function BoxStage(props) {
  const {
    values, title, subtitle, button, goTo, tooltip, titleTooltip, contentTooltip,
  } = props;
  let valCountry = Number(values.country);
  valCountry = (valCountry > 0) ? valCountry.toFixed(1) : valCountry;
  let isFloatCountry = [];
  if (valCountry !== 0) {
    isFloatCountry = valCountry.split('.');
    if (isFloatCountry.length === 2) {
      valCountry = (parseInt(isFloatCountry[1]) === 0) ? isFloatCountry[0] : valCountry;
    }
  }
  let valCity = Number(values.city);
  valCity = (valCity > 0) ? valCity.toFixed(1) : valCity;
  let isFloatCity = [];
  if (valCity !== 0) {
    isFloatCity = valCity.split('.');
    if (isFloatCity.length === 2) {
      valCity = (parseInt(isFloatCity[1]) === 0) ? isFloatCity[0] : valCity;
    }
  }
  return(
    <div>
      <div className="boxStage-title">
        <div>
          {title}
          <div className="boxStage-subtitle">
            {titleTooltip && contentTooltip &&
              <TooltipIndicator
                title={titleTooltip}
                content={contentTooltip}
              />
            }
            {subtitle}
          </div>
        </div>
        {button &&
          <ButtonPurple
            goTo={goTo}
            tooltip={tooltip}
          />
        }
      </div>
      <div className="boxState-content">
        <div className="boxStage-box">
          <div className="boxStatge-title">Nacional</div>
          <div className="boxStatge-number">{valCountry}</div>
        </div>
        <div className="boxStage-box">
          <div className="boxStatge-title">Municipio</div>
          <div className="boxStatge-number">{valCity}</div>
        </div>
      </div>
    </div>
  )
}

export default BoxStage;
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { connect } from "react-redux";
import TerritoryPopulation from "./TerritoryPopulation";
import { territoryProgramRequest } from "../functionality/actions/territoryActions";
import PDFViewer from "pdf-viewer-reactjs";
import { withRouter } from "react-router";

function TerritoryContent(props) {
  const {
    userReducer: { user },
    territoryReducer: { territory_request_loading },
    match: {
      params: { type },
    },
    information,
    user_validate,
    territoryProgramRequest,
  } = props;

  const renderDocs = (docs) => {
    return (
      <>
        {docs.map((item) => (
          <div key={item.id} className="territoryInfoPage_item">
            <div className="territoryInfoPage_download">
              <h3 style={{ color: information.background }}>{item.title}</h3>
              <a
                href={item.document}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  backgroundColor: information.background,
                  color: information.color,
                }}
                title="Descargar archivo"
              >
                <i className="fas fa-download" />
              </a>
            </div>
            <PDFViewer
              document={{
                url: item.document,
              }}
            />
          </div>
        ))}
      </>
    );
  };

  const renderGraphs = (graphs) => {
    return (
      <>
        {graphs.map((item) => (
          <div key={item.id} className="territoryInfoPage_item">
            <h3 style={{ color: information.background }}>{item.title}</h3>
            <iframe
              src={item.graph}
              frameBorder="0"
              className="territoryInfoPage_graph"
            />
          </div>
        ))}
      </>
    );
  };

  if (user_validate) {
    const access_status =
      user && user.territory_programs
        ? user.territory_programs.filter((item) => item.id === information.id)
        : [];

    if (!access_status.length) {
      return (
        <div className="territoryInfoPage_container">
          <h1 style={{ color: information.background }}>
            {information.name.toUpperCase()}
          </h1>
          <h3>
            Esta sección cuenta con información confidencial de algunos
            programas, si desea conocer esta información lo invitamos a pedir
            acceso aquí y pronto se le notificara si es aprobada o no.
          </h3>
          {!territory_request_loading ? (
            <button
              style={{
                backgroundColor: information.background,
                color: information.color,
              }}
              onClick={territoryProgramRequest}
              className="territoryInfoPage_btn-request"
            >
              SOLICITAR ACCESO
            </button>
          ) : (
            <button
              style={{
                backgroundColor: information.background,
                color: information.color,
              }}
              className="territoryInfoPage_btn-request"
            >
              ENVIANDO SOLICITUD...
            </button>
          )}
        </div>
      );
    }
  }

  if (information && information.id === 2 && type === "datos") {
    return (
      <div className="territoryInfoPage_container">
        <h1 style={{ color: information.background }}>
          {information.name.toUpperCase()}
        </h1>
        {information.docs_active && renderDocs(information.docs_active)}
        <TerritoryPopulation />
        {information.graphs_active && renderGraphs(information.graphs_active)}
      </div>
    );
  }

  return (
    <div className="territoryInfoPage_container">
      <h1 style={{ color: information.background }}>
        {information.name.toUpperCase()}
      </h1>
      {user_validate && (
        <div className="territoryInfoPage_note">
          <p>
            <strong>Nota: </strong> La información presentada aquí es
            confidencial y está prohibida su distribución a terceros.
          </p>
        </div>
      )}
      {information.graphs_active && renderGraphs(information.graphs_active)}
      {information.docs_active && renderDocs(information.docs_active)}
    </div>
  );
}

const mapStateToProps = ({ userReducer, territoryReducer }) => {
  return {
    userReducer,
    territoryReducer,
  };
};

const mapDispatchToProps = {
  territoryProgramRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TerritoryContent)
);
